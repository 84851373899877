import React, { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HashLink, NavHashLink } from "react-router-hash-link";
import IntroInfoModal from "./IntroInfoModal";
import { useState } from "react";

export default function Footer() {
  const { t } = useTranslation();
  const [infoModal, setInfoModal] = useState(false);
  return (
    <section id="footer" className="container-fluid">
      <footer className="footer container">
        <div className="footer-row d-flex justify-content-between">
          <div className="col-md-3 col-12">
            <Link to="/">
              <img
                className="footer-logo"
                src="/images/logo_footer.png"
                alt="logo"
              />
            </Link>
            <div className="application-links">
              <a
                href="#"
                className="application-link"
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  setInfoModal(true);
                }}
              >
                <img src="/images/appstore.png" alt="appstore" />
              </a>
              <a
                href="#"
                className="application-link"
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  setInfoModal(true);
                }}
              >
                <img src="/images/googleplay.png" alt="googleplay" />
              </a>
            </div>
            <div className="footer-socials">
              <h1 className="footer-title">{t("Follow us on")}</h1>
              <ul>
                <li>
                  <a
                    href="https://t.me/YellowIncOfficial"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="ri-telegram-fill" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100078467067360&mibextid=LQQJ4d"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="ri-facebook-circle-fill" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://instagram.com/yellowincofficial?igshid=YmMyMTA2M2Y="
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="ri-instagram-fill" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/YellowWayInc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="ri-twitter-fill" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@yellowway1320"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="ri-youtube-fill" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/yellowwayincofficial"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="ri-linkedin-fill" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-2 col-6">
            <h1 className="footer-title">{t("menu.About us")}</h1>
            <ul>
              <li>
                <Link to="/plans">{t("menu.Rates")}</Link>
              </li>
              <li>
                <Link to="/services">{t("menu.Our Services")}</Link>
              </li>
              <li>
                <NavHashLink to="/about#command">{t("menu.Team")}</NavHashLink>
              </li>
              <li>
                <NavHashLink to="/for-uzbek#commentSwiper">
                  {t("menu.Customer Reviews")}
                </NavHashLink>
              </li>
              <li>
                <a href="#">{t("menu.Careers")}</a>
              </li>
              <li>
                <Link to="/contact">{t("menu.Contacts")}</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-2 col-6">
            <h1 className="footer-title">{t("menu.For buyers")}</h1>
            <ul>
              <li>
                <Link to="/help">{t("menu.FAQ")}</Link>
              </li>
              <li>
                <NavHashLink to="/for-usa#tracking">
                  {t("menu.Track the package")}
                </NavHashLink>
              </li>
              <li>
                <Link to="/shops">{t("menu.Sale")}</Link>
              </li>
              <li>
                <Link to="/shops">{t("menu.Popular stores")}</Link>
              </li>
              <li>
                <NavHashLink to="/terms#terms-page">
                  {t("menu.Restrictions and bans")}
                </NavHashLink>
              </li>
              <li>
                <HashLink to="#how">{t("menu.Help with buying")}</HashLink>
              </li>
            </ul>
          </div>
          <div className="col-md-2 col-6">
            <h1 className="footer-title">{t("menu.Useful")}</h1>
            <ul>
              <li>
                <a
                  href="https://instagram.com/yellowincofficial?igshid=YmMyMTA2M2Y="
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("menu.News")}
                </a>
              </li>
              {/* <li>
                                <a href='#'>{t('menu.Sending parcels')}</a>
                            </li>
                            <li>
                                <a href='#'>{t('menu.Delivery of parcels')}</a>
                            </li>
                            <li>
                                <a href='#'>{t('menu.Custom limits')}</a>
                            </li> */}
              <li>
                <Link to="/size-table">{t("menu.Table of sizes")}</Link>
              </li>
              <li>
                <a href="#calculator-new">{t("menu.Fee calculation")}</a>
              </li>
            </ul>
          </div>
          <div className="col-md-2 col-6">
            <h1 className="footer-title">{t("menu.Contacts")}</h1>
            <p className="text-muted">{t("menu.Uzbekistan")}</p>
            <a href="tel:+998992441111" className="footer-link">
              <i className="ri-phone-line">&nbsp;</i>+99899 2441111
            </a>
            <p className="text-muted">{t("menu.United States")}</p>
            <a href="tel:+19292440000" className="footer-link">
              <i className="ri-phone-line">&nbsp;</i>+1 (929) 244-0000
            </a>
            <a href="mailto:theyellowinc@gmail.com" className="footer-link">
              <i className="ri-mail-line">&nbsp;</i>
              theyellowinc@gmail.com
            </a>
          </div>
        </div>
      </footer>
      <IntroInfoModal
        visible={infoModal}
        onCancel={() => setInfoModal(false)}
      />
    </section>
  );
}
