import { Field, Form } from "react-final-form";
import { computeValues } from "./Calculator";
import { useEffect, useState } from "react";
import { shopRate } from "../functions/api";
import { useTranslation } from "react-i18next";

export default function CalculatorForm() {
  const { t } = useTranslation();
  const [rates, setRates] = useState(null);
  const [range, setRange] = useState(0);
  useEffect(() => {
    shopRate().then((data) => setRates(data));
  }, []);
  const onSubmit = (values) => console.log(values);

  function onCountryChange(country, form, isFrom) {
    if (parseInt(country) === 1) {
      form.change(`${isFrom ? "to" : "from"}`, 2);
      form.change("unit", `${isFrom ? "lb" : "kg"}`);
    } else {
      form.change(`${isFrom ? "to" : "from"}`, 1);
      form.change("unit", `${isFrom ? "kg" : "lb"}`);
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ from: 1, to: 2 }}
      subscription={{ values: true }}
      render={({ values, form }) => {
        let totalAmount = computeValues(rates, values);
        return (
          <>
            <h3 className="calculator-section-title">{t("Калькулятор")}</h3>
            <div className="calculator-row row y-b">
              <div className="col-md-6 col-12 first-item">
                <div className="calculator-box">
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="inputFrom" className="form-label">
                        {t("Откуда")}
                      </label>
                      <Field name="from">
                        {({ input }) => (
                          <select
                            className="form-control calc-select"
                            id="inputFrom"
                            {...input}
                            onChange={(e) => {
                              input.onChange(e.target.value);
                              onCountryChange(e.target.value, form, true);
                            }}
                          >
                            <option value="1">USA</option>
                            <option value="2">UZB</option>
                          </select>
                        )}
                      </Field>
                    </div>
                    <div className="col-6">
                      <label htmlFor="inputTo" className="form-label">
                        {t("Куда")}
                      </label>
                      <Field name="to">
                        {({ input }) => (
                          <select
                            className="form-control calc-select"
                            id="inputTo"
                            {...input}
                            onChange={(e) => {
                              input.onChange(e.target.value);
                              onCountryChange(e.target.value, form, false);
                            }}
                          >
                            <option value="2">UZB</option>
                            <option value="1">USA</option>
                          </select>
                        )}
                      </Field>
                    </div>
                    <div className="col-9">
                      <Field name="weight" initialValue={range} type="number">
                        {({ input }) => (
                          <input
                            {...input}
                            onChange={(e) => setRange(e.target.value)}
                            className="form-control interval-input"
                          />
                        )}
                      </Field>

                      <input
                        id="range"
                        className="form-control calc-interval"
                        type="range"
                        max="60"
                        min="0"
                        step="0.1"
                        onChange={(e) => {
                          setRange(e.target.value);
                        }}
                        defaultValue={range ? range : 0}
                      />
                    </div>
                    <div className="col-3">
                      <Field name="unit" initialValue="lb">
                        {({ input }) => {
                          return (
                            <select
                              {...input}
                              onChange={(e) => {
                                input.onChange(e.target.value);
                                form.change(
                                  "measure",
                                  e.target.value === "kg" ? "sm" : "in"
                                );
                              }}
                              className="form-control calc-select"
                            >
                              <option value="kg" selected="">
                                {t("KG")}
                              </option>
                              <option value="lb">{t("LB")}</option>
                            </select>
                          );
                        }}
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12 second-item">
                <div className="calculator-box">
                  <div className="row checkbox-row">
                    <label
                      className="form-label"
                      style={{ color: "transparent" }}
                    >
                      .
                    </label>
                    <Field name="dimension" value="1" type="checkbox">
                      {({ input }) => {
                        return (
                          <div className="col-12">
                            <input {...input} className="form-check-input" />
                            <label
                              className="form-check-label"
                              htmlFor="formCheckCalc"
                            >
                              {t("Расчёт стоимости доставки крупногабаритных посылок")}
                            </label>
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <div
                    className={`row ${
                      values.dimension && values.dimension.length > 0
                        ? "extra-data"
                        : "none"
                    }`}
                  >
                    <Field name="length" initialValue="0" type="number">
                      {({ input }) => {
                        return (
                          <div className="col-3">
                            <label className="form-label">{t("Длина")}</label>
                            <input {...input} className="form-control" />
                          </div>
                        );
                      }}
                    </Field>
                    <Field name="width" initialValue="0" type="number">
                      {({ input }) => {
                        return (
                          <div className="col-3">
                            <label className="form-label">{t("Ширина")}</label>
                            <input {...input} className="form-control" />
                          </div>
                        );
                      }}
                    </Field>
                    <Field name="height" initialValue="0" type="number">
                      {({ input }) => {
                        return (
                          <div className="col-3">
                            <label className="form-label">{t("Высота")}</label>
                            <input {...input} className="form-control" />
                          </div>
                        );
                      }}
                    </Field>
                    <Field name="measure" initialValue="in">
                      {({ input }) => {
                        return (
                          <div className="col-3">
                            <label className="form-label">&nbsp;</label>
                            <select
                              {...input}
                              onChange={(e) => {
                                input.onChange(e.target.value);
                                form.change(
                                  "unit",
                                  e.target.value === "sm" ? "kg" : "lb"
                                );
                              }}
                              className="form-control calc-select"
                            >
                              <option value="sm">{t("см")}</option>
                              <option value="in">{t("дюйм")}</option>
                            </select>
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                </div>
              </div>
              <div className="calculator-total">
                <h5 className="calc-title">{t("Итого")}</h5>
                <h5 className="calc-amount">{totalAmount || 0} $</h5>
              </div>
            </div>
          </>
        );
      }}
    />
  );
}
