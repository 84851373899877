import React, { useContext, useRef, useState } from "react";
import WithFooterPage from "./WithFooterPage";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode, Navigation, Thumbs } from "swiper";
import { useTranslation } from "react-i18next";
import RegistrationPage from "./cabinet/RegistrationPage";
import Context from "../functions/context";
import Calculator from "../components/Calculator";
import Products from "../components/Products";
import MenuContent from "../components/MenuContent";

export default function Yellow() {
  const { t } = useTranslation();
  const {
    mainState: { customer },
  } = useContext(Context);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const swiperRef1 = useRef();

  return (
    <div id="special-page">
      <div className="only-mobile w-100">
        <MenuContent />
      </div>
      <div className="header text-center only-desktop">
        <Link className="navbar-brand" to="/">
          <img src="/images/logo.svg" alt="logo" />
        </Link>
      </div>
      <div className="container">
        <div className="w-100 about-row-swiper">
          <button
            className="mySwiper2-prew-btn"
            onClick={() => {
              swiperRef1.current?.slidePrev();
            }}
          >
            <i className="ri-arrow-left-line"></i>
          </button>

          <button
            className="mySwiper2-next-btn"
            onClick={() => {
              swiperRef1.current?.slideNext();
            }}
          >
            <i className="ri-arrow-right-line"></i>
          </button>
        </div>
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          navigation={{
            nextEl: ".mySwiper2-next-btn",
            prevEl: ".mySwiper2-prew-btn",
          }}
          grabCursor={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs, Autoplay]}
          className="mySwiper2 swipers"
          onBeforeInit={(swiper) => {
            swiperRef1.current = swiper;
          }}
        >
          <SwiperSlide>
            <div className="content">
              <h2>
                Все для <br /> тренировок
              </h2>
              <p className="swiper-text">
                Зарегистрируйтесь онлайн и закажите сейчас
              </p>
              <img
                className="swiper-image"
                src="/images/general-images/basscked.svg"
                alt="slider"
              />
            </div>
          </SwiperSlide>
        </Swiper>
        {!customer ? <RegistrationPage /> : null}
        <div className="buy-from">
          <h2>Покупайте онлайн из США и получите в Узбекистане</h2>
          <div className={`steep-1-main`}>
            <span className={`steep-1-main-line`}></span>
            <div className={`steep-1-main-item`}>
              <span>01</span>
              <h4 className="steep-1-main-item-text">
                Зарегистрируйтесь и получите Ваш адрес доставки в США
              </h4>
            </div>

            <div className={`steep-1-main-item`}>
              <span>02</span>
              <h4 className={`steep-1-main-item-text`}>
                Покупайте онлайн, используйте свой адрес доставки
              </h4>
            </div>

            <div className={`steep-1-main-item`}>
              <span>03</span>
              <h4 className={`steep-1-main-item-text`}>
                Получите свою посылку в течении 5 - 10 рабочих дней
              </h4>
            </div>
          </div>
          <div className="steep-1-main only-desktop">
            <Link to="/registration" className="swiper-button btn">
              Как сделать заказ?
            </Link>
          </div>
        </div>
      </div>
      <section id="calculator-new" className="container-fluid">
        <Calculator />
      </section>
      <Products />
      <div id="brands">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h2>Покупайте в этих магазинах</h2>
            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-4">
                  <div className="brand-image">
                    <img src="/images/brands/image 76.svg" alt="brand" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="brand-image">
                    <img src="/images/brands/image 77.svg" alt="brand" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="brand-image">
                    <img src="/images/brands/image 78.svg" alt="brand" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="brand-image">
                    <img src="/images/brands/image 79.svg" alt="brand" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="brand-image">
                    <img src="/images/brands/image 80.svg" alt="brand" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="brand-image">
                    <img src="/images/brands/image 81.svg" alt="brand" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="brand-image">
                    <img src="/images/brands/image 82.svg" alt="brand" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="brand-image">
                    <img src="/images/brands/image 83.svg" alt="brand" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="brand-image">
                    <img src="/images/brands/image 84.svg" alt="brand" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="brand-image">
                    <img src="/images/brands/image 85.svg" alt="brand" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="brand-image">
                    <img src="/images/brands/image 86.svg" alt="brand" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="services">
        <div className="container">
          <div className="row top-side">
            <div className="col-md-6">
              <div className="left-side">
                <div className="top-content">
                  <h4 className="title">
                    Нет банковской карты или времени? Воспользуйтесь услугой
                    «Купи вместо меня»
                  </h4>
                  <p className="text">
                    Вы просто отправляете нам ссылку на продукт, пополняете свой
                    счет в Globbing, и мы покупаем продукт для вас.
                  </p>
                </div>
                <div className="bottom-content">
                  <p>
                    Используйте{" "}
                    <a href="#" target="_blank">
                      «Купить для меня»
                    </a>
                    , если:
                  </p>
                  <ul>
                    <li>
                      <p>У тебя нет банковской карты или времени.</p>
                    </li>
                    <li>
                      <p>
                        Вы хотите использовать магазины, которые не доставляют
                        на складские адреса?
                      </p>
                    </li>
                    <li>
                      <p>
                        Хотите пользоваться магазинами, которые не принимают
                        банковские карты, выпущенные в Узбекистане?
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-image">
                <img src="/images/service-image.svg" alt="service" />
              </div>
            </div>
          </div>
          <div className="row bottom-side">
            <div className="col-md-4">
              <div className="box">
                <div className="box-image">
                  <img
                    src="/images/icons/Yellow (1)/location.svg"
                    alt="location"
                  />
                </div>
                <div className="box-content">
                  <p>Условия сервиса</p>
                  <span>
                    Отправьте ссылку товара, который Вас интересует, мы
                    осуществим покупку и предоставим полную информацию о заказе.
                    Мы попытаемся совершить покупку в течение 1 раб. дня. Для
                    декларирования посылки основанием послужит инвойс, который
                    выдает магазин. После совершения покупки, декларированная
                    цена товара не подлежит изменению. В случае возникновения
                    вопросов звоните пн - пт 10:00 - 20:00.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="box">
                <div className="box-image">
                  <img src="/images/icons/Yellow (1)/home.svg" alt="location" />
                </div>
                <div className="box-content">
                  <p>Стоимость услуги</p>
                  <span>
                    Отправьте ссылку товара, который Вас интересует, мы
                    осуществим покупку и предоставим полную информацию о заказе.
                    Мы попытаемся совершить покупку в течение 1 раб. дня. Для
                    декларирования посылки основанием послужит инвойс, который
                    выдает магазин. После совершения покупки, декларированная
                    цена товара не подлежит изменению. В случае возникновения
                    вопросов звоните пн - пт 10:00 - 20:00.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="box">
                <div className="box-image">
                  <img
                    src="/images/icons/Yellow (1)/dollor.svg"
                    alt="location"
                  />
                </div>
                <div className="box-content">
                  <p>Специальные магазины</p>
                  <span>
                    Отправьте ссылку товара, который Вас интересует, мы
                    осуществим покупку и предоставим полную информацию о заказе.
                    Мы попытаемся совершить покупку в течение 1 раб. дня. Для
                    декларирования посылки основанием послужит инвойс, который
                    выдает магазин. После совершения покупки, декларированная
                    цена товара не подлежит изменению. В случае возникновения
                    вопросов звоните пн - пт 10:00 - 20:00.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Link to="#" className="btn">
            Купить со мной
          </Link>
        </div>
      </div>
      <div id="how-work">
        <div className="container">
          <div className="video only-desktop">
            <img src="/images/video.svg" alt="Видео инструкция" />
            <p>Видео инструкция</p>
          </div>
          <div className="row header">
            <h2>Как это работает</h2>
            <p>
              Мы подготовили пошаговое руководство о том, как покупать онлайн в
              интернет-магазинах США и получать заказы в Узбекистане, простого и
              безопасного вместе с Yellow.
            </p>
            <div className="video only-mobile">
            <img src="/images/video.svg" alt="Видео инструкция" />
            <p>Видео инструкция</p>
          </div>
          </div>
          <div className="row section-body">
            <div className="step">
              <p className="number">01</p>
              <div className="line" />
              <p className="text">
                Зарегистрируйтесь в Yellow через наш веб-сайт или приложение,
                чтобы бесплатно получить личный адрес в США. Вы можете делать
                покупки в зарубежных интернет-магазинах по этому адресу, и
                информация о вашем заказе будет храниться на вашей странице
                Yellow.
              </p>
              <img src="/images/step1.svg" alt="Mac Book Pro" />
            </div>
            <div className="step">
              <div className="line" />
              <p className="number">02</p>
              <p className="text">
                Выбирайте товары из интернет-магазинов США. При совершении
                покупки введите адрес доставки на странице Yellow в разделе
                «Адрес доставки». Мы получим ваши посылки на нашем зарубежном
                складе и доставим их в Узбекистан. Услуга «Купи вместо меня»
                доступна специально для интернет-магазинов, где нельзя совершать
                покупки самостоятельно. Это возможно когда ваша покупка выходит
                со склада интернет-магазина, интернет-магазин предоставляет ее
                вам для загрузки. Вам нужно ввести его на странице Yellow.
              </p>
              <img src="/images/step2.svg" alt="Mac Book Pro" />
            </div>
            <div className="step">
              <div className="line" />
              <p className="number">03</p>
              <p className="text">
                Как только ваш заказ поступит на наш склад, он будет бесплатно
                переупакован и зарегистрирован в уже уменьшенных размерах, о
                чем. Вы получите уведомление на странице Globbing вы увидите
                детали и статус посылки. Только через 24 часа после оформления
                посылка автоматически перейдет в стадию доставки и будет
                отправлен в Узбекистан.
              </p>
              <img src="/images/step3.svg" alt="Mac Book Pro" />
            </div>
            <div className="step">
              <div className="line" />
              <p className="number">04</p>
              <p className="text">
                Вы можете получить заказ через наш инновационный сервис-центр
                который находиться в центре города Ташкент. Для обеспечения
                вашего комфорта также работает услуга «Доставка на дом». Вы
                можете воспользоваться нашими услугами, войдя в свою учетную
                запись Yellow
              </p>
              <img src="/images/step4.svg" alt="Mac Book Pro" />
            </div>
          </div>
        </div>
      </div>
      <div id="walk">
        <div className="container-fluit">
          <div className="row">
            <div className="content">
              <img src="images/3dwalk.svg" alt="3d-walk" />
            </div>
          </div>
        </div>
      </div>
      <WithFooterPage />
    </div>
  );
}
