import CustomModal from "../../components/CustomModal";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import Context from "../../functions/context";
import {
  ADD_UPDATE_SHOP,
  ADD_UPDATE_TRACKING,
  SET_SECTION,
  TOGGLE_ADDRESS_MODAL,
  TOGGLE_ADD_MODAL,
  UPDATE_STEP_MODAL,
} from "../../functions/constants";
import { createForm } from "final-form";
import ShopForm from "./components/ShopForm";
import arrayMutators from "final-form-arrays";
import { normalizeErrorFields } from "../../functions/request-helper";
import AddressChooser from "./components/AddressChooser";
import ShopDetail from "./components/ShopDetail";
import TrackingForm from "./components/TrackingForm";
import TrackingDetail from "./components/TrackingDetail";
import { createShop, createTracking } from "../../functions/api";
import OrderFinished from "./components/OrderFinished";
import { useTranslation } from "react-i18next";
import { shopEventRecords, trackingEventRecords } from "../../functions/helper";
import TagManager from "react-gtm-module";

const stepRender = (section, step, form, formInitialValues) => {
  if (step === 2) {
    return section === "tracking" ? (
      <TrackingForm form={form} initialValues={formInitialValues} />
    ) : (
      <ShopForm form={form} />
    );
  } else if (step === 3) {
    return <AddressChooser form={form} />;
  } else if (step === 4) {
    return section === "tracking" ? (
      <TrackingDetail values={form.getState().values} />
    ) : (
      <ShopDetail values={form.getState().values} />
    );
  }
};

export default function CreateModal() {
  const { t } = useTranslation();
  const { mainState, mainDispatch } = useContext(Context);
  const { showAddModal, currentStep, section, formInitialValues } = mainState;
  const [insertedItem, setInsertedItem] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const activeSectionPath = window.location.pathname.includes("tracking");
  const onClose = () => {
    mainDispatch({ type: TOGGLE_ADD_MODAL, payload: { visible: false } });
    mainDispatch({ type: SET_SECTION, payload: undefined });
  };

  const onSubmit = async (values) => {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });
    const eventItems =
      section === "tracking"
        ? trackingEventRecords(values.products)
        : shopEventRecords(values.records);
        
    const currentStep = values.currentStep === 1 ? 2 : values.currentStep;
    if (currentStep === 4) {
      try {
        setSubmitted(true);
        if (section === "tracking") {
          const newItem = await createTracking(values);
          mainDispatch({ type: ADD_UPDATE_TRACKING, payload: newItem });
          setInsertedItem(newItem);
        } else {
          const item = await createShop(values);
          mainDispatch({ type: ADD_UPDATE_SHOP, payload: item });
          setInsertedItem(item);
        }
        TagManager.dataLayer({
          dataLayer: {
            event: "add_to_cart",
            ecommerce: {
              items: eventItems,
            },
          },
        });
        mainDispatch({
          type: UPDATE_STEP_MODAL,
          payload: 5,
        });
        setSubmitted(false);
      } catch (res) {
        if (res?.response?.status === 422) {
          const error = res.response.data;
          const fieldErrors = normalizeErrorFields(error);
          if (Object.values(fieldErrors).length) {
            return fieldErrors;
          }
        }
        setSubmitted(false);
      }
    } else {
      if (currentStep === 2) {
        TagManager.dataLayer({
          dataLayer: {
            event: "view_item",
            ecommerce: {
              items: eventItems,
            },
          },
        });
      }
      switch (currentStep) {
        case 2:
          handleUpdateStatus(3);
          break;
        case 3:
          if (values?.recipientAddress) {
            handleUpdateStatus(4);
          }
          break;
        default:
          handleUpdateStatus(Math.min(4, currentStep + 1));
      }
    }
  };

  const handleUpdateStatus = (status) => {
    mainDispatch({
      type: UPDATE_STEP_MODAL,
      payload: status,
    });
    form.change("currentStep", status);
  };

  const form = useMemo(() => {
    return createForm({
      onSubmit: onSubmit,
      initialValues: formInitialValues,
      subscription: { values: true, valid: true },
      mutators: { ...arrayMutators },
    });
  }, [section]);

  useEffect(() => {
    if (!currentStep) {
      mainDispatch({
        type: UPDATE_STEP_MODAL,
        payload: 1,
      });
    }
    return () => {
      mainDispatch({ type: SET_SECTION, payload: undefined });
    };
  }, []);

  const ref = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (!ref.current) {
        return;
      }
      if (!ref.current.contains(event.target)) {
        if (!document.getElementById("add-address-modal")) {
          onClose();
        }
      }
    };
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, [currentStep]);

  return (
    <CustomModal
      visible={showAddModal}
      onCancel={onClose}
      id="parcels-order"
      title={`${
        currentStep === 5 && section === "tracking"
          ? t("Заказы добавлены успешно")
          : currentStep === 5 && section === "shop"
          ? t("Ваша заявка добавлена успешно")
          : t("Добавить заказ для выкупа")
      }`}
      cssClass={`parcels-order ${currentStep === 5 ? "finish" : ""}`}
    >
      <div className="modal-body" ref={ref}>
        {currentStep !== 5 ? (
          <>
            <div className="parcels-steps-group">
              <div className="parcels-modal-steps">
                {[1, 2, 3, 4].map((st) => (
                  <span
                    key={st}
                    className={`step ${st <= currentStep ? "active" : ""} `}
                  >
                    <span>{st}</span>
                  </span>
                ))}
              </div>
              <div className="parcles-modal-arrows">
                {currentStep > 1 ? (
                  <button
                    onClick={() => {
                      currentStep === 2
                        ? mainDispatch({
                            type: SET_SECTION,
                            payload: undefined,
                          })
                        : handleUpdateStatus(Math.max(currentStep - 1, 1));
                    }}
                    className="btn-prev"
                  >
                    <i className="ri-arrow-left-s-line"></i>
                  </button>
                ) : (
                  ""
                )}{" "}
                {currentStep < 4 ? (
                  <button
                    onClick={() =>
                      handleUpdateStatus(Math.min(currentStep + 1, 4))
                    }
                    className="btn-next"
                  >
                    <i className="ri-arrow-right-s-line"></i>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            {!section ? (
              <div className="parcels-order d-flex flex-column">
                <div className="col-12 mt-3">
                  <h1 className="parcels-list-title">
                    {t("Выберите тип заказа *")}
                  </h1>
                  <p className="m-0">
                    {t(
                      "Пожалуйста, выберите тип заказа, чтобы разместить заказ"
                    )}
                  </p>
                </div>
                {activeSectionPath ? (
                  <a
                    className="parcels-select mt-4"
                    onClick={() => {
                      mainDispatch({ type: SET_SECTION, payload: "tracking" });
                      mainDispatch({
                        type: UPDATE_STEP_MODAL,
                        payload: 2,
                      });
                    }}
                  >
                    <i className="ri-shopping-cart-line"></i>
                    {t("Интернет заказ")}
                  </a>
                ) : (
                  <a
                    className="parcels-select mt-4"
                    onClick={() => {
                      mainDispatch({ type: SET_SECTION, payload: "shop" });
                      mainDispatch({
                        type: UPDATE_STEP_MODAL,
                        payload: 2,
                      });
                    }}
                  >
                    <i className="ri-information-line"></i>
                    {t("Помощь в покупке")}
                  </a>
                )}
              </div>
            ) : (
              <div className="parcels-form">
                <div className="tab" style={{ display: "block" }}>
                  {stepRender(section, currentStep, form, formInitialValues)}
                </div>
                <div
                  className="col"
                  style={{
                    marginTop: "20px",
                    justifyContent: "flex-end",
                    display: "flex",
                  }}
                >
                  {currentStep === 3 ? (
                    <button
                      className="btn new-address-btn"
                      style={{ marginRight: "auto" }}
                      onClick={() =>
                        mainDispatch({
                          type: TOGGLE_ADDRESS_MODAL,
                          payload: true,
                        })
                      }
                    >
                      <i className="ri-add-line"></i>{" "}
                      {t("Добавить новый адрес")}
                    </button>
                  ) : null}
                  <button
                    type="button"
                    className="btn next-btn"
                    disabled={submitted}
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    {currentStep !== 4 ? t("Продолжить") : t("Подтвердить")}
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <OrderFinished
            item={insertedItem}
            onClose={onClose}
            section={section}
          />
        )}
      </div>
    </CustomModal>
  );
}
