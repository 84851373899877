import React from 'react';
import Select from 'react-select';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';

const SimpleSelect = ({ placeholder, options, onChange, value,className=""}) => {
  const handleSelect = (option) => {
    if (onChange) {
      onChange(option);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#f4f4f4',
      borderColor: '#f4f4f4',
      height: '45px',
      boxShadow: state.isFocused ? null : null,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '45px',
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
      height: '45px',
    }),
    indicatorSeparator: (state) => ({
      height: '45px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '45px',
    }),
  };

  return (
    <Select
      isClearable={true}
      options={options}
      placeholder={placeholder}
      styles={customStyles}
      onChange={handleSelect}
      className={className}
      value={
        !isObject(value) && isEmpty(value) ? options.find((opt) => opt.value === value) : value
      }
    ></Select>
  );
};

export default SimpleSelect;
