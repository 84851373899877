import React, { useContext } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import {
  composeValidators,
  minLengthConstraint,
  requiredConstraint,
} from "../../../functions/validator";
import Context from "../../../functions/context";
import { SET_CLAIMS } from "../../../functions/constants";

export default function ClaimUserInfo() {
  const { t } = useTranslation();
  const {
    mainState: { claims },
    mainDispatch,
  } = useContext(Context);


  const onSubmit = async (values) => {
    mainDispatch({
      type: SET_CLAIMS,
      payload: {
        step: 3,
        data: {
          ...claims.data,
          ...values,
        },
      },
    });
  };

  return (
    <div className="claim-user-info">
      <h3 className="fw-normal">{t("Информация пользователя")}</h3>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className="row">
              <div className="col-md-6 col-12">
                <h6 className="fw-normal">{t("Отправитель")}</h6>
                <div className="fields">
                  <Field
                    name="sender.firstName"
                    validate={composeValidators(requiredConstraint)}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="col-12">
                          <input
                            {...input}
                            type="text"
                            placeholder={`${t("Name")}*`}
                            className={`content-right-body-input ${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            }`}
                          />
                        </div>
                      );
                    }}
                  </Field>
                  <Field
                    name="sender.lastName"
                    validate={composeValidators(requiredConstraint)}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="col-12">
                          <input
                            {...input}
                            type="text"
                            id="lastName*"
                            className={`content-right-body-input ${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            }`}
                            placeholder={`${t("Surname")}*`}
                          />
                        </div>
                      );
                    }}
                  </Field>
                  {/* <Field
                    name="sender.id"
                    validate={composeValidators(requiredConstraint)}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="col-12">
                          <input
                            {...input}
                            type="text"
                            placeholder={`${t("ID")}*`}
                            className={`content-right-body-input ${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            }`}
                          />
                        </div>
                      );
                    }}
                  </Field> */}
                  <Field
                    name="sender.email"
                    validate={composeValidators(requiredConstraint)}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="col-12">
                          <input
                            className={`content-right-body-input ${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            }`}
                            {...input}
                            type="text"
                            id="email"
                            placeholder={`${t("E-mail")}*`}
                          />
                          {meta.submitError ? (
                            <span className="help-block">
                              {t(meta.submitError)}
                            </span>
                          ) : null}
                        </div>
                      );
                    }}
                  </Field>
                  <Field
                    name="sender.phone"
                    validate={composeValidators(
                      requiredConstraint,
                      minLengthConstraint(9)
                    )}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="col-12 input-margin-b">
                          <input
                            onChange={(e) => {
                              if (e.target.validity.valid) {
                                input.onChange(e.target.value);
                              } else {
                                e.target.value = input.value;
                              }
                            }}
                            placeholder="+"
                            type="number"
                            id="number"
                            className={`${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            } form-control number`}
                          />
                        </div>
                      );
                    }}
                  </Field>
                </div>
              </div>
              <div className="col-md-6 last col-12">
                <h6 className="fw-normal">{t("Получатель")}</h6>
                <div className="fields">
                  <Field
                    name="receiver.firstName"
                    validate={composeValidators(requiredConstraint)}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="col-12">
                          <input
                            {...input}
                            type="text"
                            placeholder={`${t("Name")}*`}
                            className={`content-right-body-input ${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            }`}
                          />
                        </div>
                      );
                    }}
                  </Field>
                  <Field
                    name="receiver.lastName"
                    validate={composeValidators(requiredConstraint)}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="col-12">
                          <input
                            {...input}
                            type="text"
                            id="lastName*"
                            className={`content-right-body-input ${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            }`}
                            placeholder={`${t("Surname")}*`}
                          />
                        </div>
                      );
                    }}
                  </Field>
                  {/* <Field
                    name="receiver.id"
                    validate={composeValidators(requiredConstraint)}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="col-12">
                          <input
                            {...input}
                            type="text"
                            placeholder={`${t("ID")}*`}
                            className={`content-right-body-input ${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            }`}
                          />
                        </div>
                      );
                    }}
                  </Field> */}
                  <Field
                    name="receiver.email"
                    validate={composeValidators(requiredConstraint)}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="col-12">
                          <input
                            className={`content-right-body-input ${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            }`}
                            {...input}
                            type="text"
                            id="email"
                            placeholder={`${t("E-mail")}*`}
                          />
                          {meta.submitError ? (
                            <span className="help-block">
                              {t(meta.submitError)}
                            </span>
                          ) : null}
                        </div>
                      );
                    }}
                  </Field>
                  <Field
                    name="receiver.phone"
                    validate={composeValidators(
                      requiredConstraint,
                      minLengthConstraint(9)
                    )}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="col-12 input-margin-b">
                          <input
                            onChange={(e) => {
                              if (e.target.validity.valid) {
                                input.onChange(e.target.value);
                              } else {
                                e.target.value = input.value;
                              }
                            }}
                            placeholder="+"
                            type="number"
                            id="number"
                            className={`${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            } form-control number`}
                          />
                        </div>
                      );
                    }}
                  </Field>
                </div>
              </div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="submit-btn y-b btn w-auto"
              >
                {t("Далее")}
              </button>
            </form>
          );
        }}
      />
    </div>
  );
}
