import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ClaimsAccsessModal from "./ClaimsAccsessModal";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "../../../functions/helper";

export default function ClaimNaigate() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [locales, setLocals] = useState({
    active: state?.tab,
    visible: false,
    claims: [],
  });

  function searchFn(value) {
    debounce(
      () => {
        setLocals({ ...locales, id: value });
      },
      "inputQuery",
      500
    );
  }

  const submit = () => {
    if (locales.id) {
      navigate(`${locales.id}`);
    }
  };

  return (
    <div className="parcles-content profile-right">
      <h3 className="fw-normal">{t("Претензия")}</h3>
      <h6 className="mb-4 fw-normal">
        {t("Претензия обрабатываются в течении 3 дней")}
      </h6>
      <div className="parcles-row flex-column flex-md-row ">
        <ul className="nav nav-pills y-b top " role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link justify-content-center ${
                locales.active ? "" : "active"
              }`}
              onClick={() => setLocals({ ...locales, active: false })}
            >
              {t("Претензия")}
            </button>
          </li>
          <span className="tabline" />
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${locales.active ? "active" : ""}`}
              onClick={() => setLocals({ ...locales, active: true })}
            >
              {t("Проверить статус претензии")}
            </button>
          </li>
        </ul>
      </div>
      <div className="tab-content tab-inner">
        <div className="tab-pane fade show active">
          {locales.active ? (
            <div className="check-status">
              <h5 className="fw-normal mb-3">
                {t("Проверить статус претензии")}
              </h5>
              <div className="d-flex align-items-center justify-content-between">
                <input
                  style={{ width: "420px" }}
                  onChange={(e) => {
                    searchFn(e.target.value.trim());
                  }}
                  type="search"
                  className="form-control"
                  placeholder={t("Введите ID претензии")}
                />
                <button
                  className="new-claim-button text-nowrap btn y-b"
                  onClick={submit}
                >
                  {t("Проверить статус")}
                </button>
              </div>
              {/* <ClaimsList /> */}
            </div>
          ) : (
            <button
              className="new-claim-button btn y-b mt-2 d-flex align-items-center justify-content-center"
              onClick={() => setLocals({ ...locales, visible: true })}
            >
              <i className="ri-add-line"></i> {t("Подать претензию")}
            </button>
          )}
        </div>
      </div>
      <ClaimsAccsessModal modal={locales} setModal={setLocals} />
    </div>
  );
}
