import React from "react";
import ClaimsContentStwitcher from "./ClaimsContentStwitcher";

export default function ClaimPage() {
  return (
    <div className="parcles-content profile-right">
      <ClaimsContentStwitcher />
    </div>
  );
}
