import { useEffect, useRef, useState } from "react";
import CustomModal from "../../../components/CustomModal";
import ReactImageVideoLightbox from "react-image-video-lightbox";

export default function BoxImageModal({ url, boxId, onCancel }) {
  const [show, setShow] = useState(false);
  const ref = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (!ref.current) {
        return;
      }
      if (!ref.current.contains(event.target)) {
        onCancel();
      }
    };
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);
  return (
    <CustomModal
      visible={!!url}
      onCancel={onCancel}
      title={`Box ID: ${boxId}`}
      cssClass="box-image"
    >
      <div
        className="modal-body photo" ref={ref}
        style={{ marginBottom: "20px", marginTop: "10px" }}
      >
        <button className="btn btn-show" onClick={() => setShow(true)}>
          <i className="ri-eye-fill"></i>
        </button>
        <img
          style={{ borderRadius: "10px" }}
          src={url}
          className="w-100"
          width="423"
          height="329"
          alt="box"
        />
        {show && (
          <ReactImageVideoLightbox
            data={[
              {
                url: url,
                type: "photo",
                altTag: "some image",
              },
            ]}
            startIndex={0}
            showResourceCount={false}
            onCloseCallback={() => setShow(false)}
          />
        )}
      </div>
    </CustomModal>
  );
}
