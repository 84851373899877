import React, { forwardRef } from "react";

const InvoiceContent = forwardRef(({ data, className }, ref) => {
  const { address, boxes, order } = data;
  const weight = (w, u) => {
    if (u === "lb/inc") {
      return w * 0.45359237;
    }
    return w;
  };
  return (
    <div ref={ref} className={className}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            style={{
              color: "#f3a421",
              marginBottom: "4px",
              fontSize: "15px",
              fontWeight: "700",
              lineHeight: "20.46px",
            }}
          >
            INVOICE
          </span>
          <span
            style={{
              fontSize: "12px",
              lineHeight: "14.63px",
              color: "#5a5a5a",
              fontWeight: "normal",
              letterSpacing: "0.5px",
            }}
          >
            Yellow way INC
            <br />
            1954 Coney Island Avenue
            <br />
            Brooklyn , NY 11223
          </span>
        </div>
        <div
          style={{
            alignSelf: "center",
            fontSize: "12px",
            lineHeight: "14.63px",
            letterSpacing: "0.5px",
            color: "#5a5a5a",
          }}
        >
          theyellowinc@gmail.com
          <br />
          +1 (929) 244 11 11
        </div>
        <img
          className="logo2"
          src="/images/logo.svg"
          style={{ width: "134px", height: "fit-content" }}
          alt=""
        />
      </div>
      <div
        className="address"
        style={{
          borderRadius: "8px",
          padding: "14px 32px",
          background: "#f3f3f3",
          margin: "24px 0",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "200px" }}>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: "14.63px",
                color: "#333333",
                margin: "0 0 4px",
              }}
            >
              Bill to
            </p>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: "16.8px",
                color: "#5a5a5a",
              }}
            >
              {address[0].label}
            </span>
          </div>
          <div style={{ width: "200px" }}>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: "14.63px",
                color: "#333333",
                margin: "0 0 4px",
              }}
            >
              Ship to
            </p>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: "16.8px",
                color: "#5a5a5a",
              }}
            >
              {address[1].label}
            </span>
          </div>
        </div>
        <hr
          style={{
            margin: "17px 0 32px",
            background: "#957d02",
            height: "1px",
            border: "none",
          }}
        />
        <div style={{ width: "200px" }}>
          <p
            style={{
              fontSize: "12px",
              fontWeight: "700",
              lineHeight: "14.63px",
              color: "#333333",
              margin: "0 0 8px",
            }}
          >
            Ivoice details
          </p>
          <span
            style={{
              fontSize: "12px",
              fontWeight: "700",
              lineHeight: "16.8px",
              color: "#5a5a5a",
            }}
          >
            Invoice number: {order.id}
            <br />
            Box ID: {order.boxes[0]}
            <br />
            Weight (KG) : {weight(order.total_weight, order.unit).toFixed(2)}
            <br />
            Invoice date: {order.created_at.split(",")[0]}
            <br />
            Due date:
          </span>
        </div>
      </div>
      <div className="table-responsive">
        <table
          style={{
            whiteSpace: "nowrap",
            width: "100%",
            padding: "0 42px 13px 30px",
            borderSpacing: "0",
          }}
        >
          <tbody>
            <tr
              style={{
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: "15px",
                textAlign: "left",
              }}
            >
              <th
                style={{ padding: "8px 0", borderBottom: "1px solid #a3a3a3" }}
              >
                #
              </th>
              <th style={{ borderBottom: "1px solid #a3a3a3" }}>Date</th>
              <th style={{ borderBottom: "1px solid #a3a3a3" }}>
                Product or service Description
              </th>
              <th
                style={{
                  textAlign: "center",
                  borderBottom: "1px solid #a3a3a3",
                }}
              >
                Qty
              </th>
              <th
                style={{
                  textAlign: "right",
                  borderBottom: "1px solid #a3a3a3",
                }}
              >
                Rate
              </th>
              <th
                style={{
                  textAlign: "right",
                  borderBottom: "1px solid #a3a3a3",
                }}
              >
                Amount
              </th>
            </tr>
            {boxes[0].products.map((product) => (
              <tr
                key={product.id}
                style={{
                  fontSize: "10px",
                  fontWeight: "500",
                  lineHeight: "15px",
                }}
              >
                <td
                  style={{
                    padding: "8px 0",
                    borderBottom: "1px solid #a3a3a3",
                  }}
                >
                  {product.id}
                </td>
                <td style={{ borderBottom: "1px solid #a3a3a3" }}>
                  {boxes[0].created_at.split(",")[0]}
                </td>
                <td
                  style={{
                    fontWeight: "700",
                    borderBottom: "1px solid #a3a3a3",
                  }}
                >
                  Shipping Service
                </td>
                <td
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid #a3a3a3",
                  }}
                >
                  {order.total_weight}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    borderBottom: "1px solid #a3a3a3",
                  }}
                >
                  ${order.weight_rate}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    borderBottom: "1px solid #a3a3a3",
                  }}
                >
                  ${order.weight_price}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan="3"></td>
              <td
                style={{
                  padding: "13px 0",
                  fontSize: "12px",
                  lineHeight: "15px",
                  textAlign: "center",
                }}
              >
                Total:
              </td>
              <td
                colSpan="2"
                style={{
                  fontSize: "12px",
                  lineHeight: "15px",
                  textAlign: "end",
                  fontWeight: "600",
                }}
              >
                ${order.total_price}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default InvoiceContent;
