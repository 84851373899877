import {
  UPDATE_PAYMENT_GATEWAY,
  UPDATE_PAYMENT_STEP,
} from "../../../../functions/constants";
import { getCurrencyRate } from "../../../../functions/api";
import { priceFormatter } from "../../../../functions/helper";
import { useTranslation } from "react-i18next";

export default function PaymentMethodSelector({
  mainDispatch,
  gateway,
  amount,
  currencyRate,
}) {
  const { t } = useTranslation();
  const handleSelectPayment = async (gateway) => {
    let rate = currencyRate;
    if (gateway === "payme" && !rate) {
      rate = await getCurrencyRate();
    }
    mainDispatch({
      type: UPDATE_PAYMENT_GATEWAY,
      payload: { gateway, currencyRate: rate },
    });
  };
  return (
    <div className="row">
      <div className="col-md-12">
        <div
          onClick={() => handleSelectPayment("stripe")}
          className={`number-card ${gateway === "stripe" ? "active" : ""}`}
        >
          <div className="circle">
            <span></span>
          </div>
          <div className="logos">
            <img className="mx-0" src="/images/logos/visa.svg" alt="visa" />
            <img src="/images/logos/mastercard.svg" alt="mastercard" />
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div
          onClick={() => handleSelectPayment("payme")}
          className={`number-card ${gateway === "payme" ? "active" : ""}`}
        >
          <div className="circle">
            <span></span>
          </div>
          <div className="logos">
            <img
              src="/images/logos/payme_02.svg"
              alt="Payme"
              style={{ width: "30px" }}
            />
            <img
              src="/images/logos/Uzcard.png"
              alt="Uzcard"
              style={{ width: "22px" }}
            />
            <img
              src="/images/logos/Humo.jpg"
              alt="Humo"
              style={{ width: "35px" }}
            />
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="total">
          <p>{t("Сумма оплаты")}</p>
          <h4>
            {gateway === "payme" && currencyRate
              ? `${priceFormatter(
                  Math.round(parseFloat(amount) * parseFloat(currencyRate.Rate))
                )} UZS`
              : `$${amount}`}
            {gateway === "payme" && currencyRate ? (
              <p style={{ fontSize: "13px", marginTop: "2px" }}>
                $1={priceFormatter(currencyRate.Rate)} UZS
              </p>
            ) : (
              ""
            )}
          </h4>
        </div>
      </div>
      <div className="col-12">
        <button
          onClick={() => {
            mainDispatch({ type: UPDATE_PAYMENT_STEP, payload: { step: 2 } });
          }}
          type="button"
          className="send-button btn"
        >
          {t("Далее")}
        </button>
        <p className="bottom-text">
          {t("Пожалуйста свяжитесь с нашим")}{" "}
          <a href="https://t.me/YellowUSA" target="_blank" rel="noreferrer">
            {t("оператором")}
          </a>
        </p>
      </div>
    </div>
  );
}
