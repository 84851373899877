import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Context from "../../functions/context";
import {
  paymentResourceEnum,
  TOGGLE_PAYMENT_MODAL,
  TOGGLE_SUBSCRIPTION_PAYMENT_MODAL,
} from "../../functions/constants";
import CustomModal from "../../components/CustomModal";
import TagManager from "react-gtm-module";

export default function SubsPaymentModal() {
  const { t } = useTranslation();
  const ref = useRef();
  const {
    mainState: { selectedTariff, visibleSubsPaymentModal },
    mainDispatch,
  } = useContext(Context);

  function onClose() {
    mainDispatch({
      type: TOGGLE_SUBSCRIPTION_PAYMENT_MODAL,
      payload: { visible: false, item: selectedTariff },
    });
  }

  const pay = () => {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: "begin_checkout",
        ecommerce: {
          items: [
            {
              item_name: selectedTariff.name,
              item_brand: "Subscription",
              item_category: "Subscription",
              price: selectedTariff.price,
              quantity: 1,
              currency: "USD",
            },
          ],
        },
      },
    });
    mainDispatch({
      type: TOGGLE_PAYMENT_MODAL,
      payload: {
        resource: paymentResourceEnum.subscription,
        resourceId: selectedTariff.id,
        saveCard: true,
        amount: parseFloat(selectedTariff.price).toFixed(2),
        visible: true,
      },
    });
    onClose();
  };

  useEffect(() => {
    const handler = (event) => {
      if (!ref.current) {
        return;
      }
      if (!ref.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  return (
    <CustomModal
      id="paymentModal"
      onCancel={onClose}
      visible={visibleSubsPaymentModal}
      cssClass="payment-modal billing"
    >
      <div className="modal-body" ref={ref}>
        <button className="close btn" onClick={onClose}>
          <img src="/images/cancel.svg" alt="close" />
        </button>
        <div className="modal-info">
          <h4 className="title">{t("Billing information")}</h4>

          <div className="info-content">
            <div className="info-item">
              <p>{selectedTariff.name}</p>
              <p>${selectedTariff.price}</p>
            </div>
            <div className="dashed" />
            <div className="info-item">
              <p className="total-label">{t("Итого")}</p>
              <p className="total-value">
                ${parseFloat(selectedTariff.price).toFixed(2)}
              </p>
            </div>
          </div>
          <button onClick={pay} className="btn order-button">
            {t("Оплатить")}
          </button>
          <div className="bottom-warning">
            <img src="/images/icons/warning.svg" alt="warning" />
            <span>
              {t(
                "*Размещая этот заказ, вы соглашаетесь с нашим пользотельскими условиями"
              )}
            </span>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}
