import {normalizeErrorFields} from "./request-helper";

export const requiredConstraint = (value, message) => (value ? undefined : (message || 'Required'));

export const compareConstraint = (compareValue) => (value) =>
    value !== compareValue ? 'Must be match' : undefined;

export const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined);

export const minValueConstraint = min => value =>
    isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`

export const minLengthConstraint = min => value =>
    isNaN(value) || value.length >= min ? undefined : `Length should be greater than ${min} symbols`

export const maxLengthConstraint = max => value =>
    isNaN(value) || value.length < max ? undefined : `Length should be less than ${max} symbols`

export const lengthEqualConstraint = length => value =>
    isNaN(value) || value.length === length ? undefined : `Length should be equal ${length} symbols`

export const cardExpireConstraint = (value) => {
    const matched = value.match(/(\d{0,2})(\d{0,2})/);
    if (matched[1] && parseInt(matched[1]) > 12) {
        return 'Expire month must be less than 12';
    }
    if (matched[2] && parseInt(matched[2]) < parseInt(String((new Date).getFullYear()).slice(2))) {
        return 'Expire year must be greater than current year';
    }
    return undefined;
}


export const renderErrorBlock = (meta) => {
    return (
        (meta.error || meta.submitError) && meta.touched &&
        <div
            className="help-block">{meta.error || meta.submitError}
        </div>
    )
};

export const isUrlConstraint = url => {
    const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const regex = new RegExp(expression);
    return url.match(regex) ? undefined : 'Invalid url format';
}

export const hasErrorInput = (meta) => {
    return (meta.error || meta.submitError);
};

export const normalizeExceptionError = (e) => {
    if (e?.response?.status === 422) {
        const error = e.response.data;
        const fieldErrors = normalizeErrorFields(error);
        if (Object.values(fieldErrors).length) {
            return fieldErrors;
        }
    }
}
