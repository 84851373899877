import { useNavigate } from 'react-router-dom'
import SocialNetworks from '../components/SocialNetworks'

export default function Intro() {
  const navigate = useNavigate()

  return (
   <>
    <section id="intro">
      <div
        className="intro-row"
        onClick={() => {
          localStorage.setItem('country', 'USA')
          navigate('/for-usa')
        }}
      >
        <div className="intro-line" />
        <div className="intro-item">
          <h5>I am in</h5>
          <h1>United States</h1>
        </div>
      </div>
      <div
        className="intro-row"
        onClick={() => {
          localStorage.setItem('country', 'UZB')
          navigate('/for-uzbek')
        }}
      >
        <div className="intro-item">
          <h5>I am in</h5>
          <h1>Uzbekistan</h1>
        </div>
      </div>
    </section>
      <SocialNetworks /> </>
  )
}
