import React, { useContext } from 'react'
import { UPDATE_PAYMENT_STEP } from '../../functions/constants'
import Context from '../../functions/context'
import { useTranslation } from 'react-i18next'

export default function ModalCloseHeader({ onClose }) {
    const {
        mainState: { paymentStep },
        mainDispatch,
    } = useContext(Context)
    const { t } = useTranslation();


    return (
        <div className="modal-header-buttons">
            {paymentStep === 2 ? (
                <h5 className="title">
                    <button
                        className="btn back"
                        onClick={() => {
                            mainDispatch({ type: UPDATE_PAYMENT_STEP, payload: { step: 1 } })
                        }}
                    >
                        <img src="/images/new/old-btn.svg" alt="back" /> {paymentStep === 2 ? t("Назад") : ''}
                    </button>
                </h5>
            ) : ''}
            {paymentStep === 1 ? <h5 className="title">{t("Выберите метод оплаты")}</h5> : ''}
            {paymentStep === 3 ? <h5 className="title">{t("Платёж успешно переведён")}</h5> : ''}
            <button className="close btn" onClick={onClose}>
                <img src="/images/cancel.svg" alt="close" />
            </button>
        </div>
    )
}
