import { useEffect, useRef } from "react";
import useConfirm from "../functions/use-confirm";
import { useTranslation } from "react-i18next";

const ConfirmDialog = () => {
  const { t } = useTranslation();
  const { onConfirm, onCancel, confirmState } = useConfirm();
  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (!ref.current) {
        return;
      }
      if (!ref.current.contains(event.target)) {
        onCancel();
      }
    };
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  return confirmState.show ? (
    <div className="modal fade show confirm" style={{ display: "block" }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" ref={ref}>
          <div className="modal-header   border-0">
            <h5 className="modal-title mx-auto" id="deleteModalLabel">
              {t(confirmState.texts.title)}
            </h5>
          </div>
          <div className="modal-body text-center">
            <div className="col-12">
              <p className="logout-text text-muted">
                {t(confirmState.texts.text && confirmState.texts.text)}
              </p>
            </div>
            <div className="col-12 mt-3">
              <button
                className="btn confirm cancel logout-btn m-1"
                onClick={onConfirm}
              >
                {t(confirmState.texts.yes)}
              </button>
              <button
                type="button"
                onClick={onCancel}
                className="btn cancel-btn  m-1"
              >
                {t(confirmState.texts.no)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};
export default ConfirmDialog;
