import { Link, useResolvedPath, useMatch } from 'react-router-dom';

export default function ActiveLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, caseSensitive: true, end: false });

  return (
    <Link
      to={to}
      {...props}
      className={`${props.className || ''} ${match ? props.activeClass || 'active' : ''}`}
    >
      {children}
    </Link>
  );
}
