import {useResolvedPath, useMatch, useNavigate} from "react-router-dom";

export default function ActiveButtonLink({children, to, ...props}) {
    let resolved = useResolvedPath(to);
    let match = useMatch({path: resolved.pathname, end: false});
    const navigate = useNavigate();

    return (
        <button
            onClick={() => navigate(to)}
            className={`${props.className || ''} ${match ? (props.activeClass || 'active') : ''}`}>
            {children}
        </button>
    );
}