import React from "react";
import { useTranslation } from "react-i18next";

export default function WarningPopup() {
  const { t } = useTranslation();
  return (
    <div id="sms-box" className="sms box">
      {/* <div className="box-arrow-border">
				<div className="box-arrow-background"></div>
			</div> */}
      {/* <div className="box-content"> */}
      {t("Send us a message")}
      {/* </div> */}
    </div>
  );
}
