import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ShopCategories, menus } from "../functions/constants";
import { debounce } from "../functions/helper";
import SearchResult from "./SearchResult";

export default function SearchForm({
  searchActive,
  setActive,
  inputRef,
  shops = [],
}) {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const lang = window.localStorage.getItem("locale");
  const [hidden, setHidden] = useState(false);
  const [result, setResult] = useState();
  function searchFn(value) {
    debounce(
      () => {
        setQuery(value);
        setResult();
      },
      "inputQuery",
      500
    );
  }
  useEffect(() => {
    if (query.length >= 1) {
      setHidden(true);
      const shopsResult = shops?.filter((shop) =>
        shop.title.toLowerCase().startsWith(query.toLowerCase())
      );
     
      const menuResult = menus[lang]?.filter((menu) =>
        menu.name.toLowerCase().startsWith(query.toLowerCase())
      );

      const categoryResult = ShopCategories.filter((catName) =>
        t(`shop.${catName}`).toLowerCase().startsWith(query.toLowerCase())
      );

      if (shopsResult?.length || menuResult?.length || categoryResult?.length) {
        setResult({
          menus: menuResult,
          shops: shopsResult,
          categories: categoryResult,
        });
      }
    }
  }, [query]);
  useEffect(() => {
    if (!searchActive) {
      inputRef.current.value = "";
      setHidden(false);
    } else {
      inputRef.current.focus();
    }
  }, [searchActive]);

  return (
    <>
      <div className={`header-search ${searchActive ? `active` : ``}`}>
        <form method="get" onSubmit={(e) => e.preventDefault()} noValidate>
          <div className={`header-search-wrapper`}>
            <input
              ref={inputRef}
              onBlur={() => {
                setTimeout(() => {
                  setHidden(false);
                  setActive(false);
                  setResult();
                }, 500);
              }}
              onChange={(e) => {
                searchFn(e.target.value.trim());
              }}
              type="search"
              className="form-control"
              autoComplete="off"
              id={"searchInput"}
              placeholder={t("search")}
              required
            />
            <button className="btn search-button">
              <i className="ri-search-line" style={{ fontSize: 21 }} />
            </button>
          </div>
        </form>
        {hidden && <SearchResult result={result} />}
      </div>
    </>
  );
}
