import { useContext, useMemo } from 'react'
import Context from '../../../functions/context'
import { useTranslation } from 'react-i18next';

export default function TrackingDetail({ values }) {
  const { t } = useTranslation();

  const {
    mainState: { addresses },
  } = useContext(Context)

  const totalPrice = useMemo(() => {
    return (values?.products || []).reduce((t, r) => {
      return r?.quantity * (r?.unitPrice || r?.unit_price) + t
    }, 0)
  }, [])
  return (
    <>
      <div className="parcels-list-declaration">
        <p className="item-title">{t("Декларация")}</p>
        {(values?.products || []).map((record, index) => {
          return (
            <div key={`product_${index}`} className="item-declaration y-b">
              <div>
                <div className="item-deckaration__name">{t("Название")}</div>
                <div className="item-deckaration__value">
                  {record?.product_name || record?.productName}
                </div>
              </div>
              <div>
                <div className="item-deckaration__name">{t("Количество")}</div>
                <div className="item-deckaration__value">
                  {record?.quantity}
                </div>
              </div>
              <div>
                <div className="item-deckaration__name">{t("Итог")}</div>
                <div className="item-deckaration__value">
                  $
                  {record?.quantity * (record?.unitPrice || record?.unit_price)}
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <p className="item-title mt-4">{t("Товар(ы) для отправки")}</p>
      <div className="parcels-list-details y-b">
        <ul>
          <li className="item-detail">
            <span className="item-left">{t("Общая стоимость товаров")}</span>
            <span className="item-right">${totalPrice}</span>
          </li>
        </ul>
      </div>
    </>
  )
}
