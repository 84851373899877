import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "../../../components/CustomModal";
import { useNavigate } from "react-router-dom";
import { SET_CLAIMS } from "../../../functions/constants";
import Context from "../../../functions/context";

export default function ClaimsAccsessModal({ modal, setModal }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mainDispatch } = useContext(Context);

  const submit = () => {
    const isChecked = document.getElementById("isChecked").checked;
    if (isChecked) {
      mainDispatch({
        type: SET_CLAIMS,
        payload: {
          step: 0,
          data: null,
        },
      });
      navigate("create");
    }
  };

  return (
    <CustomModal
      visible={modal.visible}
      onCancel={() => setModal({ ...modal, visible: false })}
      cssClass="claim-access-modal"
      title={t("Продолжить подачу претензии")}
    >
      <label
        className="mt-5 d-flex align-items-center justify-content-center checkbox-label content-right-body-checkbox-label"
        htmlFor="isChecked"
      >
        <input
          style={{ marginRight: "10px" }}
          type="checkbox"
          required
          id="isChecked"
          className="checkbox d-inline"
        />
        {t("При нажатии “Да” вы соглашайтесь с нашим")}
      </label>

      <a href="/terms" target="_blank" className="d-block text-center">
        {t("Условием соглашения.")}
      </a>
      <button className="btn y-b mt-5 m-auto" onClick={submit}>
        {t("Продолжить")}
      </button>
    </CustomModal>
  );
}
