import RegisterForm from './components/RegisterForm'
import { useContext, useState } from 'react'
import RegisterVerification from './components/RegisterVerification'
import Context from '../../functions/context'
import {useTranslation} from "react-i18next";
import SocialNetworks from '../../components/SocialNetworks';

export default function RegistrationPage() {
  const [customer, setCustomer] = useState(null)
  const [password, setPassword] = useState(null)
  const { mainDispatch } = useContext(Context)
  const {t} = useTranslation();

  return (
    <>
      <div id="registerContent" className="container-fluid">
        <div className="register-content">
          <div className="content-left" />
          <div className="boxes">
            <div className="content-right">
              <div className="content-header">
                <img
                  className="phoneContent mb-2"
                  src="/images/logo.svg"
                  alt="logo"
                />
                <h1 className="content-right-title">{t('menu.Registration')}</h1>
                <p className="content-right-text" style={{ maxWidth: '380px' }}>
                  {!customer
                    ? t('Sign Up to use the full capabilities of the system you need to pass')
                    : t('Enter the code that was sent to your email')}
                </p>
              </div>
              <div className="content-body">
                <Context.Provider
                  value={{
                    customer,
                    setCustomer,
                    password,
                    setPassword,
                    mainDispatch,
                  }}
                >
                  {!customer ? <RegisterForm /> : <RegisterVerification />}
                </Context.Provider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SocialNetworks /> 
    </>
  )
}
