import React, { useContext, useEffect } from "react";
import Context from "../functions/context";
import { SHOWED_MODAL, reviewStars } from "../functions/constants";

function CustomModal({
  visible,
  title,
  onCancel,
  children,
  size,
  cssClass,
  secondary,
  id,
  stars = null,
  options,
}) {
  const { mainDispatch } = useContext(Context);
  useEffect(() => {
    if (visible) {
      mainDispatch({ type: SHOWED_MODAL, payload: true });
    }
    return () => {
      if (visible && !secondary) {
        mainDispatch({ type: SHOWED_MODAL, payload: false });
      }
    };
  }, [visible]);

  return (
    <div
      className={`modal fade ${visible ? "show" : ""} ${cssClass || ""}`}
      style={{ display: visible ? "block" : "none" }}
      id={id || "mainModal"}
    >
      <div
        className={`modal-dialog modal-dialog-centered ${
          size ? `modal-${size}` : ""
        } ${options?.dialogClass || ""}`}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className={`modal-title ${options?.titleClass || ""}`}>
              {title}{" "}
              {stars
                ? reviewStars.map((star) =>
                    star <= stars ? (
                      <img src="/images/star.svg" alt="star" />
                    ) : (
                      <img src="/images/emptyStar.svg" alt="star" />
                    )
                  )
                : null}
            </h4>
            <button className="close btn p-0" onClick={onCancel}>
              <img src="/images/cancel.svg" alt="close" />
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default CustomModal;
