import React from "react";
import { useTranslation } from "react-i18next";

export default function PaymentFinished({
  amount,
  onClose,
  currencyRate,
  gateway,
}) {
  const {t}=useTranslation(); 


  return (
    <div className="decloration-form">
      <img src="/images/done.svg" alt="finished" />
      <h1 className="parcels-list-title">
        {t("Оплачено")}{" "}
        {gateway === "payme" && currencyRate
          ? `${Math.round(
              parseFloat(amount) * parseFloat(currencyRate.Rate)
            )} UZS`
          : `$${amount}`}
      </h1>
      <p>{t("Чек платежа будет отправлена на вашу почту")}</p>
      <button className="btn thanks" onClick={() => onClose()}>
        {t("Спасибо")}
      </button>
    </div>
  );
}
