import {useNavigate, useSearchParams} from "react-router-dom";
import {useContext, useEffect} from "react";
import {loadProfile} from "../functions/api";
import {LOAD_PROFILE} from "../functions/constants";
import Context from "../functions/context";

export default function OauthCallbackPage() {
    const {mainDispatch} = useContext(Context);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (searchParams.get('token')) {
            const token = searchParams.get('token');
            loadProfile(token)
                .then(({user}) => {
                    localStorage.setItem('cabinet_token', token);
                    mainDispatch({type: LOAD_PROFILE, payload: user});
                    setTimeout(() => {
                        navigate('/cabinet/profile');
                    }, 200);
                })
                .catch(() => {
                    navigate('/login');
                })
        }
    }, [searchParams.get('token')]);
    return <>Redirecting...</>
}