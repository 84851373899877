import { useContext, useEffect, useState } from "react";
import Context from "../../../functions/context";
import isEmpty from "lodash/isEmpty";
import { findTrackingBox } from "../../../functions/api";
import {
  SET_TRACKING_ORDER,
  UNSET_TRACKING_ORDER,
} from "../../../functions/constants";
import { lbToKg } from "../../../functions/helper";
import BoxImageModal from "./BoxImageModal";
import Review from "./Review";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function TrackingInfoCard({ individual = false }) {
  const { t } = useTranslation();
  const {
    mainState: {
      mainTracking: {
        box,
        step,
        sender_address,
        recipient_address,
        order,
        shipment,
        delivery,
        estimatedDate,
      },
    },
    mainDispatch,
  } = useContext(Context);
  const [boxValue, setBoxValue] = useState("");
  const [boxImageUrl, setBoxImageUrl] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isEmpty(boxValue)) {
      findTrackingBox(boxValue).then((response) => {
        if (response.box) {
          mainDispatch({ type: SET_TRACKING_ORDER, payload: response });
        } else {
          mainDispatch({ type: UNSET_TRACKING_ORDER });
        }
      });
    }
  };

  useEffect(() => {
    return () => {
      mainDispatch({ type: UNSET_TRACKING_ORDER });
    };
  }, []);

  return (
    <>
      <div
        id="tracking"
        className="track container-fluid mb-370 y-b"
        style={
          individual ? { backgroundColor: "white", borderRadius: "10px" } : {}
        }
      >
        <div className="container">
          <div className={`steep-${step}`}>
            <form
              onSubmit={handleSubmit}
              name="search-id"
              className="track-search"
            >
              <div className="track-search-input">
                <input
                  value={boxValue}
                  style={individual ? { border: "1px solid #f3d127" } : {}}
                  type="text"
                  id="search-id-0"
                  name="search-id"
                  placeholder={t("Enter your Box ID here")}
                  onChange={(e) => setBoxValue(e.target.value)}
                />
                <button style={{ color: "black" }} type="submit" id="track-id">
                  {t("Search")}
                </button>
              </div>
            </form>
            {!box && !individual ? (
              <>
                <div className="track-search-help text-center">
                  <Link to="/help">{t("NEED HELP?")}</Link>
                </div>

                <div className="steep-0-bottom y-b"></div>
              </>
            ) : (
              ""
            )}
            {box ? (
              <>
                <div className={`steep-1-main`}>
                  <span className={`steep-1-main-line`}></span>
                  <div className={`steep-1-main-item`}>
                    <span
                      className={`tooltips ${step >= 1 ? "active" : ""} ${
                        step === 1 ? "wh-55" : ""
                      }`}
                    >
                      <div className="tooltipText">
                        {t("ваша коробка находится в")}{" "}
                        {t(sender_address.country.name)}
                      </div>
                    </span>
                    <div className={`steep-1-main-item-text`}>
                      {/* Ваша коробка находится в США */}
                      <h4>
                        {t("Pending")}{" "}
                        <span>
                          ({t("в")} {t(sender_address.country.name)})
                        </span>
                      </h4>
                    </div>
                  </div>

                  <div className={`steep-1-main-item`}>
                    <span
                      className={`tooltips ${step >= 2 ? "active" : ""} ${
                        step === 2 ? "wh-55" : ""
                      }`}
                    >
                      <div className="tooltipText">
                        {t("ваша коробка отправлена из")}{" "}
                        {t(sender_address.country.name)}
                      </div>
                    </span>
                    <div className={`steep-1-main-item-text`}>
                      <h4>
                        {t("Sent")}{" "}
                        <span>
                          ({t("отправлена из")} {t(sender_address.country.name)}
                          )
                        </span>
                      </h4>
                    </div>
                  </div>
                  <div className={`steep-1-main-item`}>
                    <span
                      className={`tooltips ${step >= 3 ? "active" : ""} ${
                        step === 3 ? "wh-55" : ""
                      }`}
                    >
                      <div className="tooltipText">
                        {t("ваша коробка находится на таможенном досмотре в")}{" "}
                        {t(recipient_address.country.name)}
                      </div>
                    </span>
                    <div className={`steep-1-main-item-text`}>
                      <h4>
                        {t("In customs")}
                        <br />
                        <span style={{ whiteSpace: "pre-wrap" }}>
                          ({t("на таможенном досмотре в")}{" "}
                          {t(recipient_address.country.name)})
                        </span>
                      </h4>
                    </div>
                  </div>
                  <div className={`steep-1-main-item`}>
                    <span
                      className={`tooltips ${step >= 4 ? "active" : ""} ${
                        step === 4 ? "wh-55" : ""
                      }`}
                    >
                      <div className="tooltipText">
                        {t("ваша коробка прибыла из")}{" "}
                        {t(recipient_address.country.name)}
                      </div>
                    </span>
                    <div className={`steep-1-main-item-text`}>
                      <h4>
                        {t("Arrived")}{" "}
                        <span>
                          ({t("посылка прибыла в")}{" "}
                          {t(recipient_address.country.name)})
                        </span>
                      </h4>
                    </div>
                  </div>
                

                  <div className={`steep-1-main-item`}>
                    <span
                      className={`tooltips ${step >= 5 ? "active" : ""} ${
                        step === 5 ? "end wh-55" : ""
                      }`}
                    >
                      <div className="tooltipText">
                        {t("ваша коробка доставлена получателю")}
                      </div>
                    </span>
                    <div className={`steep-1-main-item-text`}>
                      <h4>
                        {t("Delivered")}{" "}
                        <span className="d-sm-block d-none">
                          ({t("доставлена получателю")})
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className={`steep-1-main align-items-start`}>
                  <div className={`steep-1-main-item-bottom`}>
                    <h5>{t("From")}</h5>
                    <h3>
                      {[
                        sender_address?.city,
                        sender_address?.region?.name,
                        sender_address?.country?.name,
                      ]
                        .filter((v) => v)
                        .join(", ")}
                    </h3>
                  </div>
                  <div className={`steep-1-main-item-bottom`}>
                    <h5>{t("To")}</h5>
                    <h3>
                      {[
                        recipient_address?.serialize_district?.name,
                        recipient_address?.region?.name,
                        recipient_address?.country?.name,
                      ]
                        .filter((v) => v)
                        .join(", ")}
                    </h3>
                  </div>
                </div>
                {estimatedDate && step !== 5 ? (
                  <div
                    className={`estemate-time d-flex align-items-center justify-content-between`}
                  >
                    <h6 className="mb-0">{t("Estimated delivery date:")}</h6>
                    <p className="mb-0">
                      {" "}
                      {estimatedDate} {t("Tashkent timezone")}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
          {box && step !== 5 ? (
            <div className={`steep-${step === 5 ? 4 : 1}-bottom`}>
              <div className="thead">
                <p>{t("Order ID")}</p>
                <p>{t("Box ID")}</p>
                <p>
                  {step === 1
                    ? t("Created date")
                    : step === 2 || step === 3
                    ? t("Sent at")
                    : t("Arrived at")}
                </p>
                <p>{t("Weight")}</p>
                <p>{t("Shipper city, state")}</p>
                <p>{t("Recipient city, state")}</p>
              </div>

              <div className="tbody">
                <p>{box.order}</p>
                <p>{box.id}</p>
                <p>{order.created_at}</p>
                <p>
                  {box.weight} lb / {lbToKg(box.weight)} kg
                </p>
                <p>
                  {sender_address?.region?.name} {sender_address?.country?.name}
                </p>
                <p>
                  {recipient_address?.region?.name}{" "}
                  {recipient_address?.country?.name}
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          {box && step === 5 ? (
            <div className="steep-4-bottom">
              <ul className="steep-4-bottom-ul">
                <li className="m-0">
                  <span className="line"></span>
                </li>
                <li className="row">
                  <span className="item-dot"></span>
                  <p className="desc1 col-md-4 col-12">
                    <p>{t("Дата создания")}:</p>
                    &nbsp;{" "}
                    <p>
                      {shipment && shipment.sent_at
                        ? shipment.sent_at
                        : order.created_at}
                    </p>
                  </p>
                </li>
                {shipment ? (
                  <li className="row">
                    <span className="item-dot"></span>
                    <p className="desc1 col-md-4 col-12">
                      <p>{t("Дата отправки")}:</p>
                      &nbsp;<p>{shipment.sent_at}</p>
                    </p>
                  </li>
                ) : (
                  ""
                )}
                {shipment ? (
                  <li className="row">
                    <span className="item-dot"></span>
                    <p className="desc1 col-md-4 col-12">
                      <p>{t("Дата доставки")}:</p>&nbsp;
                      <p>{shipment.delivered_at || shipment.arrival_date}</p>
                    </p>
                  </li>
                ) : (
                  ""
                )}
                {step === 5 && (delivery?.signature || delivery?.box_image) ? (
                  <li className="row">
                    <span className="item-dot"></span>
                    <div className="steep-4-bottom-img p-0 row">
                      {step === 5 ? (
                        <div className="rew col-md-4 col-12">
                          <Review box={boxValue} />
                        </div>
                      ) : null}
                      {delivery?.signature ? (
                        <div className="col-md-4 col-6 sig">
                          <div className="content y-b">
                            <p style={{ textAlign: "left" }}>
                              {t("Подпись получателя")}
                            </p>
                            <img
                              src={delivery?.signature.url}
                              alt="signature"
                              weight="72"
                              height="75"
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {delivery?.box_image ? (
                        <div className="col-md-4 col-6 box">
                          <div className="content y-b">
                            <p style={{ textAlign: "left" }}>
                              Box ID: {box?.id}
                            </p>
                            <button
                              className="btn"
                              onClick={() =>
                                setBoxImageUrl(delivery?.box_image.url)
                              }
                            >
                              <img
                                src={delivery?.box_image.url}
                                alt="signature"
                                weight="72"
                                height="75"
                              />
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <BoxImageModal
        url={boxImageUrl}
        boxId={box?.id}
        onCancel={() => setBoxImageUrl(null)}
      />
    </>
  );
}
