import React, { useContext, useEffect, useRef } from "react";
import Context from "../../../../functions/context";
import CustomModal from "../../../../components/CustomModal";
import {
  ADD_UPDATE_ORDER,
  paymentResourceEnum,
  TOGGLE_PAYMENT_MODAL,
  UPDATE_PAYMENT_STEP,
  UPDATE_SHOP_RECORD,
} from "../../../../functions/constants";
import PaymentMethodSelector from "./PaymentMethodSelector";
import StripePayment from "./StripePayment";
import PaymentFinished from "./PaymentFinished";
import ModalCloseHeader from "../../../../components/Modal/ModalCloseHeader";
import { readOrder, readShopRecord } from "../../../../functions/api";
import PaymePayment from "./PaymePayment";

export default function PaymentCreateModal() {
  const {
    mainState: {
      uuid,
      resource,
      resourceId,
      amount,
      paymentGateway: gateway,
      paymentStep,
      currencyRate,
      visibleCreatePaymentModal,
      saveCard,
    },
    mainDispatch,
  } = useContext(Context);

  const onClose = () => {
    mainDispatch({ type: TOGGLE_PAYMENT_MODAL, payload: { visible: false } });
    if (paymentStep >= 3) {
      if (resource === paymentResourceEnum.shop) {
        readShopRecord(resourceId).then((changedRecord) => {
          mainDispatch({ type: UPDATE_SHOP_RECORD, payload: changedRecord });
        });
      } else if (resource === paymentResourceEnum.order) {
        readOrder(resourceId).then((changedOrder) => {
          mainDispatch({ type: ADD_UPDATE_ORDER, payload: changedOrder });
        });
      }
    }
  };

  const ref = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (!ref.current) {
        return;
      }
      if (!ref.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  return (
    <CustomModal
      title=""
      id="account-modal"
      onCancel={onClose}
      visible={visibleCreatePaymentModal}
      cssClass={`payment-modal ${
        paymentStep === 3 ? "parcels-order finish" : ""
      }`}
    >
      <div className="modal-body" ref={ref}>
        <ModalCloseHeader onClose={onClose} />
        {paymentStep !== 3 ? (
          <div className="my-accounts">
            <div className="row balls-row">
              <div className="col-md-12 px-0">
                <div className="balls-box">
                  {paymentStep === 1 ? (
                    <PaymentMethodSelector
                      mainDispatch={mainDispatch}
                      amount={amount}
                      gateway={gateway}
                      currencyRate={currencyRate}
                    />
                  ) : (
                    ""
                  )}
                  {paymentStep === 2 && gateway === "stripe" ? (
                    <StripePayment
                      uuid={uuid}
                      resource={resource}
                      resourceId={resourceId}
                      amount={amount}
                      successCallback={() => {
                        mainDispatch({
                          type: UPDATE_PAYMENT_STEP,
                          payload: { step: 3 },
                        });
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {paymentStep === 2 && gateway === "payme" ? (
                    <PaymePayment
                      uuid={uuid}
                      resource={resource}
                      resourceId={resourceId}
                      amount={amount}
                      saveCard={saveCard}
                      successCallback={() => {
                        mainDispatch({
                          type: UPDATE_PAYMENT_STEP,
                          payload: { step: 3 },
                        });
                      }}
                      currencyRate={currencyRate}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {paymentStep === 3 ? (
          <PaymentFinished
            onClose={onClose}
            amount={amount}
            currencyRate={currencyRate}
            gateway={gateway}
          />
        ) : (
          ""
        )}
      </div>
    </CustomModal>
  );
}
