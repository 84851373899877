import React from "react";
import { getDiscountPercent } from "../functions/helper";
import { useTranslation } from "react-i18next";

export default function ProductCard({ product }) {
  const { t } = useTranslation();
  
  return (
    <a href={product.link} className="product-card" target="_blank">
      <div className="product-header">
        {product.image ? (
          <img src={product.image.url} alt={product.title} />
        ) : (
          ""
        )}
        {product.original_price ? (
          <span className="discount">
            {getDiscountPercent(product.original_price, product.price)}%{" "}
            {t("SALE")}
          </span>
        ) : null}
      </div>
      <div className="product-body">
        <div className="name-button">
          <div className="name">
            <h4>{product.title}</h4>
            {product.short ? <span>{product.short}</span> : ""}
          </div>
          <a target="_blank" href={product.link} className="btn">
            {t("Купить")}
          </a>
        </div>
        <div className="price">
          <h5>${product.price}</h5>
          {product.original_price ? (
            <span>${product.original_price}</span>
          ) : null}
        </div>
      </div>
    </a>
  );
}
