import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {
  composeValidators,
  isUrlConstraint,
  minValueConstraint,
  requiredConstraint,
} from "../../../functions/validator";
import { useTranslation } from "react-i18next";

export default function ShopForm({ form, initialValues }) {
  const { t } = useTranslation()
  return (
    <div className="decloration-form">
      <div className="col">
        <h1 className="parcels-list-title">{t("Товары для покупки")}</h1>
        <p className="parcels-list-text mt-3">
          {t("Пожалуйста дайте нам знать какие товары вы хотите чтобы мы купили. Обработка заказов происходит в течение 1-2 дней.")}
        </p>
      </div>
      <Form
        form={form}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        render={({
          handleSubmit,
          form: {
            mutators: { push },
          },
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="products row y-b">
                <p className="m-0 col-md-2">{t("Декларация")}</p>
                <div className="col-md-10">
                  <FieldArray name="records">
                    {({ fields }) => {
                      return fields.map((fieldName, index) => {
                        const total =
                          (values.records[index]?.price || 0) *
                          (values.records[index]?.quantity || 1);
                        return (
                          <div key={`records_${index}`}>
                            <div
                              className="products-info"
                              key={`records_${index}`}
                            >
                              <Field
                                name={`${fieldName}.link`}
                                validate={composeValidators(
                                  requiredConstraint,
                                  isUrlConstraint
                                )}
                              >
                                {({ input, meta }) => (
                                  <div className="col-md-10 mb-3">
                                    <input
                                      className={`form-control ${(meta.error || meta.submitError) &&
                                          meta.touched
                                          ? `error-border`
                                          : ``
                                        }`}
                                      placeholder={t("Ссылка на товар*")}
                                      {...input}
                                    />
                                  </div>
                                )}
                              </Field>
                              <Field
                                name={`${fieldName}.name`}
                                validate={composeValidators(requiredConstraint)}
                              >
                                {({ input, meta }) => (
                                  <div className="product-name">
                                    <input
                                      className={`form-control ${(meta.error || meta.submitError) &&
                                          meta.touched
                                          ? `error-border`
                                          : ``
                                        }`}
                                      placeholder={t("Название товара*")}
                                      {...input}
                                    />
                                  </div>
                                )}
                              </Field>
                              <Field
                                name={`${fieldName}.price`}
                                type="number"
                                validate={composeValidators(
                                  requiredConstraint,
                                  minValueConstraint(1)
                                )}
                              >
                                {({ input, meta }) => (
                                  <div className={`track-price `}>
                                    <input
                                      type="text"
                                      placeholder={t("Цена за ед*")}
                                      className={`form-control ${(meta.error || meta.submitError) &&
                                          meta.touched
                                          ? `error-border`
                                          : ``
                                        }`}
                                      name="track-price"
                                      {...input}
                                    />
                                    <span className="input-btn">$</span>
                                  </div>
                                )}
                              </Field>
                              <Field
                                name={`${fieldName}.quantity`}
                                type="number"
                                validate={composeValidators(
                                  requiredConstraint,
                                  minValueConstraint(1)
                                )}
                              >
                                {({ input, meta }) => (
                                  <div className={`track-count`}>
                                    <input
                                      type="number"
                                      className={`form-control ${(meta.error || meta.submitError) &&
                                          meta.touched
                                          ? `error-border`
                                          : ``
                                        }`}
                                      name="track-count"
                                      min="1"
                                      placeholder={t("Количество*")}
                                      {...input}
                                    />
                                    <span className="input-btn">{t("шт")}</span>
                                  </div>
                                )}
                              </Field>
                              <p className="total">
                                {t("Итого")}:{" "}
                                <span>
                                  $
                                  {(values.records[index]?.price || 0) *
                                    (values.records[index]?.quantity || 1)}
                                </span>
                              </p>
                              {fields.length > 1 ? (
                                <button
                                  type="button"
                                  className="btn modal-outline-btn"
                                  onClick={() => fields.remove(index)}
                                >
                                  <i className="ri-delete-bin-2-line"></i>
                                </button>
                              ) : (
                                ""
                              )}
                              {total > 1000 ? (
                                <div className="warning">
                                  <img
                                    src="/images/icons/alert.svg"
                                    alt="alert"
                                  />
                                  <span>
                                    {t("Лимит беспошлинного ввоза превышен.")}
                                  </span>
                                </div>
                              ) : null}
                              <div className="w-100 d-flex align-items-center">
                                <Field name={`${fieldName}.color`} type="text">
                                  {({ input, meta }) => (
                                    <div className="track-price record-field">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder={t("Цвет")}
                                        {...input}
                                      />
                                    </div>
                                  )}
                                </Field>
                                <Field name={`${fieldName}.size`} type="text">
                                  {({ input, meta }) => (
                                    <div className="track-price record-field">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder={t("Размер")}
                                        {...input}
                                      />
                                    </div>
                                  )}
                                </Field>
                              </div>
                            </div>
                            {fields.length > 1 &&
                              fields.length - 1 !== index ? (
                              <div className="col">
                                <hr className="my-4" />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      });
                    }}
                  </FieldArray>
                  <button
                    type="button"
                    className="btn new-address-btn my-3"
                    onClick={() => push("records", null)}
                  >
                    <i className="ri-add-line"></i> {t("Добавить новый заказ")}
                  </button>
                </div>
              </div>
              <div className="tracking row y-b">
                <Field name="note">
                  {({ input }) => (
                    <div className="col-md-12">
                      <textarea
                        {...input}
                        rows="3"
                        placeholder={t("Комментарий для оператора")}
                      ></textarea>
                    </div>
                  )}
                </Field>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
}
