import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { SET_CLAIMS } from "../../../functions/constants";
import Context from "../../../functions/context";

const claimOptions = [
  {
    label: "lost_box",
    value: "lost_box",
  },
  {
    label: "Поврежденная",
    value: "damage",
  },
  {
    label: "Потерянные предметы внутри",
    value: "lost_item",
  },
  {
    label: "Задержка доставки",
    value: "delay_delivery",
  },
  {
    label: "Не прибыл в склад",
    value: "not_arrive_warehouse",
  },
  {
    label: "Другое",
    value: "other",
  },
];

export default function ClaimSelector() {
  const { t } = useTranslation();
  const { mainDispatch } = useContext(Context);

  const onChange = (e) => {
    mainDispatch({
      type: SET_CLAIMS,
      payload: {
        step: 1,
        data: {
          type: e.target.value,
        },
      },
    });
  };

  return (
    <div className="claim-selector">
      <h3 className="fw-normal mb-3">{t("Претензия")}</h3>
      <select
        style={{ width: "420px" }}
        defaultValue=""
        className="form-control claim-select"
        id="inputFrom"
        onChange={onChange}
      >
        <option value="" disabled>
          {t("Выберите тип претензии")}
        </option>
        {claimOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {t(option.label)}
          </option>
        ))}
      </select>
    </div>
  );
}
