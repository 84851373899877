import React from "react";
import CustomModal from "../../../components/CustomModal";
import InvoiceContent from "./InvoiceContent";

export default function ViewInvoiceModal(props) {
  return (
    <CustomModal
      id="view-invoice-modal"
      title=""
      visible={props.isOpen}
      onCancel={() => props.setIsOpen(false)}
      options={{ dialogClass: "offers", titleClass: "offers" }}
    >
      <InvoiceContent data={props.data} className="invoice-content"/>
    </CustomModal>
  );
}
