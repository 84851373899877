import { useMemo, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useOnClickOutside } from "../functions/useOnClickOutside";
import {
  composeValidators,
  minLengthConstraint,
  requiredConstraint,
} from "../functions/validator";
import countryList from "react-select-country-list";
import { amounts, fieldOfOper } from "../functions/constants";
import SimpleSelect from "./SimpleSelect";
import { createForm } from "final-form";
import { normalizeErrorFields } from "../functions/request-helper";
import { postFranchise } from "../functions/api";
import { useTranslation } from "react-i18next";

export default function JoinUsForm({ setSent }) {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const ref = useRef();
  const [phonePrefix, setPhonePrefix] = useState(
    localStorage.getItem("country") === "USA" ? "+1" : "+998"
  );
  const [showCountrySelector, setShowCountrySelector] = useState(false);

  const countries = useMemo(() => countryList().getData(), []);

  const onSubmit = async (values) => {
    let sentValues = { ...values };
    if (sentValues.country) {
      sentValues.country = sentValues.country.value;
    }
    if (sentValues.investmentAmount) {
      sentValues.investmentAmount = sentValues.investmentAmount.value;
    }
    if (sentValues.timezone) {
      sentValues.timezone = sentValues.timezone.value;
    }
    if (sentValues.business?.businessType) {
      sentValues.business.businessType = sentValues.business.businessType.value;
    }
    if (sentValues.individual?.businessType) {
      sentValues.individual.businessType =
        sentValues.individual.businessType.value;
    }
    try {
      setSubmitting(true);
      await postFranchise(sentValues);
      setSent(true);
    } catch (res) {
      if (res?.response?.status === 422) {
        const error = res.response.data;
        const fieldErrors = normalizeErrorFields(error, values);
        if (Object.keys(fieldErrors).length) {
          return fieldErrors;
        }
      }
    }
    setSubmitting(false);
  };

  const form = useMemo(() => {
    return createForm({
      onSubmit,
    });
  }, []);

  useOnClickOutside(ref, () => setShowCountrySelector(false));

  return (
    <Form
      form={form}
      subscription={{ values: true }}
      onSubmit={onSubmit}
      initialValues={{ isOwner: "false", hasExperience: "false" }}
      render={({ handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit} className="row join-us-form">
            <Field
              name="name"
              type="text"
              validate={composeValidators(requiredConstraint)}
            >
              {({ input, meta }) => (
                <div className="col-md-12 mb-4">
                  <input
                    {...input}
                    placeholder={t("Имя*")}
                    className={`${
                      (meta.error || meta.submitError) && meta.touched
                        ? `error-border`
                        : ``
                    }`}
                  />
                </div>
              )}
            </Field>
            <Field
              name="phone"
              validate={composeValidators(
                requiredConstraint,
                minLengthConstraint(phonePrefix === "+1" ? 10 : 9)
              )}
            >
              {({ input, meta }) => {
                return (
                  <div className="col-md-6 mb-4" ref={ref}>
                    <div className="input-group">
                      <button
                        onClick={() =>
                          setShowCountrySelector(!showCountrySelector)
                        }
                        className="bt btt btn-outline-secondar"
                        type="button"
                      >
                        <span className="d-flex align-items-center ">
                          <img
                            src={`/images/${
                              phonePrefix === "+1" ? "usa" : "uzb"
                            }.svg`}
                            alt=""
                            width="44"
                          />
                          {showCountrySelector ? (
                            <i className="ri-arrow-up-s-fill"></i>
                          ) : (
                            <i className="ri-arrow-down-s-fill"></i>
                          )}

                          {phonePrefix}
                        </span>
                      </button>
                      <input
                        pattern="^[+]{0,1}?[0-9]{0,12}$"
                        onFocus={() => setShowCountrySelector(false)}
                        onChange={(e) => {
                          if (e.target.validity.valid) {
                            input.onChange(e.target.value);
                          }
                        }}
                        type="number"
                        id="number"
                        style={{ lineHeight: "4" }}
                        className={`register-number-input number ${
                          phonePrefix === "+998" ? "uz" : ""
                        } ${
                          (meta.error || meta.submitError) && meta.touched
                            ? `error-border`
                            : ``
                        }`}
                      />
                      <ul
                        className={`dropdown-menu ${
                          showCountrySelector ? "show" : ""
                        }`}
                      >
                        <li>
                          <a
                            onClick={() => {
                              setPhonePrefix("+1");
                              setShowCountrySelector(false);
                            }}
                            className={`dropdown-item ${
                              phonePrefix === "+1" ? "active" : ""
                            }`}
                          >
                            <span>
                              <img src="/images/usa.svg" alt="" />
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setPhonePrefix("+998");
                              setShowCountrySelector(false);
                            }}
                            className={`dropdown-item ${
                              phonePrefix === "+998" ? "active" : ""
                            }`}
                          >
                            <span>
                              <img src="/images/uzb.svg" alt="" />
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              }}
            </Field>
            <Field
              name="email"
              validate={composeValidators(requiredConstraint)}
            >
              {({ input, meta }) => {
                return (
                  <div className="col-md-6 mb-4">
                    <input
                      {...input}
                      className={`${
                        (meta.error || meta.submitError) && meta.touched
                          ? `error-border`
                          : ``
                      }`}
                      type="text"
                      id="email"
                      placeholder={t("Электронная почта*")}
                    />
                  </div>
                );
              }}
            </Field>
            <Field name="country">
              {({ input, meta }) => {
                return (
                  <div className="col-md-6 mb-4 countries">
                    <SimpleSelect
                      {...input}
                      onChange={(value) => {
                        input.onChange(value);
                      }}
                      placeholder={t("Страна")}
                      options={countries.map((r) => {
                        return {
                          value: r.label,
                          label: r.label,
                        };
                      })}
                    />
                  </div>
                );
              }}
            </Field>
            <Field name="investmentAmount">
              {({ input, meta }) => {
                return (
                  <div className="col-md-6 mb-4 amounts">
                    <SimpleSelect
                      {...input}
                      onChange={(value) => {
                        input.onChange(value);
                      }}
                      placeholder={t("Сумма вложения")}
                      options={amounts.map((r) => {
                        return {
                          value: r.value,
                          label: r.label,
                        };
                      })}
                    />
                  </div>
                );
              }}
            </Field>
            <div className="col-md-6 mb-4 radio-buttons">
              <p>{t("Имеется ли свой бизнес")}?</p>
              <Field
                name="isOwner"
                type="radio"
                value={"true"}
                defaultValue={"false"}
              >
                {({ input, meta }) => (
                  <input
                    {...input}
                    type="radio"
                    id="yes"
                    className="m-0 custom-radio"
                  />
                )}
              </Field>
              <label htmlFor="yes">{t("Да")}</label>

              <Field
                name="isOwner"
                type="radio"
                value={"false"}
                defaultValue={"false"}
              >
                {({ input, meta }) => (
                  <input
                    {...input}
                    type="radio"
                    id="no"
                    className="custom-radio"
                    defaultChecked
                  />
                )}
              </Field>
              <label htmlFor="no">{t("Нет")}</label>
            </div>
            {values.isOwner === "true" ? (
              <div className="col-md-6 mb-4 radio-buttons"></div>
            ) : (
              <>
                <div className="col-md-6 mb-4 radio-buttons">
                  <p>{t("Имеется ли опыт управления")}?</p>
                  <Field
                    name="hasExperience"
                    type="radio"
                    value={"true"}
                    defaultValue={"false"}
                  >
                    {({ input, meta }) => (
                      <input
                        {...input}
                        type="radio"
                        id="yes2"
                        className="m-0 custom-radio"
                      />
                    )}
                  </Field>
                  <label htmlFor="yes2">{t("Да")}</label>

                  <Field
                    name="hasExperience"
                    type="radio"
                    value={"false"}
                    defaultValue={"false"}
                  >
                    {({ input, meta }) => (
                      <input
                        {...input}
                        type="radio"
                        id="no2"
                        className="custom-radio"
                      />
                    )}
                  </Field>
                  <label htmlFor="no2">{t("Нет")}</label>
                </div>
                {values.hasExperience === "true" ? (
                  <>
                    <Field
                      name="individual.businessType"
                      validate={composeValidators(requiredConstraint)}
                    >
                      {({ input, meta }) => {
                        return (
                          <div className="col-md-6 mb-4 operation">
                            <SimpleSelect
                              {...input}
                              onChange={(value) => {
                                input.onChange(value);
                              }}
                              className={`${
                                (meta.error || meta.submitError) && meta.touched
                                  ? `error-border`
                                  : ``
                              }`}
                              placeholder={t("Область опыта*")}
                              options={fieldOfOper.map((r) => {
                                return {
                                  value: r.value,
                                  label: r.label,
                                };
                              })}
                            />
                          </div>
                        );
                      }}
                    </Field>
                    <Field name="individual.experience">
                      {({ input, meta }) => {
                        return (
                          <div className="col-md-6 mb-4">
                            <input
                              {...input}
                              className={`${
                                (meta.error || meta.submitError) && meta.touched
                                  ? `error-border`
                                  : ``
                              }`}
                              type="text"
                              id="expYear"
                              placeholder={t("Годы опыта")}
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </>
                ) : null}
              </>
            )}
            {values.isOwner === "true" ? (
              <>
                <Field name="business.companyName" type="text">
                  {({ input }) => (
                    <div className="col-md-6 mb-4">
                      <input {...input} placeholder={t("Название компании")} />
                    </div>
                  )}
                </Field>
                <Field name="business.website" type="text">
                  {({ input }) => (
                    <div className="col-md-6 mb-4">
                      <input {...input} placeholder={t("Веб-сайт")} />
                    </div>
                  )}
                </Field>
                <Field name="business.experience" type="text">
                  {({ input }) => (
                    <div className="col-md-6 mb-4">
                      <input {...input} placeholder={t("Годы эксплуатации")} />
                    </div>
                  )}
                </Field>
                <Field
                  name="business.businessType"
                  validate={composeValidators(requiredConstraint)}
                >
                  {({ input }) => {
                    return (
                      <div className="col-md-6 mb-4 operation">
                        <SimpleSelect
                          {...input}
                          onChange={(value) => {
                            input.onChange(value);
                          }}
                          placeholder={t("Поле деятельности")}
                          options={fieldOfOper.map((r) => {
                            return {
                              value: r.value,
                              label: r.label,
                            };
                          })}
                        />
                      </div>
                    );
                  }}
                </Field>
                <Field name="business.description" type="textarea">
                  {({ input }) => (
                    <div className="col-md-12 mb-4">
                      <textarea {...input} placeholder={t("Опишите свой бизнес")} />
                    </div>
                  )}
                </Field>
              </>
            ) : null}
            <div className="col-12">
              <button
                type="submit"
                disabled={submitting}
                className="send-button btn"
              >
                {t("Отправить")}
              </button>
            </div>
          </form>
        );
      }}
    />
  );
}
