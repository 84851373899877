import React, { useEffect, useRef } from "react";
import CustomModal from "../../../../components/CustomModal";
import FeedbackForm from "../../../../components/FeedbackForm";
import { useTranslation } from "react-i18next";

export default function ReviewModal({ setModal, modal, stars, boxId }) {
  const { t } = useTranslation();

  const ref = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (!ref.current) {
        return;
      }
      if (!ref.current.contains(event.target)) {
        setModal(false);
      }
    };
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);
  return (
    <CustomModal
      title={"Ваш отзыв"}
      id="review-modal"
      onCancel={() => setModal(false)}
      visible={modal}
      stars={stars}
      options={{ dialogClass: "review-modal contacts" }}
    >
      <div className="modal-body " ref={ref}>
        <div className="col">
          <FeedbackForm
            finishText={
              <>
                <img src="/images/done.svg" alt="finished" />
                <h3 className="parcels-list-title">
                  {t("Спасибо за ваш комментарий!")}
                </h3>
                <button className="btn thanks" onClick={() => setModal(false)}>
                  {t("Завершить")}
                </button>
              </>
            }
            stars={stars}
            box={boxId}
          />
        </div>
      </div>
    </CustomModal>
  );
}
