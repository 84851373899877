import { Outlet, useNavigate, useLocation } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import Context from "../../functions/context";
import CreateModal from "./CreateModal";
import {
  TOGGLE_ADD_MODAL,
  TOGGLE_IMAGE_MODAL,
} from "../../functions/constants";
import AddressModal from "./components/AddressModal";
import ActiveButtonLink from "../../components/ActiveButtonLink";
import ProofImageModal from "./components/ProofImageModal";
import PaymentCreateModal from "./components/Payment/PaymentCreateModal";
import { useTranslation } from "react-i18next";

export default function ParcelPage() {
  const { t } = useTranslation();

  const {
    mainState: {
      visibleAddressModal,
      visibleImageModal,
      images,
      visibleCreatePaymentModal,
    },
    mainDispatch,
  } = useContext(Context);
  const onCloseImageModal = () => {
    mainDispatch({
      type: TOGGLE_IMAGE_MODAL,
      payload: { visible: false, images: [] },
    });
  };
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/cabinet/parcels") {
      navigate("/cabinet/parcels/orders");
    }
  }, [location.pathname]);
  
  return (
    <div className="parcles-content profile-right">
      <div className="parcles-row flex-column flex-md-row">
        <ul className="nav nav-pills y-b top " role="tablist">
          <li className="nav-item" role="presentation">
            <ActiveButtonLink className="nav-link" to="/cabinet/parcels/orders">
              {t("Посылки")}
            </ActiveButtonLink>
          </li>
          <span className="tabline" />
          <li className="nav-item" role="presentation">
            <ActiveButtonLink
              className="nav-link text-nowrap"
              to="/cabinet/parcels/shop"
            >
              {t("Помощь в покупке")}
            </ActiveButtonLink>
          </li>
          <span className="tabline" />
          <li className="nav-item" role="presentation">
            <ActiveButtonLink
              className="nav-link text-nowrap"
              to="/cabinet/parcels/tracking"
            >
              {t("Ваши покупки")}
            </ActiveButtonLink>
          </li>
        </ul>
        <div className="new-parcels-button-item">
          <button
            className="new-parcels-button btn text-nowrap"
            onClick={() =>
              mainDispatch({
                type: TOGGLE_ADD_MODAL,
                payload: { visible: true },
              })
            }
          >
            <i className="ri-add-line"></i> {t("Новый заказ")}
          </button>
        </div>
      </div>
      <div className="tab-content tab-inner">
        <div className="tab-pane fade show active">
          <Outlet />
        </div>
      </div>
      <CreateModal />
      {visibleAddressModal ? <AddressModal country={2} secondary={true} /> : ""}
      {visibleImageModal ? (
        <ProofImageModal
          visible={visibleImageModal}
          images={images}
          onClose={onCloseImageModal}
        />
      ) : (
        ""
      )}
      {visibleCreatePaymentModal ? <PaymentCreateModal /> : ""}
    </div>
  );
}
