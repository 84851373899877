import SocialNetworks from "../components/SocialNetworks";
import TrackingInfoCard from "./cabinet/components/TrackingInfoCard";

export default function SingleTrackingPage() {
    return (
       <>
        <div id="loginContent" className="container-fluid ">
            <div className="login-content" style={{gridTemplateColumns: '15% 65%'}}>
                <div className="tracking-content-left" style={{minHeight: 'calc(100vh)'}}/>
                <div className="boxes">
                    <div className="tracking-content-right content-body">
                        <TrackingInfoCard individual={true}/>
                    </div>
                </div>
            </div>
        </div>
        <SocialNetworks/>
       </>
    )
}