import React, { useContext } from "react";
import Context from "../../../functions/context";
import ClaimSelector from "./ClaimSelector";
import ClaimUserInfo from "./ClaimUserInfo";
import ClaimBoxInfo from "./ClaimBoxInfo";
import ClaimConfirm from "./ClaimConfirm";

export default function ClaimsContentStwitcher() {
  const {
    mainState: { claims },
  } = useContext(Context);

  switch (claims.step) {
    case 0:
      return <ClaimSelector />;
    case 1:
      return <ClaimUserInfo />;
    case 3:
      return <ClaimBoxInfo />;
    case 4:
      return <ClaimConfirm />;
    default:
      return <ClaimSelector />;
  }
}
