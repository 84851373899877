import React, { useContext, useEffect, useState } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import Context from "../../functions/context";
import { useTranslation } from "react-i18next";
import { TOGGLE_SUBSCRIPTION_PAYMENT_MODAL } from "../../functions/constants";
import SubsPaymentModal from "./SubsPaymentModal";
import { subscriptionPlans } from "../../functions/api";
import PaymentCreateModal from "../cabinet/components/Payment/PaymentCreateModal";
import SocialNetworks from "../../components/SocialNetworks";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";

const handleScroll = () => {
  const ratesList = document.querySelector(".rates-list");
  const rates = document.querySelector(".rates-header");

  if (ratesList && rates) {
    if (window.scrollY > 300) {
      rates.classList.add("fixed");
    } else {
      rates.classList.remove("fixed");
    }
  }
};

export default function Subscription() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const {
    mainState: { visibleSubsPaymentModal, visibleCreatePaymentModal, customer },
    mainDispatch,
  } = useContext(Context);

  const selectTariff = (tariff) => {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: "view_cart",
        ecommerce: {
          item_name: tariff.name,
          item_brand: "Subscription",
          item_category: "Subscription",
          price: tariff.price,
          quantity: 1,
          currency: "USD",
        },
      },
    });
    if (customer) {
      mainDispatch({
        type: TOGGLE_SUBSCRIPTION_PAYMENT_MODAL,
        payload: { item: tariff, visible: true },
      });
    } else {
      navigate("/registration");
    }
  };

  const getPlans = async () => {
    try {
      const { data } = await subscriptionPlans();
      setPlans(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (customer) {
      TagManager.dataLayer({
        dataLayer: {
          event: "userID",
          UID: customer.id,
        },
      });
    }
    getPlans();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [customer]);

  return (
    <>
      <SocialNetworks />
      <BreadCrumb currentTitle="Subscription" />
      <section className="subscription-page">
        <div className="container">
          <h1 className="title">
            {t("Выберите тариф и получите эксклюзивные привилегии!")}
          </h1>
          <div className="only-desktop table-responsive">
            <table>
              <tr>
                <td>
                  <p>{t("benefits")}</p>
                </td>
                {plans.map((plan, i) => (
                  <td align="center" key={plan.id}>
                    <p>{plan.name}</p>
                    {i === 0 && <span>{t("Favorite")}</span>}
                    {customer?.active_subscription &&
                      customer.active_subscription.plan.id === plan.id && (
                        <span className="active-subs">{t("active_subs")}</span>
                      )}
                  </td>
                ))}
              </tr>
              <tr>
                <td />
                {plans.map((plan, i) => (
                  <td
                    align="center"
                    key={plan.id}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <h2>${plan.price}</h2>
                    <span>/{t(i === 0 ? "мес" : "год")}</span>
                    <button
                      className="btn"
                      onClick={() => selectTariff(plan)}
                      disabled={customer?.active_subscription}
                    >
                      {t("select")}
                    </button>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Бесплатная упаковка")}</h5>
                </td>
                {plans.map((plan) => (
                  <td align="center" key={plan.id}>
                    {plan.offer_options.package_free && (
                      <img src="/images/icons/check.svg" alt="check" />
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Бесплатная доставка​")}</h5>
                </td>
                {plans.map((plan) => (
                  <td align="center" key={plan.id}>
                    <h6>{t("brooklyn")}</h6>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Приоритет в доставке")}</h5>
                  <i>{t("priority_des")}</i>
                </td>
                {plans.map((plan) => (
                  <td align="center" key={plan.id}>
                    {plan.offer_options.priority_delivery && (
                      <img src="/images/icons/check.svg" alt="check" />
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Бесплатная доставка​ документов")}</h5>
                </td>
                {plans.map((plan) => (
                  <td align="center" key={plan.id}>
                    {plan.offer_options.delivery_free && (
                      <img src="/images/icons/check.svg" alt="check" />
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Плата за онлайн покупки​")}</h5>
                </td>
                {plans.map((plan) => (
                  <td align="center" key={plan.id}>
                    <h6>{plan.offer_options.online_fee}%</h6>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Без минимального веса​")}</h5>
                </td>
                {plans.map((plan) => (
                  <td align="center" key={plan.id}>
                    <h6>{t("can_100gr")}</h6>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Стоимость замороженной доставки")}</h5>
                  <i>{t("coled_des")}</i>
                </td>
                {plans.map((plan) => (
                  <td align="center" key={plan.id}>
                    <h6>{t("standart", { rate: plan.rate.rate_kg })}</h6>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Бонус на праздничные доставки​")}</h5>
                  <i>{t("holidays_des")}</i>
                </td>
                {plans.map((plan) => (
                  <td align="center" key={plan.id}>
                    <h6>{t("extra_kg")}</h6>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Скидка FedEx​")}</h5>
                </td>
                {plans.map((plan) => (
                  <td align="center" key={plan.id}>
                    <h6>{plan.offer_options.fedex_discount_percent}%</h6>
                  </td>
                ))}
              </tr>
            </table>
          </div>
          <div className="only-mobile rates-list">
            <div className="rates-header">
              {plans.map((plan, i) => (
                <div key={i} className="rate-item">
                  {customer?.active_subscription &&
                    customer.active_subscription.plan.id === plan.id && (
                      <span className="active-subs">{t("active_subs")}</span>
                    )}
                  <h3>{plan.name}</h3>
                  <h2>
                    ${plan.price} <span>/{t(i === 0 ? "мес" : "год")}</span>
                  </h2>
                  <button
                    className="btn y-b"
                    onClick={() => selectTariff(plan)}
                    disabled={customer?.active_subscription}
                  >
                    {t("select")}
                  </button>
                </div>
              ))}
            </div>
            <h5 className="label">{t("Бесплатная упаковка")}</h5>
            <div className="benefits">
              {plans.map((plan) => (
                <div key={plan.id} className="benefit">
                  {plan.offer_options.package_free && (
                    <img src="/images/icons/check.svg" alt="check" />
                  )}
                </div>
              ))}
            </div>
            <h5 className="label">{t("Бесплатная доставка")}</h5>
            <div className="benefits">
              {plans.map((plan) => (
                <div key={plan.id} className="benefit">
                  <h6>{t("brooklyn")}</h6>
                </div>
              ))}
            </div>
            <h5 className="label">{t("Приоритет в доставке")}</h5>
            <i>{t("priority_des")}</i>
            <div className="benefits">
              {plans.map((plan) => (
                <div key={plan.id} className="benefit">
                  {plan.offer_options.priority_delivery && (
                    <img src="/images/icons/check.svg" alt="check" />
                  )}
                </div>
              ))}
            </div>
            <h5 className="label">{t("Бесплатная доставка​ документов")}</h5>
            <div className="benefits">
              {plans.map((plan) => (
                <div key={plan.id} className="benefit">
                  {plan.offer_options.delivery_free && (
                    <img src="/images/icons/check.svg" alt="check" />
                  )}
                </div>
              ))}
            </div>
            <h5 className="label">{t("Плата за онлайн покупки​")}</h5>
            <div className="benefits">
              {plans.map((plan) => (
                <div key={plan.id} className="benefit">
                  <h6>{plan.offer_options.online_fee}%</h6>
                </div>
              ))}
            </div>
            <h5 className="label">{t("Без минимального веса​")}</h5>
            <div className="benefits">
              {plans.map((plan) => (
                <div key={plan.id} className="benefit">
                  <h6>{t("can_100gr")}</h6>
                </div>
              ))}
            </div>
            <h5 className="label">{t("Стоимость замороженной доставки")}</h5>
            <i>{t("coled_des")}</i>
            <div className="benefits">
              {plans.map((plan) => (
                <div key={plan.id} className="benefit">
                  <h6>{t("standart", { rate: plan.rate.rate_kg })}</h6>
                </div>
              ))}
            </div>
            <h5 className="label">{t("Бонус на праздничные доставки")}</h5>
            <i>{t("holidays_des")}</i>
            <div className="benefits">
              {plans.map((plan) => (
                <div key={plan.id} className="benefit">
                  <h6>{t("extra_kg")}</h6>
                </div>
              ))}
            </div>
            <h5 className="label">{t("Скидка FedEx")}</h5>
            <div className="benefits">
              {plans.map((plan) => (
                <div key={plan.id} className="benefit">
                  <h6>{plan.offer_options.fedex_discount_percent}%</h6>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {visibleSubsPaymentModal && <SubsPaymentModal />}
      {visibleCreatePaymentModal ? <PaymentCreateModal /> : ""}
    </>
  );
}
