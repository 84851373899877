import React, { lazy, useEffect, useReducer } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Context from "./functions/context";
import { initialState, MainReducer } from "./reducer/main";
import { fetchShopList, loadProfile } from "./functions/api";
import { LOAD_PROFILE } from "./functions/constants";
import Intro from "./pages/Intro";
import "./App.css";
import "./App.scss";
import ParcelPage from "./pages/cabinet/ParcelPage";
import { ConfirmContextProvider } from "./functions/confirm-context-provider";
import ConfirmDialog from "./components/ConfirmDialog";
import ReferralPage from "./pages/ReferralPage";
import CalculatorModal from "./components/CalculatorModal";
import FranchizePage from "./pages/FranchizePage";
import MessagesPage from "./pages/MessagesPage";
import OauthCallbackPage from "./pages/OauthCallbackPage";
import SuspenseLoad from "./components/SuspenseLoad";
import WithMenuPage from "./pages/WithMenuPage";
import WithFooterPage from "./pages/WithFooterPage";
import SingleTrackingPage from "./pages/SingleTrackingPage";
import TermsPage from "./pages/TermsPage";
import SizeTablePage from "./pages/SizeTable";
import Yellow from "./pages/Yellow";
import Subscription from "./pages/subscription";
import WalletSubscriptionPage from "./pages/cabinet/WalletSubscriptionPage";
import InfoPage from "./pages/cabinet/fedex/InfoPage";
import ClaimNaigate from "./pages/cabinet/claims/ClaimNavigate";
import ClaimPage from "./pages/cabinet/claims/ClaimPage";
import ClaimsList from "./pages/cabinet/claims/ClaimsList";
import PaymentInvoice from "./pages/cabinet/PaymentInvoice";

const MainUzbek = lazy(() => import("./pages/uz/MainUzbek"));
const MainUsa = lazy(() => import("./pages/en/MainUsa"));
const ServicePage = lazy(() => import("./pages/ServicePage"));
const IndexPage = lazy(() => import("./pages/uz/IndexPage"));
const AboutUsPage = lazy(() => import("./pages/AboutUsPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const FaqPage = lazy(() => import("./pages/FaqPage"));
const PlanPage = lazy(() => import("./pages/PlanPage"));
const HelpShopPage = lazy(() => import("./pages/HelpShopPage"));
const ShopPage = lazy(() => import("./pages/ShopPage"));
const RegistrationPage = lazy(() => import("./pages/cabinet/RegistrationPage"));
const SimpleRegistrationPage = lazy(() =>
  import("./pages/other/SimpleRegistrationPage")
);
const LoginPage = lazy(() => import("./pages/cabinet/LoginPage"));
const ForgotPasswordPage = lazy(() =>
  import("./pages/cabinet/ForgotPasswordPage")
);
const CheckForgotPasswordPage = lazy(() =>
  import("./pages/cabinet/CheckForgotPasswordPage")
);
const ResetPasswordPage = lazy(() =>
  import("./pages/cabinet/ResetPasswordPage")
);
const CabinetMainPage = lazy(() => import("./pages/cabinet/CabinetMainPage"));
const ProfilePage = lazy(() => import("./pages/cabinet/ProfilePage"));
const ProfileEditMainPage = lazy(() =>
  import("./pages/cabinet/ProfileEditMainPage")
);
const ProfileEditPage = lazy(() => import("./pages/cabinet/ProfileEditPage"));
const ProfilePasswordPage = lazy(() =>
  import("./pages/cabinet/ProfilePasswordPage")
);
const LogoutPage = lazy(() => import("./pages/cabinet/LogoutPage"));
const ServiceCentersPage = lazy(() => import("./pages/ServiceCenters"));
const AddressPage = lazy(() => import("./pages/cabinet/AddressPage"));
const Fedex = lazy(() => import("./pages/cabinet/fedex"));
const FedexList = lazy(() => import("./pages/cabinet/fedex/FedexListPage"));
const FedexCreate = lazy(() => import("./pages/cabinet/fedex/Create"));
const FedexInvoicePage = lazy(() =>
  import("./pages/cabinet/fedex/FedexInvoicePage")
);
const TrackingPage = lazy(() => import("./pages/cabinet/TrackingPage"));
const CabinetShopPage = lazy(() => import("./pages/cabinet/ShopPage"));
const OrdersPage = lazy(() => import("./pages/cabinet/OrdersPage"));
const WalletBonusPage = lazy(() => import("./pages/cabinet/WalletBonusPage"));
const MainWalletPage = lazy(() => import("./pages/cabinet/MainWalletPage"));
const TransactionHistoryPage = lazy(() =>
  import("./pages/cabinet/TransactionHistoryPage")
);
const TransactionHistoryIncomePage = lazy(() =>
  import("./pages/cabinet/TransactionHistoryIncomePage")
);
const TransactionHistoryOutcomePage = lazy(() =>
  import("./pages/cabinet/TransactionHistoryOutcomePage")
);
const OrderInvoicePage = lazy(() => import("./pages/invoice/OrderInvoicePage"));
const PassportUploadPage = lazy(() =>
  import("./pages/other/PassportUploadPage")
);

const Claims = lazy(() => import("./pages/cabinet/claims"));

function App() {
  const [mainState, mainDispatch] = useReducer(MainReducer, initialState);
  const { modalActive, visibleCalculatorModal } = mainState;

  useEffect(() => {
    if (localStorage.getItem("cabinet_token")) {
      loadProfile(localStorage.getItem("cabinet_token")).then((data) => {
        mainDispatch({ type: LOAD_PROFILE, payload: data });
      });
    }
    if (!localStorage.getItem("shops"))
      fetchShopList(1, 200).then((items) =>
        localStorage.setItem("shops", JSON.stringify(items))
      );
  }, []);

  return (
    <div className={`app ${modalActive ? "modal-open" : ""}`}>
      <Context.Provider value={{ mainState, mainDispatch }}>
        <ConfirmContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Intro />} />
              <Route path="/yellow" element={<Yellow />} />
              <Route path="oauth-callback" element={<OauthCallbackPage />} />
              <Route
                path="registration"
                element={
                  <SuspenseLoad>
                    <RegistrationPage />
                  </SuspenseLoad>
                }
              />
              <Route
                path="become-client"
                element={
                  <SuspenseLoad>
                    <SimpleRegistrationPage />
                  </SuspenseLoad>
                }
              />
              <Route
                path="login"
                element={
                  <SuspenseLoad>
                    <LoginPage />
                  </SuspenseLoad>
                }
              />
              <Route
                path="forgot-password"
                element={
                  <SuspenseLoad>
                    <ForgotPasswordPage />
                  </SuspenseLoad>
                }
              />
              <Route
                path="check-code"
                element={
                  <SuspenseLoad>
                    <CheckForgotPasswordPage />
                  </SuspenseLoad>
                }
              />
              <Route
                path="reset-password"
                element={
                  <SuspenseLoad>
                    <ResetPasswordPage />
                  </SuspenseLoad>
                }
              />

              <Route path="invite/:referral" element={<ReferralPage />} />
              <Route
                path="invoice/:order"
                element={
                  <SuspenseLoad>
                    <OrderInvoicePage />
                  </SuspenseLoad>
                }
              />
              <Route path="trackings" element={<SingleTrackingPage />} />
              <Route path="parcel" element={<WithMenuPage />}>
                <Route
                  path="passport-upload"
                  element={
                    <SuspenseLoad>
                      <PassportUploadPage />
                    </SuspenseLoad>
                  }
                />
              </Route>
              <Route
                path="orders/payment-invoice/:id"
                element={<PaymentInvoice />}
              />
              <Route path="*" element={<WithMenuPage />}>
                <Route
                  path="cabinet"
                  element={
                    <SuspenseLoad>
                      <CabinetMainPage />
                    </SuspenseLoad>
                  }
                >
                  <Route
                    path="profile"
                    element={
                      <SuspenseLoad>
                        <ProfilePage />
                      </SuspenseLoad>
                    }
                  />
                  <Route
                    path="address"
                    element={
                      <SuspenseLoad>
                        <AddressPage />
                      </SuspenseLoad>
                    }
                  />
                  <Route
                    path="fedex"
                    element={
                      <SuspenseLoad>
                        <Fedex />
                      </SuspenseLoad>
                    }
                  >
                    <Route
                      index
                      element={
                        <SuspenseLoad>
                          <FedexList />
                        </SuspenseLoad>
                      }
                    />
                    <Route
                      path="create"
                      element={
                        <SuspenseLoad>
                          <FedexCreate />
                        </SuspenseLoad>
                      }
                    />
                    <Route
                      path=":fedex/fill-declaration"
                      element={
                        <SuspenseLoad>
                          <FedexInvoicePage />
                        </SuspenseLoad>
                      }
                    />
                    <Route
                      path="info"
                      element={
                        <SuspenseLoad>
                          <InfoPage />
                        </SuspenseLoad>
                      }
                    />
                  </Route>
                  <Route
                    path="claims"
                    element={
                      <SuspenseLoad>
                        <Claims />
                      </SuspenseLoad>
                    }
                  >
                    <Route
                      index
                      element={
                        <SuspenseLoad>
                          <ClaimNaigate />
                        </SuspenseLoad>
                      }
                    />
                    <Route
                      path="create"
                      element={
                        <SuspenseLoad>
                          <ClaimPage />
                        </SuspenseLoad>
                      }
                    />
                    <Route
                      path=":id"
                      element={
                        <SuspenseLoad>
                          <ClaimsList />
                        </SuspenseLoad>
                      }
                    />
                  </Route>
                  <Route
                    path="parcels"
                    element={
                      <SuspenseLoad>
                        <ParcelPage />
                      </SuspenseLoad>
                    }
                  >
                    <Route
                      index
                      path="orders"
                      element={
                        <SuspenseLoad>
                          <OrdersPage />
                        </SuspenseLoad>
                      }
                    />
                    <Route
                      path="tracking"
                      element={
                        <SuspenseLoad>
                          <TrackingPage />
                        </SuspenseLoad>
                      }
                    />
                    <Route
                      path="shop"
                      element={
                        <SuspenseLoad>
                          <CabinetShopPage />
                        </SuspenseLoad>
                      }
                    />
                  </Route>
                  <Route
                    path="profile/edit"
                    element={
                      <SuspenseLoad>
                        <ProfileEditMainPage />
                      </SuspenseLoad>
                    }
                  >
                    <Route
                      path="main"
                      element={
                        <SuspenseLoad>
                          <ProfileEditPage />
                        </SuspenseLoad>
                      }
                    />
                    <Route
                      path="password"
                      element={
                        <SuspenseLoad>
                          <ProfilePasswordPage />
                        </SuspenseLoad>
                      }
                    />
                  </Route>
                  <Route
                    path="wallet"
                    element={
                      <SuspenseLoad>
                        <MainWalletPage />
                      </SuspenseLoad>
                    }
                  >
                    <Route
                      path="bonus"
                      element={
                        <SuspenseLoad>
                          <WalletBonusPage />
                        </SuspenseLoad>
                      }
                    />
                    <Route
                      path="subscription"
                      element={
                        <SuspenseLoad>
                          <WalletSubscriptionPage />
                        </SuspenseLoad>
                      }
                    />
                    <Route
                      path="transaction-history"
                      element={
                        <SuspenseLoad>
                          <TransactionHistoryPage />
                        </SuspenseLoad>
                      }
                    >
                      <Route
                        path="income"
                        element={
                          <SuspenseLoad>
                            <TransactionHistoryIncomePage />
                          </SuspenseLoad>
                        }
                      />
                      <Route
                        path="outcome"
                        element={
                          <SuspenseLoad>
                            <TransactionHistoryOutcomePage />
                          </SuspenseLoad>
                        }
                      />
                    </Route>
                  </Route>
                  <Route
                    path="logout"
                    element={
                      <SuspenseLoad>
                        <LogoutPage />
                      </SuspenseLoad>
                    }
                  />
                </Route>
                <Route
                  path="for-usa"
                  element={
                    <SuspenseLoad>
                      <MainUsa />
                    </SuspenseLoad>
                  }
                />
                <Route
                  path="for-uzbek"
                  element={
                    <SuspenseLoad>
                      <MainUzbek />
                    </SuspenseLoad>
                  }
                >
                  <Route path="" element={<IndexPage />} />
                </Route>
                <Route path="*" element={<WithFooterPage />}>
                  <Route
                    path="plans"
                    element={
                      <SuspenseLoad>
                        <PlanPage />
                      </SuspenseLoad>
                    }
                  />
                  <Route
                    path="shops"
                    element={
                      <SuspenseLoad>
                        <ShopPage />
                      </SuspenseLoad>
                    }
                  />
                  <Route
                    path="subscription"
                    element={
                      <SuspenseLoad>
                        <Subscription />
                      </SuspenseLoad>
                    }
                  />
                  <Route
                    path="services"
                    element={
                      <SuspenseLoad>
                        <ServicePage />
                      </SuspenseLoad>
                    }
                  />
                  <Route
                    path="size-table"
                    element={
                      <SuspenseLoad>
                        <SizeTablePage />
                      </SuspenseLoad>
                    }
                  />
                  <Route
                    path="service-centers"
                    element={
                      <SuspenseLoad>
                        <ServiceCentersPage />
                      </SuspenseLoad>
                    }
                  />
                  <Route
                    path="about"
                    element={
                      <SuspenseLoad>
                        <AboutUsPage />
                      </SuspenseLoad>
                    }
                  />
                  <Route
                    path="contact"
                    element={
                      <SuspenseLoad>
                        <ContactPage />
                      </SuspenseLoad>
                    }
                  />
                  <Route
                    path="help"
                    element={
                      <SuspenseLoad>
                        <FaqPage />
                      </SuspenseLoad>
                    }
                  />
                  <Route
                    path="messages"
                    element={
                      <SuspenseLoad>
                        <MessagesPage />
                      </SuspenseLoad>
                    }
                  />
                  <Route
                    path="terms"
                    element={
                      <SuspenseLoad>
                        <TermsPage />
                      </SuspenseLoad>
                    }
                  />
                  <Route
                    path="franchize"
                    element={
                      <SuspenseLoad>
                        <FranchizePage />
                      </SuspenseLoad>
                    }
                  />
                  <Route
                    path="help-shop"
                    element={
                      <SuspenseLoad>
                        <HelpShopPage />
                      </SuspenseLoad>
                    }
                  />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
          <ConfirmDialog />
          {visibleCalculatorModal ? <CalculatorModal /> : ""}
        </ConfirmContextProvider>
      </Context.Provider>
      {modalActive ? <div className="modal-backdrop fade show" /> : ""}
    </div>
  );
}

export default App;
