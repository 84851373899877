import React, { useState } from "react";
import { deleteMessages } from "../functions/api";

export default function Message({ message, messages, setMessages }) {
  const [disabled, setDisabled] = useState(false);

  function deleteM(id) {
    setDisabled(true);
    deleteMessages(id)
      .then(() => {
        setMessages({
          ...messages,
          items: messages.items.filter((item) => item.id !== message.id),
        });
        setDisabled(false);
      })
      .catch((err) => {
        console.log(err);
        setDisabled(false);
      });
  }

  return (
    <div className="message-wrapper">
      <div
        className={`message ${message.author} ${message.read ? "read" : null}`}
      >
        {!message.image ? <span>{message.message}</span>: ''}
        {message.image ? <a href={message.image.url} target="_blank"><img style={{width: '200px'}} src={message.image.url} alt="message_image" /></a>: ''}
        <button
          className="btn delete"
          disabled={disabled}
          onClick={() => deleteM(message.id)}
        >
          <i
            className={`${
              disabled ? `ri-loader-line` : `ri-delete-bin-2-line`
            }`}
          />
        </button>
        <div className="time">
          <span>{message.created_at}</span>
          <i
            className={`${
              message.read ? "ri-check-double-line " : "ri-check-line"
            }`}
          ></i>
        </div>
      </div>
    </div>
  );
}
