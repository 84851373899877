import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import {
  createStripeIntents,
  fetchStripePublishingKey,
} from "../../../../functions/api";
import { loadStripe } from "@stripe/stripe-js";
import StripeCheckoutForm from "./StripeCheckoutForm";

export default function StripePayment({
  uuid,
  resource,
  resourceId,
  amount,
  successCallback,
}) {
  const [loadedStripe, setLoadStripe] = useState();
  const [intentsSecret, setIntentsSecret] = useState();
  useEffect(() => {
    fetchStripePublishingKey(uuid).then((key) => {
      setLoadStripe(loadStripe(key));
    });
  }, []);

  useEffect(() => {
    if (amount && resource && resourceId) {
      createStripeIntents(resource, resourceId, amount, uuid).then(
        (securityKey) => {
          setIntentsSecret(securityKey);
        }
      );
    }
  }, [amount, resource, resourceId]);

  return (
    <>
      {loadedStripe && intentsSecret ? (
        <Elements
          stripe={loadedStripe}
          options={{ clientSecret: intentsSecret }}
        >
          <StripeCheckoutForm
            successCallback={successCallback}
            amount={amount}
            resource={resource}
          />
        </Elements>
      ) : (
        ""
      )}
    </>
  );
}
