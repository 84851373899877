import { Field, Form } from "react-final-form";
import {
  composeValidators,
  requiredConstraint,
} from "../../../functions/validator";
import { loginUser, verifyRegistration } from "../../../functions/api";
import { normalizeErrorFields } from "../../../functions/request-helper";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Context from "../../../functions/context";
import { LOAD_PROFILE } from "../../../functions/constants";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";

export default function RegisterVerification() {
  const [submitting, setSubmitting] = useState(false);
  const { customer, password, mainDispatch } = useContext(Context);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubmit = async (values) => {
    try {
      await verifyRegistration(customer.id, values.code);
      console.log(customer.email);
      loginUser(customer.email, password).then(({ user }) => {
        TagManager.dataLayer({
          dataLayer: {
            event: "registration_doi",
          },
        });
        TagManager.dataLayer({
          dataLayer: {
            event: "userID",
            UID: user.id,
          },
        });
        mainDispatch({ type: LOAD_PROFILE, payload: user });
        navigate("/cabinet/profile");
      });
    } catch (res) {
      setSubmitting(false);
      if (res?.response?.status === 422) {
        const error = res.response.data;
        const fieldErrors = normalizeErrorFields(error, values);
        if (Object.keys(fieldErrors).length) {
          return fieldErrors;
        }
      } else if (res?.response?.status === 400) {
        return { code: "Code is invalid" };
      }
    }
  };
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className="row">
            <Field name="code" validate={composeValidators(requiredConstraint)}>
              {({ input, meta }) => {
                return (
                  <div className="col-12">
                    <input
                      {...input}
                      type="text"
                      placeholder={t("Code")}
                      pattern="^[0-9]{0,5}$"
                      className={`${
                        (meta.error || meta.submitError) && meta.touched
                          ? `error-border`
                          : ``
                      }`}
                      onChange={(e) => {
                        if (e.target.validity.valid) {
                          input.onChange(e.target.value);
                        }
                      }}
                    />
                  </div>
                );
              }}
            </Field>
            <div className="col-12 btns" style={{ marginTop: "42px" }}>
              <input
                className="login-btn btn"
                type="submit"
                value={t("Confirm")}
                disabled={submitting}
              />
            </div>
          </form>
        );
      }}
    />
  );
}
