import React from "react";

export default function FileInputButton({
  label,
  selectedFile,
  oldPhoto,
  setSelectedFile,
  wrapperClass,
}) {
  const imageRef = React.useRef();
  return (
    <>
      <div className={`form-group ${wrapperClass || ""}`}>
        <input
          type="file"
          accept="image/*"
          ref={imageRef}
          style={{ display: "none" }}
          onChange={(e) => {
            const [file] = e.target.files;
            if (file) {
              setSelectedFile(file);
            }
          }}
        />
        <div className="col-md-12">
          <h6 className="control-label">{label}</h6>
        </div>
        <div className="input-group col-sm-8">
          <div className="buttons">
            <button
              onClick={() => imageRef.current.click()}
              type="button"
              style={{ height: "auto" }}
              className="btn reciever-add-btn"
            >
              {!oldPhoto && !selectedFile ? (
                <i className="ri-camera-fill" />
              ) : (
                <img
                  style={{ width: "60px" }}
                  src={
                    selectedFile
                      ? URL.createObjectURL(selectedFile)
                      : oldPhoto.url
                  }
                />
              )}
            </button>
            {oldPhoto || selectedFile ? (
              <div className="edit-content">
                {selectedFile ? (
                  <button
                    className="delete modal-outline-btn btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedFile();
                    }}
                  >
                    <i className="ri-delete-bin-2-line" />
                  </button>
                ) : null}
                <button
                  onClick={() => imageRef.current.click()}
                  type="button"
                  className="edit btn create-btn"
                >
                  <i className="ri-edit-line" />
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
