import React from "react";
import { useTranslation } from "react-i18next";

export default function InfoPage() {
  const { t } = useTranslation();

  return (
    <div className="profile-content col fedex-info">
      <div className="row top-info mx-auto">
        <div className="col-md-5">
          <span>{t("Yellow ID")}</span>
          <h6>mmmm@gmail.com</h6>
          <span>{t("Дата")}</span>
          <h6>Фев. 05. 2024</h6>
          <span>{t("ID заказа")}</span>
          <h6>HKUNB34DFE</h6>
          <span>{t("№ Документа")}</span>
          <h6>1525482969654</h6>
        </div>
        <div className="col-md-7">
          <span>{t("Выставленный счёт")}</span>
          <h6>
            Visa .... 5486
            <br /> Ivan Ivanov
            <br /> 2000 Coney Island ave
            <br /> Brooklyn, NY 11223
            <br /> USA
          </h6>
        </div>
      </div>
      <hr />
      <div className="middle-info d-flex justify-content-between">
        <div className="d-flex align-items-start">
          <p>
            Silver {t("member")} (6 {t("month")})<br />
            {t("Обнавление")} Авг. 05. 2024
            <br />
            <span style={{ color: "#E8C305" }}>{t("Сообщить о проблеме")}</span>
          </p>
        </div>
        <h5>$52.50</h5>
      </div>
      <hr />
      <h5>
        <span>{t("TOTAL")}:</span>$52.50
      </h5>
      <hr />
      <img
        src="/images/logo.png"
        width={114}
        alt="logo"
        className="mx-auto d-block mt-5"
      />
      <h5
        className="text-center bottom-text my-4"
        style={{ fontWeight: "normal" }}
      >
        {t("Подписывайтесь на наши соц. сети, чтобы быть в кусе всех новостей")}
      </h5>
      <div className="socials d-flex mb-5   ">
        <a
          href="https://t.me/YellowIncOfficial"
          target="_blank"
          rel="noreferrer"
        >
          <i className="ri-telegram-fill" />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100078467067360&mibextid=LQQJ4d"
          target="_blank"
          rel="noreferrer"
        >
          <i className="ri-facebook-circle-fill" />
        </a>
        <a
          href="https://instagram.com/yellowincofficial?igshid=YmMyMTA2M2Y="
          target="_blank"
          rel="noreferrer"
        >
          <i className="ri-instagram-fill" />
        </a>
        <a
          href="https://twitter.com/YellowWayInc"
          target="_blank"
          rel="noreferrer"
        >
          <i className="ri-twitter-fill" />
        </a>
        <a
          href="https://www.youtube.com/@yellowway1320"
          target="_blank"
          rel="noreferrer"
        >
          <i className="ri-youtube-fill" />
        </a>
        <a
          href="https://www.linkedin.com/company/yellowwayincofficial"
          target="_blank"
          rel="noreferrer"
        >
          <i className="ri-linkedin-fill" />
        </a>
      </div>
    </div>
  );
}
