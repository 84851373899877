import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Context from "../../../functions/context";
import { SET_CLAIMS } from "../../../functions/constants";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";

export default function ClaimBoxInfo() {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const {
    mainState: { claims },
    mainDispatch,
  } = useContext(Context);

  const removeFile = (name) => {
    setFiles(files.filter((file) => file.name !== name));
  };

  const onSubmit = async (values) => {
    values = {
      ...values,
      fileImages: files,
    };
    mainDispatch({
      type: SET_CLAIMS,
      payload: {
        step: 4,
        data: {
          ...claims.data,
          ...values,
        },
      },
    });
  };

  return (
    <div className="claim-user-info">
      <h3 className="fw-normal">{t("Информация о коробке")}</h3>
      <Form
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        initialValuesEqual={() => true}
        initialValues={{ products: [{}] }}
        render={({
          handleSubmit,
          values,
          form: {
            mutators: { push },
          },
        }) => {
          return (
            <form onSubmit={handleSubmit} className="row">
              <div className="products">
                <div className="fields labels">
                  <div className="field name">
                    <span className="mb-2 d-block">
                      {t("Наименование товара")}
                    </span>
                  </div>
                  <div className="field number">
                    <span className="mb-2 d-block">
                      {t("Номер отслеживания")} *
                    </span>
                  </div>
                  <div className="field quantity">
                    <span className="mb-2 d-block">{t("Qty")} *</span>
                  </div>
                  <div className="field price">
                    <span className="mb-2 d-block">{t("Цена товара")} *</span>
                  </div>
                  <button disabled className="fade" />
                </div>
                <FieldArray name="products">
                  {({ fields }) => {
                    return fields.map((fieldName, index) => (
                      <div className="fields mb-3" key={`products_${index}`}>
                        <div className="field name">
                          <Field name={`${fieldName}.name`} type="text">
                            {({ input }) => (
                              <input
                                className="form-control"
                                placeholder={t("Наименование товара")}
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="field number">
                          <Field name={`products[0].tracking`} type="text">
                            {({ input }) => (
                              <input
                                className="form-control"
                                placeholder={t("Номер")}
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="field quantity">
                          <Field name={`${fieldName}.count`} type="text">
                            {({ input }) => (
                              <input
                                className="form-control"
                                placeholder={t("Qty")}
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="field price">
                          <Field name={`${fieldName}.price`} type="text">
                            {({ input }) => (
                              <input
                                className="form-control"
                                placeholder="$00.00"
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="add-btn">
                          <button
                            type="button"
                            className="btn y-b mb-0 w-100"
                            onClick={() => push("products", {})}
                          >
                            {t("+ Добавить")}
                          </button>
                        </div>
                      </div>
                    ));
                  }}
                </FieldArray>
              </div>
              <div className="d-flex align-items-center mt-4 mb-5 col-12">
                <h6 className="fw-normal total">{t("Общяя сумма")}:</h6>
                <h5 className="fw-bold">
                  ${" "}
                  {values.products.reduce((a, b) => {
                    return (a +=
                      b.price && b.count ? +b.price * +b.count : 0);
                  }, 0)}
                </h5>
              </div>
              <div className="comment col-md-7">
                <span className="mb-2 d-block">{t("Сообщение")}</span>
                <Field name="text" type="text">
                  {({ input }) => (
                    <textarea
                      className="form-control"
                      placeholder={t("Сообщение")}
                      {...input}
                    />
                  )}
                </Field>
              </div>
              <div className="pictures-list col-md-2">
                <span className="mb-2 d-block fade">*</span>
                {files.map((file) => (
                  <div className="d-flex align-items-center justify-content-between">
                    <span key={file.name}>{file.name}</span>
                    <button
                      className="btn min-w-auto"
                      onClick={(e) => {
                        e.preventDefault();
                        removeFile(file.name);
                      }}
                    >
                      <i className="ri-close-line" />
                    </button>
                  </div>
                ))}
              </div>
              <div className="pictures col-md-3">
                <span className="mb-2 d-block">
                  {t("Загрузите документ *")}
                </span>
                <label htmlFor="pictures">
                  <i className="ri-camera-fill"></i>
                  <input
                    type="file"
                    name="images"
                    multiple
                    id="pictures"
                    className="form-control"
                    onChange={(e) => {
                      setFiles([...e.target.files]);
                    }}
                  />
                </label>
              </div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="submit-btn y-b btn w-auto"
              >
                {t("Далее")}
              </button>
            </form>
          );
        }}
      />
    </div>
  );
}
