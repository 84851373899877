import CustomModal from "../../../components/CustomModal";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Context from "../../../functions/context";
import { Form, Field } from "react-final-form";
import Autocomplete from "react-google-autocomplete";
import { createForm } from "final-form";
import {
  addOrUpdateModel,
  normalizeErrorFields,
} from "../../../functions/request-helper";
import {
  composeValidators,
  minLengthConstraint,
  requiredConstraint,
} from "../../../functions/validator";
import {
  createAddress,
  loadDistricts,
  loadRegions,
  updateAddress,
} from "../../../functions/api";
import SimpleSelect from "../../../components/SimpleSelect";
import {
  LOAD_ADDRESS,
  TOGGLE_ADDRESS_MODAL,
} from "../../../functions/constants";
import FileInputButton from "../../../components/FileInputButton";
import { useOnClickOutside } from "../../../functions/useOnClickOutside";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { handlePlaceSelected } from "../../../functions/address";

const formatToForm = (values) => {
  return {
    ...values,
    firstName: values.first_name,
    lastName: values.last_name,
    region: values.region?.id,
    district: values.district?.id,
    firstAddress: values.first_address,
    zipCode: values.zip_code,
    country: values.country?.id,
    phones: values.phones.map((phone) => {
      if (phone.indexOf("+998") === 0) {
        return phone.replace("+998", "");
      }
      if (phone.indexOf("+1") === 0) {
        return phone.replace("+1", "");
      }
      return phone;
    }),
  };
};

export default function AddressModal({ country, secondary }) {
  const {
    mainState: { visibleAddressModal, currentAddress, addresses: items },
    mainDispatch,
  } = useContext(Context);
  const [submitting, setSubmitting] = useState(false);
  const [regions, setRegions] = useState([]);
  const [showCountrySelector, setShowCountrySelector] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [active, setActive] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [passportBackFile, setPassportBackFile] = useState(null);
  const [phonePrefix, setPhonePrefix] = useState(country === 2 ? "+998" : "+1");
  const [phonePrefix2, setPhonePrefix2] = useState(
    country === 2 ? "+998" : "+1"
  );
  const modalEl = useRef();
  const ref = useRef();
  const zipCodeRef = React.createRef();
  const { t } = useTranslation();

  useOnClickOutside(ref, () => setShowCountrySelector(false));

  const onSubmit = async (values) => {
    setSubmitting(true);
    try {
      let sendValues = { ...values };
      if (sendValues.region?.value) {
        sendValues.region = sendValues.region.value;
      }
      if (sendValues.district?.value) {
        sendValues.district = sendValues.district.value;
      }
      let address;
      if (selectedFile) {
        sendValues["passportFile"] = selectedFile;
      }
      if (passportBackFile) {
        sendValues["passportBackFile"] = passportBackFile;
      }
      if (!sendValues?.id) {
        sendValues.country = country;
        address = await createAddress(sendValues);
      } else {
        address = await updateAddress(sendValues.id, sendValues);
      }
      mainDispatch({
        type: LOAD_ADDRESS,
        payload: { country, items: addOrUpdateModel(items[country], address) },
      });
      mainDispatch({ type: TOGGLE_ADDRESS_MODAL, payload: false });
    } catch (res) {
      if (res?.response?.status === 422) {
        const error = res.response.data;
        const fieldErrors = normalizeErrorFields(error, values);
        if (Object.keys(fieldErrors).length) {
          return fieldErrors;
        }
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    loadRegions({ country }, 1, 1000).then(({ items }) => {
      setRegions(items);
    });
    return () => {
      form.reset({});
    };
  }, []);

  useEffect(() => {
    if (currentAddress?.region?.id) {
      handleRegionsChange(currentAddress.region.id);
    }
    return () => {
      setSelectedFile(null);
      setPassportBackFile(null);
    };
  }, [currentAddress?.id]);

  const handleRegionsChange = (value) => {
    if (value) {
      loadDistricts({ region: value }, 1, 1000).then(({ items }) => {
        setDistricts(items);
      });
    }
  };
  const form = useMemo(() => {
    return createForm({
      onSubmit,
      initialValues: currentAddress?.id ? formatToForm(currentAddress) : {},
    });
  }, [visibleAddressModal, currentAddress?.id]);

  useEffect(() => {
    const handler = (event) => {
      if (!modalEl.current) {
        return;
      }
      if (!modalEl.current.contains(event.target)) {
        mainDispatch({ type: TOGGLE_ADDRESS_MODAL, payload: false });
      }
    };
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  return (
    <CustomModal
      visible={visibleAddressModal}
      onCancel={() =>
        mainDispatch({ type: TOGGLE_ADDRESS_MODAL, payload: false })
      }
      title={
        country === 2 ? t("Добавить получателя") : t("Добавить отправителя")
      }
      size="lg"
      id={"add-address-modal"}
      secondary={secondary || false}
    >
      <div className="modal-body" id="parcelsModal">
        <Form
          form={form}
          initialValues={currentAddress?.id ? formatToForm(currentAddress) : {}}
          subscription={{ values: true, initialValues: true }}
          onSubmit={onSubmit}
          render={({ handleSubmit, values }) => {
            return (
              <form
                onSubmit={handleSubmit}
                className="row g-2 parcels-form"
                ref={modalEl}
              >
                <div className="col-12 col-md-6">
                  <div className="col-12 input-margin-b">
                    <h6>{t("Данные")}</h6>
                  </div>
                  <Field
                    name="firstName"
                    validate={composeValidators(requiredConstraint)}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="col-12 input-margin-b">
                          <input
                            {...input}
                            className={`form-control ${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            }`}
                            placeholder={t("Имя*")}
                          />
                        </div>
                      );
                    }}
                  </Field>
                  <Field
                    name="lastName"
                    validate={composeValidators(requiredConstraint)}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="col-12 input-margin-b">
                          <input
                            {...input}
                            className={`form-control ${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            }`}
                            placeholder={t("Фамилия*")}
                          />
                        </div>
                      );
                    }}
                  </Field>
                  <Field
                    name="passport"
                    validate={composeValidators(requiredConstraint)}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="col-12 input-margin-b">
                          <input
                            {...input}
                            placeholder={t("Серия и номер паспорта*")}
                            className={`form-control ${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            }`}
                            pattern={
                              country === 2
                                ? "^[a-zA-Z]{0,2}[0-9]{0,7}$"
                                : undefined
                            }
                            onChange={(e) => {
                              if (e.target.validity.valid && country === 2) {
                                input.onChange(
                                  e.target.value.toLocaleUpperCase()
                                );
                              } else if (country === 1) {
                                input.onChange(e.target.value);
                              }
                            }}
                          />
                        </div>
                      );
                    }}
                  </Field>
                  <Field
                    name="phones.0"
                    validate={composeValidators(
                      requiredConstraint,
                      minLengthConstraint(phonePrefix === "+1" ? 10 : 9)
                    )}
                  >
                    {({ input, meta }) => {
                      return (
                        <div
                          className="col-12 input-margin-b position-relative"
                          ref={ref}
                        >
                          <div className="position-relative">
                            <button
                              onClick={() => {
                                setActive(1);
                                setShowCountrySelector(!showCountrySelector);
                              }}
                              className="bt btt btn-outline-secondar"
                              type="button"
                            >
                              <span className="d-flex align-items-center ">
                                <img
                                  src={`/images/${
                                    phonePrefix === "+1" ? "usa" : "uzb"
                                  }.svg`}
                                  alt=""
                                  width="44"
                                />
                                {showCountrySelector ? (
                                  <i className="ri-arrow-up-s-fill"></i>
                                ) : (
                                  <i className="ri-arrow-down-s-fill"></i>
                                )}

                                {phonePrefix}
                              </span>
                            </button>
                            <input
                              {...input}
                              pattern={`^[0-9]{0,${
                                phonePrefix === "+1" ? 10 : 9
                              }}$`}
                              onFocus={() => setShowCountrySelector(false)}
                              onChange={(e) => {
                                if (e.target.validity.valid) {
                                  input.onChange(e.target.value);
                                } else {
                                  e.target.value = input.value;
                                }
                              }}
                              // type="number"
                              id="number"
                              className={`form-control ${
                                phonePrefix === "+998" ? "uz" : "en"
                              } ${
                                (meta.error || meta.submitError) && meta.touched
                                  ? `error-border`
                                  : ``
                              }`}
                            />
                            <ul
                              className={`dropdown-menu ${
                                showCountrySelector && active === 1
                                  ? "show"
                                  : ""
                              }`}
                            >
                              <li>
                                <a
                                  onClick={() => {
                                    setPhonePrefix("+1");
                                    setShowCountrySelector(false);
                                  }}
                                  className={`dropdown-item ${
                                    phonePrefix === "+1" ? "active" : ""
                                  }`}
                                >
                                  <span>
                                    <img src="/images/usa.svg" alt="" />
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={() => {
                                    setPhonePrefix("+998");
                                    setShowCountrySelector(false);
                                  }}
                                  className={`dropdown-item ${
                                    phonePrefix === "+998" ? "active" : ""
                                  }`}
                                >
                                  <span>
                                    <img src="/images/uzb.svg" alt="" />
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      );
                    }}
                  </Field>
                  <Field
                    name="phones.1"
                    validate={composeValidators(
                      minLengthConstraint(phonePrefix2 === "+1" ? 10 : 9)
                    )}
                  >
                    {({ input, meta }) => {
                      return (
                        <div
                          className="col-12 input-margin-b position-relative"
                          ref={ref}
                        >
                          <div className="position-relative">
                            <button
                              onClick={() => {
                                setActive(2);
                                setShowCountrySelector(!showCountrySelector);
                              }}
                              className="bt btt btn-outline-secondar"
                              type="button"
                            >
                              <span className="d-flex align-items-center ">
                                <img
                                  src={`/images/${
                                    phonePrefix2 === "+1" ? "usa" : "uzb"
                                  }.svg`}
                                  alt=""
                                  width="44"
                                />
                                {showCountrySelector ? (
                                  <i className="ri-arrow-up-s-fill"></i>
                                ) : (
                                  <i className="ri-arrow-down-s-fill"></i>
                                )}

                                {phonePrefix2}
                              </span>
                            </button>
                            <input
                              {...input}
                              pattern={`^[0-9]{0,${
                                phonePrefix2 === "+1" ? 10 : 9
                              }}$`}
                              onFocus={() => setShowCountrySelector(false)}
                              onChange={(e) => {
                                if (e.target.validity.valid) {
                                  input.onChange(e.target.value);
                                } else {
                                  e.target.value = input.value;
                                }
                              }}
                              // type="number"
                              id="number"
                              className={`form-control ${
                                phonePrefix2 === "+998" ? "uz" : "en"
                              }`}
                            />
                            <ul
                              className={`dropdown-menu ${
                                showCountrySelector && active === 2
                                  ? "show"
                                  : ""
                              }`}
                            >
                              <li>
                                <a
                                  onClick={() => {
                                    setPhonePrefix2("+1");
                                    setShowCountrySelector(false);
                                  }}
                                  className={`dropdown-item ${
                                    phonePrefix2 === "+1" ? "active" : ""
                                  }`}
                                >
                                  <span>
                                    <img src="/images/usa.svg" alt="" />
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={() => {
                                    setPhonePrefix2("+998");
                                    setShowCountrySelector(false);
                                  }}
                                  className={`dropdown-item ${
                                    phonePrefix2 === "+998" ? "active" : ""
                                  }`}
                                >
                                  <span>
                                    <img src="/images/uzb.svg" alt="" />
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      );
                    }}
                  </Field>
                  <Field name="email">
                    {({ input, meta }) => {
                      return (
                        <div className="col-12 input-margin-b">
                          <input
                            {...input}
                            className={`form-control ${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            }`}
                            placeholder={t("E-mail")}
                          />
                        </div>
                      );
                    }}
                  </Field>
                  <FileInputButton
                    wrapperClass="col-md-12 mt-3"
                    label={t("Passport front")}
                    oldPhoto={currentAddress?.passport_photo}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <div className="col-12 input-margin-b">
                    <h6>{t("Адрес")}</h6>
                  </div>
                  <Field
                    name="firstAddress"
                    validate={composeValidators(requiredConstraint)}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="col-12 input-margin-b">
                          <Autocomplete
                            {...input}
                            apiKey="AIzaSyAeU5i04w2RLitwcCQI10zwojhLlWswWTc"
                            onPlaceSelected={async (place) => {
                              input.onChange(place.formatted_address);
                              const {
                                city,
                                zipCode,
                                region,
                                latitude,
                                longitude,
                              } = await handlePlaceSelected(
                                place,
                                values,
                                regions
                              );
                              form.batch(() => {
                                if (!isEmpty(city)) {
                                  form.change("city", city);
                                }
                                if (!isEmpty(zipCode)) {
                                  form.change("zipCode", zipCode);
                                }
                                if (!isEmpty(region)) {
                                  form.change("region", region);
                                }
                                form.change("latitude", latitude);
                                form.change("longitude", longitude);
                              });
                            }}
                            placeholder="First address"
                            className="form-control"
                            options={{
                              types: ["address"],
                              componentRestrictions: { country: "us" },
                            }}
                          />
                        </div>
                      );
                    }}
                  </Field>
                  <Field name="house">
                    {({ input, meta }) => {
                      return (
                        <div className="col-12 input-margin-b">
                          <input
                            {...input}
                            placeholder={t("Апартамент")}
                            className={`form-control ${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            }`}
                          />
                        </div>
                      );
                    }}
                  </Field>
                  <Field
                    name="region"
                    validate={composeValidators(requiredConstraint)}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="col-12 input-margin-b">
                          <SimpleSelect
                            {...input}
                            onChange={(option) => {
                              handleRegionsChange(option?.value);
                              input.onChange(option);
                            }}
                            className={`${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            }`}
                            placeholder={t("Регион*")}
                            options={regions.map((r) => {
                              return {
                                value: r.id,
                                label: r.name,
                              };
                            })}
                          />
                        </div>
                      );
                    }}
                  </Field>
                  {country === 2 ? (
                    <Field name="district">
                      {({ input, meta }) => {
                        return (
                          <div className="col-12 input-margin-b">
                            <SimpleSelect
                              {...input}
                              placeholder={t("Город / Район*")}
                              options={districts.map((r) => {
                                return {
                                  value: r.id,
                                  label: r.name,
                                };
                              })}
                            />
                          </div>
                        );
                      }}
                    </Field>
                  ) : (
                    <Field name="city">
                      {({ input, meta }) => {
                        return (
                          <div className="col-12 input-margin-b">
                            <input
                              {...input}
                              placeholder={t("Город / Район*")}
                              className={`form-control ${
                                (meta.error || meta.submitError) && meta.touched
                                  ? `error-border`
                                  : ``
                              }`}
                            />
                          </div>
                        );
                      }}
                    </Field>
                  )}
                  <Field name="apartment">
                    {({ input, meta }) => {
                      return (
                        <div className="col-12 input-margin-b">
                          <input
                            {...input}
                            placeholder={t("Квартира/офис")}
                            className={`form-control ${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            }`}
                          />
                        </div>
                      );
                    }}
                  </Field>
                  <Field name="zipCode">
                    {({ input, meta }) => {
                      return (
                        <div className="col-12 input-margin-b">
                          <Autocomplete
                            {...input}
                            ref={zipCodeRef}
                            apiKey={process.env.YOUR_GOOGLE_MAPS_API_KEY}
                            onPlaceSelected={async (place) => {
                              const {
                                city,
                                zipCode,
                                region,
                                latitude,
                                longitude,
                              } = await handlePlaceSelected(
                                place,
                                values,
                                regions
                              );
                              input.onChange(zipCode);
                              form.batch(() => {
                                form.change(
                                  "firstAddress",
                                  place.formatted_address
                                );
                                if (!isEmpty(city)) {
                                  form.change("city", city);
                                }
                                if (!isEmpty(region)) {
                                  form.change("region", region);
                                }
                                form.change("latitude", latitude);
                                form.change("longitude", longitude);
                              });
                            }}
                            placeholder={t("ZIP-код")}
                            className="form-control"
                            options={{
                              types: ["address"],
                              componentRestrictions: { country: "us" },
                            }}
                          />
                          {/* <input
                            {...input}
                            className={`form-control ${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            }`}
                            placeholder={t("ZIP-код")}
                          /> */}
                        </div>
                      );
                    }}
                  </Field>
                  <FileInputButton
                    wrapperClass="col-md-12 mt-3"
                    label={t("Passport back")}
                    oldPhoto={currentAddress?.passport_back_photo}
                    selectedFile={passportBackFile}
                    setSelectedFile={setPassportBackFile}
                  />
                </div>
                <div className="col-12 text-end mt-4 ">
                  <button
                    type="button"
                    onClick={() =>
                      mainDispatch({
                        type: TOGGLE_ADDRESS_MODAL,
                        payload: false,
                      })
                    }
                    className="btn reciever-cancel-btn"
                  >
                    {t("Отменить")}
                  </button>
                  <button
                    disabled={submitting}
                    type="submit"
                    className="btn reciever-add-btn"
                  >
                    {currentAddress ? t("Обновить") : t("Добавить")}
                  </button>
                </div>
              </form>
            );
          }}
        />
      </div>
    </CustomModal>
  );
}
