import {loadAddress, loadAddressLimits, loadDistricts} from "../functions/api";
import {LOAD_ADDRESS} from "../functions/constants";

export function loadAddressAction(country, mainDispatch) {
    return loadAddress({country})
        .then(({items: data}) => {
            let districtIds = data.reduce((r, d) => {
                if (d.district) {
                    r.push(d.district);
                }
                return r;
            }, []);
            let addressIds = data.map(a => a.id);
            if (districtIds.length) {
                Promise
                    .all([
                        loadDistricts({id: districtIds}, 1, 100),
                        loadAddressLimits(addressIds)
                    ])
                    .then(([{items: districts}, limits]) => {
                        data.map(d => {
                            if (d.district) {
                                const dist = districts.find(ds => ds.id === d.district);
                                if (dist) {
                                    d.district = dist;
                                }
                            }
                            const limit = limits.find(l => l.id === d.id);
                            if (limit) {
                                d.limit = limit;
                            }
                            return d;
                        });
                        mainDispatch({
                            type: LOAD_ADDRESS,
                            payload: {country, items: data}
                        })
                    })

            } else {
                loadAddressLimits(addressIds)
                    .then((limits) => {
                        data.map(d => {
                            const limit = limits.find(l => l.id === d.id);
                            if (limit) {
                                d.limit = limit;
                            }
                            return d;
                        });

                        mainDispatch({
                            type: LOAD_ADDRESS,
                            payload: {country, items: data}
                        })
                    })

            }
        })
}