import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function SearchResult({ result }) {
  const pathname = window.location.pathname;
  const { t } = useTranslation();
  return (
    <div className="search-result">
      {!result ? (
        <div className="no-data">{t("По вашему запросу ничего не найдено")}</div>
      ) : (
        <ul className="result-list">
          {/* <div className="text text-center">Result</div> */}
          {result.menus && result.menus.length ? (
            <>
              {pathname === "/shops" ? <li className="title">{t("Меню")}</li> : null}
              {result.menus.map((item, i) => (
                <li className="result-item" key={i}>
                  <Link to={item.link}>{item.name}</Link>
                </li>
              ))}
            </>
          ) : null}
          {result.categories && result.categories.length ? (
            <>
              {pathname === "/shops" ? (
                <li className="title">{t("Категории")}</li>
              ) : null}
              {result.categories.map((item, i) => (
                <li className="result-item" key={i}>
                  <Link to="/shops">{t(`shop.${item}`)}</Link>
                </li>
              ))}
            </>
          ) : null}
          {result.shops && result.shops.length ? (
            <>
              {pathname === "/shops" ? (
                <li className="title">{t("Магазины")}</li>
              ) : null}
              {result.shops.map((item, i) => (
                <li className="result-item" key={i}>
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <div className="shop-image">
                      <img src={item.logo.url} alt={item.title} />
                    </div>
                    <div className="texts">
                      <p className="shop-name">{item.title}</p>
                      <p className="shop-description">{item.body}</p>
                    </div>
                  </a>
                </li>
              ))}
            </>
          ) : null}
        </ul>
      )}
    </div>
  );
}
