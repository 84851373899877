import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { getUnreadMessages } from "../functions/api";
import { UNREAD_COUNT } from "../functions/constants";
import Context from "../functions/context";
import { useTranslation } from "react-i18next";
import ActiveLink from "./ActiveLink";

export default function HeaderMessageLogin({
  pageRender = () => {},
  activeRoute,
}) {
  const {
    mainDispatch,
    mainState: { unreadCount },
  } = useContext(Context);
  const { t } = useTranslation();
  useEffect(() => {
    getUnreadMessages().then((res) => {
      mainDispatch({ type: UNREAD_COUNT, payload: res });
    });
  }, []);

  return (
    <>
      <li className="nav-item">
        <ActiveLink
          className={`nav-link btn-profile-icon pr-0 ${
            activeRoute === "messages" ? "active" : ""
          }`}
          to="/messages"
          onClick={() => pageRender("messages")}
        >
          {unreadCount ? <span className="unreads">{unreadCount}</span> : null}
          <i className="ri-mail-line only-desktop" style={{ fontSize: 21 }}></i>
          <span className="only-mobile"> {t("Messages")}</span>
        </ActiveLink>
      </li>
      <li className="nav-item">
        <ActiveLink
          className={`nav-link btn-profile-icon pr-0 ${
            activeRoute === "profile" ? "active" : ""
          }`}
          to="/cabinet/profile"
          onClick={() => pageRender("profile")}
        >
          <i className="ri-user-line only-desktop" style={{ fontSize: 21 }} />
          <span className="only-mobile"> {t("menu.profile")}</span>
        </ActiveLink>
      </li>
    </>
  );
}
