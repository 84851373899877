import { date, months } from "./constants";

export function ucFirst(val) {
  return `${val[0]?.toUpperCase() || ""}${val
    .substring(1, val.length)
    .toLocaleLowerCase()}`;
}

export const lbToKg = (weight) =>
  Math.round((weight * 0.453592 + Number.EPSILON) * 100) / 100;

export const kgToLb = (weight) =>
  Math.round((weight / 0.453592 + Number.EPSILON) * 100) / 100;

export function weightList(weight) {
  return `${weight} lb / ${lbToKg(weight)} kg`;
}

export function getDiscountPercent(originalPrice, price) {
  return Math.round(price / originalPrice) * 100;
}

export const addressFullLabel = (address, includeName = true) => {
  return includeName
    ? address.label
    : address.label.replaceAll(
        `${address.first_name}${
          address.last_name ? " " + address.last_name : ""
        }`,
        ""
      );
};

const tasks = {};

export function debounce(callBack, nameTask, duration = 1000) {
  // get task
  let task = tasks[nameTask];

  // remove timeout if it has
  task && clearTimeout(task);

  // set new task or update
  tasks[nameTask] = setTimeout(callBack, duration);
}

export const priceFormatter = (price) => {
  return String(price).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

export function totop() {
  window.scroll({
    top: 0,
    behavior: "smooth",
  });
}

export const getFormattedDate = (month) => {
  let month2 = months.find((m) => m.value === month?.split("/")[0]);
  month2 = { ...month2, year: new Date().getFullYear() };
  return `${month2.label} ${month2.value}, ${month2.year}`;
};

export const currentMonth =
  date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;

export const currentDay =
  date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

export const downloadFileFromBlob = (data, fileName, extension) => {
  if (!extension) {
    extension = data.type.split("/")[1];
  }
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.${extension}`); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export const shopEventRecords = (records, price = "price") => {
  return records.map((record) => {
    const item_brand = `https://${new URL(record.link).hostname}`;
    const total = +record[price] * +record.quantity * 0.1;
    return {
      item_name: record.name,
      item_brand: item_brand,
      item_category: "help in purchasing",
      price: total.toFixed(2),
      currency: "USD",
    };
  });
};

export const trackingEventRecords = (records) => {
  return records.map((record) => {
    return {
      item_name: record.productName,
      item_category: "parcel",
      price: record.unitPrice,
      quantity: record.quantity,
      currency: "USD",
    };
  });
};

export const trackingEventOrder = (orders) => {
  return orders.map((order) => {
    return {
      item_name: weightList(order.total_weight),
      item_category: "parcel",
      price: order.total_price,
      quantity: 1,
      currency: "USD",
    };
  });
};

function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
}

export function jsonToFormData(data) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}
