import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HelpAccordionItem from "../components/HelpAccordionItem";
import JoinUsForm from "../components/JoinUsForm";
import SocialNetworks from "../components/SocialNetworks";
import { franchizeContents } from "../functions/constants";
import { totop } from "../functions/helper";
import Context from "../functions/context";
import TagManager from "react-gtm-module";

export default function FranchizePage() {
  const [active, setActive] = useState(1);
  const { t } = useTranslation();
  const [sent, setSent] = useState(false);
  const {
    mainState: { customer },
  } = useContext(Context);

  useEffect(() => {
    setTimeout(() => {
      setSent(false);
    }, 5000);
  }, [sent]);

  useEffect(() => {
    if (customer) {
      TagManager.dataLayer({
        dataLayer: {
          event: "userID",
          UID: customer.id,
        },
      });
    }
    totop();
  }, []);
  return (
    <>
      <SocialNetworks />
      <div className="franchize-header">
        <div className="container">
          <div className="franchize-nav">
            <a
              className={`fran-nav-item ${active === 1 ? `active` : ``}`}
              href="#why-choose-us"
              onClick={() => setActive(1)}
            >
              {t("Why Choose Us")}
            </a>
            <a
              className={`fran-nav-item ${active === 2 ? `active` : ``}`}
              href="#how-yellow-operates"
              onClick={() => setActive(2)}
            >
              {t("How Yellow Operates")}
            </a>
            <a
              className={`fran-nav-item ${active === 3 ? `active` : ``}`}
              href="#franchise-set-up"
              onClick={() => setActive(3)}
            >
              {t("Franchise Set-Up")}
            </a>
            <a
              className={`fran-nav-item ${active === 4 ? `active` : ``}`}
              href="#business-opportunity"
              onClick={() => setActive(4)}
            >
              {t("Business Opportunity")}
            </a>
            {/* <a
              className={`fran-nav-item ${active === 5 ? `active` : ``}`}
              href="#what-we-ensure"
              onClick={() => setActive(5)}
            >
              What We Ensure
            </a> */}
            <a
              className={`fran-nav-item ${active === 6 ? `active` : ``}`}
              href="#join-us"
              onClick={() => setActive(6)}
            >
              {t("Join Us")}
            </a>
          </div>
        </div>
      </div>
      <section className="franchize-section">
        <div className="container franchize-top-content">
          <div className="title-top">
            <span className="line" />
            <h2 className="section-title">
              {t("Own the most profitable & successful Franchise")}
            </h2>
            <span className="line" />
          </div>
          <p>
            {t(
              "Help your customers buy from international online shops that don't deliver to their country"
            )}
          </p>
          <div className="video" data-video-id="30LEYoVoFpM">
            <div className="video-layer">
              <div className="video-placeholder" />
            </div>
            <div className="video-preview">
              <img
                src="/images/general-images/how daes it works 876 424.jpg"
                alt="video"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="why-choose-us" className="franchize-section">
        <div className="container">
          <div className="why-choose-us">
            <div className="title-top">
              <span className="line" />
              <h1 className="section-title">{t("WHY CHOOSE US")}</h1>
              <span className="line" />
            </div>
            <div className="why-choose-us-boxes row">
              {franchizeContents.whyChooseUs.map((item, index) => (
                <div className="col-md-4 col-12" key={index}>
                  <div className="box text-center">
                    <div className="box-image">
                      <img src={item.image} alt={t(item.text)} />
                    </div>
                    <h2 className="box-title">{t(item.title)}</h2>
                    <p className="box-text">{t(item.text)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section id="how-yellow-operates" className="franchize-section">
        <div className="container">
          <div className="how-yellow-operates">
            <div className="title-top">
              <div className="line"></div>
              <h2 className="section-title">{t("How Yellow Operates")}</h2>
              <div className="line"></div>
            </div>
            <div className="how-yellow-operates-boxes">
              {franchizeContents.howYellowOperates.map((item, index) => (
                <div className="box" key={index}>
                  <h2 className="box-title">{t(item.title)}</h2>
                  <img src={item.image} alt={t(item.text)} />
                  <p className="box-text">{t(item.text)}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section id="business-opportunity" className="franchize-section">
        <div className="container">
          <div className="business-opportunity">
            <div className="title-top">
              <div className="line"></div>
              <h2 className="section-title">{t("Business Opportunity")}</h2>
              <div className="line"></div>
            </div>
            <div className="business-opportunity-boxes">
              {franchizeContents.businessOpportunity.map((item, index) => (
                <div className="box" key={index}>
                  <h1 className="box-sum">{t(item.sum)}</h1>
                  <h4 className="box-title">{t(item.title)}</h4>
                  <p className="box-text">{t(item.text)}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section id="franchise-set-up" className="franchize-section">
        <div className="container">
          <div className="franchise-set-up">
            <div className="title-top">
              <div className="line"></div>
              <h2 className="section-title">{t("Franchise Set-Up")}</h2>
              <div className="line"></div>
            </div>
            <div className="franchise-set-up-boxes row">
              {franchizeContents.franchiseSetUp.map((item, index) => (
                <div className="col-md-2" key={index}>
                  <div className="box">
                    <div className="box-image">
                      <img src={item.image} alt={t(item.text)} />
                    </div>
                    <h2 className="box-title">
                      {t("Step")} <span>{index + 1}</span>
                    </h2>
                    <p className="box-text">{t(item.text)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section id="faq-join-us" className="franchize-section">
        <div className="container">
          <div className="faq-join-us">
            <div className="title-top">
              <div className="line"></div>
              <h2 className="section-title">{t("FAQ")}</h2>
              <div className="line"></div>
            </div>
            {franchizeContents.franchiseFaq.map((item, index) => (
              <HelpAccordionItem
                title={t(item.title)}
                index={index + 1}
                key={index}
              >
                {t(item.text)}
              </HelpAccordionItem>
            ))}
          </div>
        </div>
      </section>
      <section id="featured-in" className="franchize-section">
        <div className="container">
          <div className="featured-in">
            <div className="title-top">
              <div className="line"></div>
              <h2 className="section-title">{t("Возможности")}</h2>
              <div className="line"></div>
            </div>
            {/* <div className="featured-in-boxes row">
              {franchizeContents.featuredIn.map((item, index) => (
                <div className="col-md-3">
                  <div className="box" key={index}>
                    <img src={item.image} alt="img" />
                  </div>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </section>
      <section id="meet-us" className="franchize-section">
        <div className="container">
          <div className="meet-us">
            <div className="meet-us-content">
              <h2 className="section-title">{t("Meet Us")}</h2>
              <p className="meet-us-text">
                {t(
                  "Yellow — посылочная компания, которая предоставляет возможности франчайзинга для лиц, заинтересованных в собственном посылочном бизнесе. Как франчайзер, вы можете извлечь выгоду из известного бренда, бизнес-модели и сети поддержки Yellow. Франчайзеры также могут пройти обучение, маркетинговую помощь и доступ к технологиям и системам Yellow. Партнерство с Yellow может быть отличной возможностью для людей, желающих начать посылочной бизнес при поддержке известного бренда."
                )}
              </p>
              <a
                className={`btn join-us`}
                href="#join-us"
                onClick={() => setActive(6)}
              >
                {t("Join Us")}
              </a>
            </div>
            <div className="meet-us-image">
              <img
                src="/images/general-images/about us 876 424.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="join-us" className="franchize-section">
        <div className="container">
          <div className="join-us">
            <div className="title-top">
              <div className="line"></div>
              <h2 className="section-title">{t("Join Us")}</h2>
              <div className="line"></div>
            </div>
            <div className="join-us-content row">
              <div className="left-side col-md-6">
                {sent ? (
                  <div className="thanks">
                    <h3>{t("Спасибо что выбрали Yellow")}</h3>
                    <p>{t("Мы свяжемся с вами в течении трех рабочих дней")}</p>
                  </div>
                ) : (
                  <JoinUsForm setSent={setSent} />
                )}
              </div>
              <div className="right-side col-md-5">
                <img
                  src={`${
                    sent ? "/images/franchise.svg" : "/images/joinushi.svg"
                  }`}
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
