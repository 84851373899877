import { postFeedbackWebsite } from "../functions/api";
import { normalizeErrorFields } from "../functions/request-helper";
import { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import {
  composeValidators,
  maxLengthConstraint,
  minLengthConstraint,
  requiredConstraint,
} from "../functions/validator";
import { useOnClickOutside } from "../functions/useOnClickOutside";
import { useTranslation } from "react-i18next";

export default function FeedbackForm({
  finishText = "",
  stars = null,
  box = null,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [sent, setSent] = useState(false);

  const [initialValues] = useState({});
  const { t } = useTranslation();

  const ref = useRef();
  const [phonePrefix, setPhonePrefix] = useState(
    localStorage.getItem("country") === "USA" ? "+1" : "+998"
  );
  const [showCountrySelector, setShowCountrySelector] = useState(false);

  useOnClickOutside(ref, () => setShowCountrySelector(false));

  const onSubmit = async (values) => {
    setSubmitting(true);
    try {
      if (stars) {
        values.rating = stars;
      }
      if (box) {
        values.box = box;
      }
      await postFeedbackWebsite(values);
      setSubmitting(false);
      setSent(true);
    } catch (res) {
      setSubmitting(false);
      if (res?.response?.status === 422) {
        const error = res.response.data;
        const fieldErrors = normalizeErrorFields(error, values);
        if (Object.keys(fieldErrors).length) {
          return fieldErrors;
        }
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSent(false);
    }, 5000);
  }, [sent]);

  return (
    <div className="contacts-form">
      {!sent ? (
        <>
          <h3 className="contacts-title">{t("Give us some feedback.")}</h3>
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} className="row g-3">
                  <div className="col-md-6">
                    <Field
                      name="firstName"
                      validate={composeValidators(requiredConstraint)}
                    >
                      {({ input, meta }) => {
                        return (
                          <div className="col-12 mb-2">
                            <input
                              {...input}
                              type="text"
                              placeholder={`${t("Name")}*`}
                              className={`form-control ${
                                (meta.error || meta.submitError) && meta.touched
                                  ? `error-border`
                                  : ``
                              }`}
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <div className="col-md-6">
                    <Field
                      name="lastName"
                      validate={composeValidators(requiredConstraint)}
                    >
                      {({ input, meta }) => {
                        return (
                          <div className="col-12 mb-2">
                            <input
                              {...input}
                              type="text"
                              placeholder={`${t("Surname")}*`}
                              className={`form-control ${
                                (meta.error || meta.submitError) && meta.touched
                                  ? `error-border`
                                  : ``
                              }`}
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <div className="col-md-6">
                    <Field
                      name="phone"
                      validate={composeValidators(
                        requiredConstraint,
                        minLengthConstraint(phonePrefix === "+1" ? 10 : 9)
                      )}
                    >
                      {({ input, meta }) => {
                        return (
                          <div className="col-12 mb-2" ref={ref}>
                            <div className="input-group">
                              <button
                                onClick={() =>
                                  setShowCountrySelector(!showCountrySelector)
                                }
                                className="bt btt btn-outline-secondar mt-0"
                                type="button"
                              >
                                <span className="d-flex align-items-center ">
                                  <img
                                    src={`/images/${
                                      phonePrefix === "+1" ? "usa" : "uzb"
                                    }.svg`}
                                    alt=""
                                    width="44"
                                  />
                                  {showCountrySelector ? (
                                    <i className="ri-arrow-up-s-fill"></i>
                                  ) : (
                                    <i className="ri-arrow-down-s-fill"></i>
                                  )}

                                  {phonePrefix}
                                </span>
                              </button>
                              <input
                                pattern="^[+]{0,1}?[0-9]{0,12}$"
                                onFocus={() => setShowCountrySelector(false)}
                                onChange={(e) => {
                                  if (e.target.validity.valid) {
                                    input.onChange(e.target.value);
                                  }
                                }}
                                type="number"
                                id="number"
                                className={`form-control ${
                                  phonePrefix == "+998" ? "uz" : "en"
                                } ${
                                  (meta.error || meta.submitError) &&
                                  meta.touched
                                    ? `error-border`
                                    : ``
                                }`}
                              />
                              <ul
                                className={`dropdown-menu ${
                                  showCountrySelector ? "show" : ""
                                }`}
                              >
                                <li>
                                  <a
                                    onClick={() => {
                                      setPhonePrefix("+1");
                                      setShowCountrySelector(false);
                                    }}
                                    className={`dropdown-item ${
                                      phonePrefix === "+1" ? "active" : ""
                                    }`}
                                  >
                                    <span>
                                      <img src="/images/usa.svg" alt="" />
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    onClick={() => {
                                      setPhonePrefix("+998");
                                      setShowCountrySelector(false);
                                    }}
                                    className={`dropdown-item ${
                                      phonePrefix === "+998" ? "active" : ""
                                    }`}
                                  >
                                    <span>
                                      <img src="/images/uzb.svg" alt="" />
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <div className="col-md-6">
                    <Field
                      name="email"
                      validate={composeValidators(requiredConstraint)}
                    >
                      {({ input, meta }) => {
                        return (
                          <div className="col-12 mb-2">
                            <input
                              {...input}
                              type="text"
                              placeholder={`${t("Email")}*`}
                              className={`form-control ${
                                (meta.error || meta.submitError) && meta.touched
                                  ? `error-border`
                                  : ``
                              }`}
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <div className="col-12">
                    <Field
                      name="message"
                      validate={composeValidators(
                        requiredConstraint,
                        maxLengthConstraint(500)
                      )}
                    >
                      {({ input, meta }) => {
                        return (
                          <div className="col-12 mb-4">
                            <textarea
                              {...input}
                              name="message"
                              placeholder={`${t("Text message")}*`}
                              id="message"
                              cols="30"
                              rows="10"
                              className={`${
                                (meta.error || meta.submitError) && meta.touched
                                  ? `error-border`
                                  : ``
                              }`}
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <div className="col-12 m-0">
                    <button
                      type="submit"
                      disabled={submitting}
                      className="contacts-form-btn btn"
                    >
                      {t("Send")}
                    </button>
                  </div>
                </form>
              );
            }}
          />
        </>
      ) : (
        <div className="finished">
          {finishText ? (
            finishText
          ) : (
            <h3>{t("Спасибо что связались с нами, мы ценим ваш отзыв")}!</h3>
          )}
        </div>
      )}
    </div>
  );
}
