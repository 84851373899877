import {useState} from "react";

export default function HelpAccordionItem({title, index, children}) {
    const [status, setStatus] = useState(false);
    return (
        <div className="accordion-item">
            <h2 className="accordion-header y-b overflow-hidden" id={`panelsStayOpen-heading${index}`}>
                <button
                    className={`accordion-button ${!status ? 'collapsed' : ''}`}
                    type="button"
                    onClick={() => setStatus(!status)}
                >
                    {title}
                </button>
            </h2>
            <div
                id={`panelsStayOpen-collapse${index}`}
                className={`accordion-collapse collapse ${status ? 'show' : ''}`}
            >
                <div className="accordion-body">
                    {children}
                </div>
            </div>
        </div>
    );
}