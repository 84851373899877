import {isEmpty} from "lodash";
import { loadRegions } from "./api";

export const handlePlaceSelected = async (place, values, regionList) => {
    if (regionList.length === 0) {
        const regionResult = await loadRegions({}, 1, 1000);
        regionList = regionResult.items;
    }
    const address = place.address_components || [];
    let city = values?.city || '';
    let country = values?.country || '';
    let region = values?.region || '';
    let zipCode = values?.zipCode || '';

    for (let i = 0; i < address.length; i++) {
        if (isEmpty(city)) {
            if (address[i].types.includes('sublocality_level_1')) {
                city = address[i]['long_name'];
            } else if (address[i].types.includes('locality')) {
                city = address[i]['long_name'];
            }
        }
        if (isEmpty(region)) {
            if (address[i].types.includes("administrative_area_level_1")) {
                const resourceRegion = regionList.find(reg => reg.name.toLowerCase() === (address[i]['long_name'] || '').toLowerCase());
                if (resourceRegion) {
                    region = {value: resourceRegion.id, label: resourceRegion.name};
                }
            }
        }
        if (isEmpty(country)) {
            if (address[i].types.includes('country')) {
                country = address[i]['short_name'];
            }
        }
        if (isEmpty(zipCode)) {
            if (address[i].types.includes('postal_code')) {
                zipCode = address[i]['short_name'];
            }
        }
    }
    const latitude = place.geometry?.location?.lat() || null;
    const longitude = place.geometry?.location?.lng() || null;

    return {country, region, city, zipCode, latitude, longitude};
};

export const addressFullLabel = (address, includeName = true) => {
    return includeName ? address.label : address?.label?.replaceAll(`${address?.first_name}${address?.last_name ? (' ' + address?.last_name) : ''}`, '')
};
