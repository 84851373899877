import CalculatorForm from './CalculatorForm'

export const computeValues = (rates, values) => {
  const rate = (rates || []).find(
    (r) =>
      parseInt(r.sender_country) === parseInt(values.from) &&
      parseInt(r.recevier_country) === parseInt(values.to),
  )
  if (rate) {
    let dimensionWeight = 0
    if (values.dimension && values.dimension.length > 0) {
      const multiplier = 0.453592
      if (values.measure === 'sm') {
        dimensionWeight =
          ((values.length || 0) * (values.width || 0) * (values.height || 0)) /
          5000
        if (values.unit === 'lb') {
          dimensionWeight = dimensionWeight / multiplier
        }
      } else {
        dimensionWeight =
          ((values.length || 0) * (values.width || 0) * (values.height || 0)) /
          139
        if (values.unit === 'kg') {
          dimensionWeight = dimensionWeight * multiplier
        }
      }
    }
    let weight = values.weight || 0
    weight = weight >= dimensionWeight ? weight : dimensionWeight
    if (values.unit === 'lb') {
      return (weight * rate.rate).toFixed(2)
    } else {
      return (weight * rate.rate_kg).toFixed(2)
    }
  }
}

export default function Calculator() {
  return (
    <div className="container">
      <CalculatorForm />
    </div>
  )
}
