import { useEffect, useRef, useState } from "react";
import CustomModal from "../../../components/CustomModal";
import ReactImageVideoLightbox from "react-image-video-lightbox";
export default function ProofImageModal({ title, onClose, visible, images }) {
  const [show, setShow] = useState();
  const ref = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (!ref.current) {
        return;
      }
      if (!ref.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);
  return (
    <CustomModal
      title={title}
      visible={visible}
      onCancel={onClose}
      options={{ dialogClass: "photo" }}
    >
      <div className="modal-body photo" ref={ref}>
        <ul>
          {images.map((image, index) => (
            <li key={image.id} className="position-relative">
              <button className="btn btn-show" onClick={() => setShow(image)}>
                <i className="ri-eye-fill"></i>
              </button>
              <img src={image.url} width="323" height="229" />
              {show && (
                <ReactImageVideoLightbox
                  data={[
                    {
                      url: show.url,
                      type: "photo",
                      altTag: "some image",
                    },
                  ]}
                  startIndex={0}
                  showResourceCount={false}
                  onCloseCallback={() => setShow()}
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    </CustomModal>
  );
}
