import React, {useEffect, useState} from "react";

export default function BankCardInput({input, ...props}) {
    const [card, setCard] = useState();
    const domRef = React.useRef();

    const handleChange = React.useCallback(() => {
        if (domRef.current) {
            const cardValue = domRef.current.value
                .replace(/\D/g, "")
                .match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
            if (cardValue) {
                domRef.current.value = !cardValue[2]
                    ? cardValue[1]
                    : `${cardValue[1]} ${cardValue[2]}${`${
                        cardValue[3] ? ` ${cardValue[3]}` : ""
                    }`}${`${cardValue[4] ? ` ${cardValue[4]}` : ""}`}`;
                const numbers = domRef.current.value.replace(/(\D)/g, "");
                setCard(numbers);
                input.onChange(numbers);
            }
        }
    }, [domRef]);

    useEffect(() => {
        handleChange();
    }, [card, handleChange]);

    return (
        <>
            <input
                type="text"
                ref={domRef}
                onChange={handleChange}
                {...props}
            />
        </>
    );
}