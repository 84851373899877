export const ShopCategories = [
  "Leisure",
  "Sport",
  "Home and garden",
  "Online Mall",
  "Health & Beauty",
  "Jewelry and gifts",
  "Office supplies",
  "Products for animals",
  "Automobiles and accessories",
  "Electronics & Accessories",
  "Baby clothes and toys",
  "Clothes shoes and accessories",
  "Other",
];

export const LOAD_PROFILE = "load_profile";
export const UNSET_PROFILE = "unset_profile";
export const SHOWED_MODAL = "SHOWED_MODAL";


export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";

export const LOAD_TRACKING = "LOAD_TRACKING";
export const SET_CURRENT_ITEM = "SET_CURRENT_ITEM";
export const TOGGLE_ORDER_INVOICE = "TOGGLE_ORDER_INVOICE";
export const ADD_UPDATE_TRACKING = "ADD_UPDATE_TRACKING";
export const REMOVE_TRACKING = "REMOVE_TRACKING";

export const LOAD_SHOP = "LOAD_SHOP";
export const ADD_UPDATE_SHOP = "ADD_UPDATE_SHOP";
export const UPDATE_SHOP_RECORD = "UPDATE_SHOP_RECORD";
export const REMOVE_SHOP_RECORD = "REMOVE_SHOP_RECORD";
export const TOGGLE_SERVICE_MODAL = "TOGGLE_SERVICE_MODAL";
export const TOGGLE_IMAGE_MODAL = "TOGGLE_IMAGE_MODAL";
export const SET_SELECTED_ADDRESS = "SET_SELECTED_ADDRESS";

export const SET_CLAIMS = "SET_CLAIMS";

export const LOAD_ORDERS = "LOAD_BOXES";
export const ADD_UPDATE_ORDER = "ADD_UPDATE_ORDER";

export const TOGGLE_ADD_MODAL = "TOGGLE_ADD_MODAL";
export const TOGGLE_ADDRESS_MODAL = "TOGGLE_ADDRESS_MODAL";
export const UPDATE_STEP_MODAL = "UPDATE_STEP_MODAL";
export const SET_SECTION = "SET_SECTION";
export const LOAD_ADDRESS = "LOAD_ADDRESS";
export const SET_CURRENT_ADDRESS = "SET_CURRENT_ADDRESS";

export const TOGGLE_CALCULATOR_MODAL = "TOGGLE_CALCULATOR_MODAL";
export const SET_TRACKING_ORDER = "SET_TRACKING_ORDER";
export const UNSET_TRACKING_ORDER = "UNSET_TRACKING_ORDER";
export const SET_LOCALE = "SET_LOCALE";

export const CARD_ITEM_MODAL = "CARD_ITEM_MODAL";
export const PAYMENT_MODAL = "PAYMENT_MODAL";
export const TOGGLE_SUBSCRIPTION_PAYMENT_MODAL = "TOGGLE_SUBSCRIPTION_PAYMENT_MODAL";
export const ACCOUNT_MODAL = "ACCOUNT_MODAL";
export const BALLS_MODAL = "BALLS_MODAL";

export const SET_RESET_CODE = "SET_RESET_CODE";
export const TOGGLE_PAYMENT_MODAL = "TOGGLE_PAYMENT_MODAL";
export const UPDATE_PAYMENT_STEP = "UPDATE_PAYMENT_STEP";
export const UPDATE_PAYMENT_GATEWAY = "UPDATE_PAYMENT_GATEWAY";
export const TOGGLE_FEDEX_EMAIL_MODAL = "TOGGLE_FEDEX_EMAIL_MODAL";

export const UNREAD_COUNT = "UNREAD_COUNT";

export const boxesStatuses = [
  "pending",
  "sent",
  "in_customs",
  "arrived",
  "delivered",
];

export const shopOrderStatuses = ["process", "bought", "verified", "accepted"];

export const SERVICES = {
  hold_storage: 3,
  dont_pack: 0,
  save_packing: 3,
  take_shot_out: 2,
  take_shop_in: 5,
  get_back: 3,
  change_receiver: 0,
  check_order: 3,
  consolidation: 20,
  repacking: 6,
  deconsolidation: 5,
};
export const ShopRatings = ["5", "4", "3", "2", "1"];

export const whyYellowContent = {
  boxes: [
    {
      image: "/images/icons/boxes.svg",
      title: "32 548+",
      text: "Parcels delivered",
    },
    {
      image: "/images/icons/users.svg",
      title: "15 334+",
      text: "Registered clients",
    },
    {
      image: "/images/icons/subscribes.svg",
      title: "10+",
      text: "Provided services",
    },
  ],
  benefites: [
    {
      image: "/images/icons/service_center.svg",
      text: "Guaranteed shipments delivery. Quickly and without damage!",
    },
    {
      image: "/images/icons/warehouse.svg",
      text: "Process your shipment right in your home. And you will get rid of unnecessary hassle.",
    },
    {
      image: "/images/icons/home.svg",
      text: "24/7 Customer service center",
    },
    {
      image: "/images/icons/scales.svg",
      text: "Free delivery service to your home address (through the cities of Tashkent and Samarkand)",
    },
    {
      image: "/images/icons/percent.svg",
      text: "FREE OF CHARGE donation delivery from USA to orphanages and needy people in Uzbekistan",
    },
    {
      image: "/images/icons/user.svg",
      text: "PICK UP is available from over 40 states in the USA.",
    },
    {
      image: "/images/icons/user.svg",
      text: "Earn bonuses by inviting friends and use them to pay for the services.",
    },
    {
      image: "/images/icons/user.svg",
      text: "Tax exempt when you receive your purchase. Offices in Delaware, a tax-free zone!",
    },
  ],
};

export const franchizeContents = {
  whyChooseUs: [
    {
      image: "/images/icons/money.svg",
      title: "$70.000",
      text: "Оценка электронной коммерции",
    },
    {
      image: "/images/icons/cart.svg",
      title: "Раскрутка",
      text: "Доступ к нашим маркетологам",
    },
    {
      image: "/images/icons/circle-stats.svg",
      title: "30%",
      text: "Доля рынка Yellow",
    },
  ],
  howYellowOperates: [
    {
      image: "/images/icons/chelector.svg",
      title: "01",
      text: "Customers register on Yellow’s website",
    },
    {
      image: "/images/icons/location.svg",
      title: "02",
      text: "Yellow provides shipping addresses in 7 countries",
    },
    {
      image: "/images/icons/user-location.svg",
      title: "03",
      text: "Customer uses them as shipping addresses when shopping online",
    },
    {
      image: "/images/icons/receive.svg",
      title: "04",
      text: "Yellow receives and processes the purchase",
    },
    {
      image: "/images/icons/delivery.svg",
      title: "05",
      text: "Yellow receives and processes the purchase2",
    },
  ],
  businessOpportunity: [
    {
      sum: "$10,000",
      title: "Плата за франшизу с Yellow",
      text: "Право на использование бренда Yellow, обучение персонала, клиентская служба, доступ к нашим маркетологам",
    },
    {
      sum: "$20,000",
      title: "Инвестиции",
      text: "Инвестиции до получения прибыли",
    },
  ],
  franchiseSetUp: [
    {
      image: "/images/icons/document.svg",
      text: "Submit Franchise Application",
    },
    {
      image: "/images/icons/message.svg",
      text: "Franchisee Interviews",
    },
    {
      image: "/images/icons/user-search.svg",
      text: "Research and Business Plan",
    },
    {
      image: "/images/icons/table.svg",
      text: "Agreements and Deposit",
    },
    {
      image: "/images/icons/stats.svg",
      text: "Discovery Day and Trainings",
    },
    {
      image: "/images/icons/notification.svg",
      text: "Launch",
    },
  ],
  featuredIn: [
    {
      image: "/images/franchise-direct.svg",
    },
    {
      image: "/images/top-franchise.svg",
    },
    {
      image: "/images/accurate-franchise.svg",
    },
    {
      image: "/images/accurate-franchise.svg",
    },
  ],
  franchiseFaq: [
    {
      title: "Submit Franchise Application",
      text: "Для отправки заявки на франшизу заполните форму ниже, наши операторы свяжутся с вами в короткие сроки чтобы координировать действия для дальнейших шагов.",
    },
    {
      title: "Franchisee Interviews",
      text: "Интервью может длиться от 40-45 минут  ее можно провести как и через телефон так и при встрече",
    },
    {
      title: "Research and Business Plan",
      text: "Рассматривается бизнес план с франчайзи и проводится исследование рынка для наилучшей интеграции",
    },
    {
      title: "Agreements and Deposit",
      text: "Составляется договор с фрэнчайзером для дальнейшего сотрудничество",
    },
    {
      title: "Discovery Day and Trainings",
      text: "После подписание договора начинается подпор и обучение персонала с интегрированием нашей клиентской службой и с нашей командой маркетологов",
    },
  ],
};

export const socialNetworks = [
  {
    name: "Telegram",
    image: "/images/icons/telegram.svg",
    link: "https://t.me/YellowUSA",
  },
  {
    name: "Instagram",
    image: "/images/icons/instagram.svg",
    link: "https://instagram.com/yellowincofficial?igshid=YmMyMTA2M2Y=",
  },
  {
    name: "Facebook",
    image: "/images/icons/facebook.svg",
    link: "https://www.facebook.com/profile.php?id=100078467067360&mibextid=LQQJ4d",
  },
  {
    name: "Twitter",
    image: "/images/icons/twitter.svg",
    link: "https://twitter.com/YellowWayInc",
  },
  {
    name: "Phone",
    image: "/images/icons/phone.svg",
    link: "tel:+19292440000",
  },
];

export const ourTeam = [
  {
    image: "/images/general-images/jamshid_m.png",
    name: "JAMSHID MUHITDINOV",
    job: "CEO/CO-OWNER",
  },
  {
    image: "/images/general-images/aziz_m.png",
    name: "AZIZ MIRZAEV",
    job: "CAO/CO-OWNER",
  },
  {
    image: "/images/general-images/david_k.png",
    name: "DAVID KUDRATOV",
    job: "CFO/BUSINESS MANAGER",
  },
];

export const calcInterval = [0, 5, 10, 15, 20, 25, 30, 35, 40];

export const paymentResourceEnum = {
  order: 1,
  shop: 2,
  subscription: 3
};

export const menus = {
  uz: [
    { link: "/plans", name: "Tariflar" },
    { link: "/services", name: "Xizmatlar" },
    { link: "/shops", name: "Do'konlar" },
    { link: "/about", name: "Biz haqimizda" },
    { link: "/contact", name: "Kontaktlar" },
    { link: "/help", name: "Yordam" },
    { link: "/franchize", name: "Franchayzing" },
  ],
  en: [
    { link: "/plans", name: "Rates" },
    { link: "/services", name: "Shoppirsrvices" },
    { link: "/shops", name: "Shopping" },
    { link: "/about", name: "About us" },
    { link: "/contact", name: "Contacts" },
    { link: "/help", name: "FAQ" },
    { link: "/franchize", name: "Franchise" },
  ],
  ru: [
    { link: "/plans", name: "Тарифы" },
    { link: "/services", name: "Услуги" },
    { link: "/shops", name: "Магазины" },
    { link: "/about", name: "О нас" },
    { link: "/contact", name: "Контакты" },
    { link: "/help", name: "Помощь" },
    { link: "/franchize", name: "Франчайзинг" },
  ],
};

export const reviewStars = [1, 2, 3, 4, 5];

export const amounts = [
  { value: "меньше $50,000", label: "меньше $50,000" },
  { value: "$60,000", label: "$60,000" },
  { value: "$70,000", label: "$70,000" },
  { value: "$80,000", label: "$80,000" },
  { value: "$90,000", label: "$90,000" },
  { value: "$100,000", label: "$100,000" },
  { value: "$125,000", label: "$125,000" },
  { value: "$150,000", label: "$150,000" },
  { value: "$200,000", label: "$200,000" },
  { value: "$300,000", label: "$300,000" },
  { value: "$400,000", label: "$400,000" },
  { value: "больше $500,000", label: "больше $500,000" },
];

export const fieldOfOper = [
  { value: "Цифровой маркетинг", label: "Цифровой маркетинг" },
  { value: "Логистика", label: "Логистика" },
  {
    value: "Складирование и комплектация заказов",
    label: "Складирование и комплектация заказов",
  },
  { value: "Развитие бизнеса", label: "Развитие бизнеса" },
  { value: "Продажа", label: "Продажа" },
  { value: "Другой вид деятельности", label: "Другой вид деятельности" },
];

export const timezones = [
  {
    value: "(GMT -12:00) Eniwetok, Kwajalein",
    label: "(GMT -12:00) Eniwetok, Kwajalein",
  },
  {
    value: "(GMT -11:00) Midway Island, Samoa",
    label: "(GMT -11:00) Midway Island, Samoa",
  },
  { value: "(GMT -10:00) Hawaii", label: "(GMT -10:00) Hawaii" },
  { value: "(GMT -9:00) Alaska", label: "(GMT -9:00) Alaska" },
  {
    value: "(GMT -8:00) Pacific Time (US & Canada)",
    label: "(GMT -8:00) Pacific Time (US & Canada)",
  },
  {
    value: "(GMT -7:00) Mountain Time (US & Canada)",
    label: "(GMT -7:00) Mountain Time (US & Canada)",
  },
  {
    value: "(GMT -6:00) Central Time (US & Canada), Mexico City",
    label: "(GMT -6:00) Central Time (US & Canada), Mexico City",
  },
  {
    value: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima",
    label: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima",
  },
  {
    value: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
    label: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
  },
  {
    value: "(GMT -3:00) Brazil, Buenos Aires, Georgetown",
    label: "(GMT -3:00) Brazil, Buenos Aires, Georgetown",
  },
  { value: "(GMT -2:00) Mid-Atlantic", label: "(GMT -2:00) Mid-Atlantic" },
  {
    value: "(GMT -1:00 hour) Azores, Cape Verde Islands",
    label: "(GMT -1:00 hour) Azores, Cape Verde Islands",
  },
  {
    value: "(GMT) Western Europe Time, London, Lisbon, Casablanca",
    label: "(GMT) Western Europe Time, London, Lisbon, Casablanca",
  },
  {
    value: "(GMT +1:00 hour) Brussels, Copenhagen, Madrid, Paris",
    label: "(GMT +1:00 hour) Brussels, Copenhagen, Madrid, Paris",
  },
  {
    value: "(GMT +2:00) Kaliningrad, South Africa",
    label: "(GMT +2:00) Kaliningrad, South Africa",
  },
  {
    value: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg",
    label: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg",
  },
  { value: "(GMT +3:30) Tehran", label: "(GMT +3:30) Tehran" },
  {
    value: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi",
    label: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi",
  },
  { value: "(GMT +4:30) Kabul", label: "(GMT +4:30) Kabul" },
  {
    value: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
    label: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
  },
  {
    value: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi",
    label: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi",
  },
  { value: "(GMT +5:45) Kathmandu", label: "(GMT +5:45) Kathmandu" },
  {
    value: "(GMT +6:00) Almaty, Dhaka, Colombo",
    label: "(GMT +6:00) Almaty, Dhaka, Colombo",
  },
  {
    value: "(GMT +7:00) Bangkok, Hanoi, Jakarta",
    label: "(GMT +7:00) Bangkok, Hanoi, Jakarta",
  },
  {
    value: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong",
    label: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong",
  },
  {
    value: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
    label: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
  },
  {
    value: "(GMT +9:30) Adelaide, Darwin",
    label: "(GMT +9:30) Adelaide, Darwin",
  },
  {
    value: "(GMT +10:00) Eastern Australia, Guam, Vladivostok",
    label: "(GMT +10:00) Eastern Australia, Guam, Vladivostok",
  },
  {
    value: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
    label: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
  },
  {
    value: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
    label: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
  },
];

export const officeAddresses = [
  {
    link: "https://goo.gl/maps/PUG9u2xbcTAd5mcX8",
    name: "1954 Coney Island Ave, Brooklyn, NY 11223 (Head office)",
  },
  {
    link: "https://goo.gl/maps/5PgvozkoTatjy5cw7",
    name: "Tashkent office",
  },
  {
    link: "https://t.me/c/1728328104/3677",
    name: "Samarkand office",
  },
  {
    link: "https://goo.gl/maps/zvdJEmBJbHUTGwzq9",
    name: "606 Interchange blvd, Newark DE 19711",
  },
  {
    link: "https://maps.app.goo.gl/VYmMX9zuX8BDSqGWA",
    name: "7577 Central Parke Blvd, Ohio",
  },
  {
    link: "https://goo.gl/maps/3gvBqQJR3qDgPeKv7",
    name: "3921 18th Ave, Brooklyn, NY 1230",
  },
  {
    link: "https://goo.gl/maps/ih6oqLc7GSf6E13e9",
    name: "223 Kings Hwy, Brooklyn, NY 11223",
  },
  {
    link: "https://goo.gl/maps/o6d72BfhvmtQkSpR8",
    name: "510 Cheltenham way, Carapolis, PA 15108",
  },
  {
    link: "https://www.google.com/maps/place/842+Red+Lion+Rd+%2317,+Philadelphia,+PA+19115,+%D0%A1%D0%A8%D0%90/@40.1030393,-75.0302103,17z/data=!4m13!1m7!3m6!1s0x89c6b24cc647a23f:0x6497f74317749303!2zODQyIFJlZCBMaW9uIFJkICMxNywgUGhpbGFkZWxwaGlhLCBQQSAxOTExNSwg0KHQqNCQ!3b1!8m2!3d40.1030393!4d-75.0302103!3m4!1s0x89c6b24cc647a23f:0x6497f74317749303!8m2!3d40.1030393!4d-75.0302103",
    name: "842 Red Lion rd Unit 17 Philadelphia, PA 19115",
  },
  {
    link: "https://www.google.com/maps/place/4139+Vine+St+%23107,+Kissimmee,+FL+34746,+USA/@28.3049043,-81.448692,17z/data=!4m5!3m4!1s0x88dd83e7464958eb:0x47e6640cc9064635!8m2!3d28.3049043!4d-81.448692",
    name: "4139 W Vine Street unit, 107 Kissimmee FL 34741",
  },
  {
    link: "https://www.google.com/maps/place/2615+Coney+Island+Ave,+Brooklyn,+NY+11223,+USA/@40.5929721,-73.9603956,16z/data=!4m5!3m4!1s0x89c2445fefe89a5b:0xe94f70c26abaad2f!8m2!3d40.5929721!4d-73.9603956",
    name: "2615 Coney Island Ave, Brooklyn, NY 11223",
  },
  {
    link: "https://www.google.com/maps/place/2428+Nolensville+Pk,+Nashville,+TN+37211,+USA/@36.1193788,-86.748865,16z/data=!4m5!3m4!1s0x886465f0f9de8ca9:0x14d3328c2f00b693!8m2!3d36.1193788!4d-86.748865",
    name: "2428 Nolensville Pike, Nashville, TN 37211",
  },
  {
    link: "https://www.google.com/maps/place/101-15+Queens+Blvd,+Flushing,+NY+11375,+USA/@40.726622,-73.8516662,16z/data=!4m5!3m4!1s0x89c25e2f886ce6a9:0x1c4de261527e80c!8m2!3d40.726622!4d-73.8516662",
    name: "101-15 Queens Blvd, Flushing, NY 11375, USA",
  },
];

export const months = [
  {
    label: "January",
    value: "01",
    index: 0,
  },
  {
    label: "February",
    value: "02",
    index: 1,
  },
  {
    label: "March",
    value: "03",
    index: 2,
  },
  {
    label: "April",
    value: "04",
    index: 3,
  },
  {
    label: "May",
    value: "05",
    index: 4,
  },
  {
    label: "June",
    value: "06",
    index: 5,
  },
  {
    label: "July",
    value: "07",
    index: 6,
  },
  {
    label: "August",
    value: "08",
    index: 7,
  },
  {
    label: "September",
    value: "09",
    index: 8,
  },
  {
    label: "October",
    value: "10",
    index: 9,
  },
  {
    label: "November",
    value: "11",
    index: 10,
  },
  {
    label: "December",
    value: "12",
    index: 11,
  },
];
export const date = new Date();