import CustomModal from "./CustomModal";
import { useContext, useEffect, useRef } from "react";
import { TOGGLE_CALCULATOR_MODAL } from "../functions/constants";
import Context from "../functions/context";
import CalculatorForm from "./CalculatorForm";
import { useTranslation } from "react-i18next";

export default function CalculatorModal() {
  const { t } = useTranslation()
  const {
    mainState: { visibleCalculatorModal },
    mainDispatch,
  } = useContext(Context);
  const ref = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (!ref.current) {
        return;
      }
      if (!ref.current.contains(event.target)) {
        mainDispatch({ type: TOGGLE_CALCULATOR_MODAL });
      }
    };
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);
  return (
    <CustomModal
      title={t("Калькулятор")}
      id="calculator-new"
      onCancel={() => mainDispatch({ type: TOGGLE_CALCULATOR_MODAL })}
      visible={visibleCalculatorModal}
      options={{ dialogClass: "calculator-modal" }}
    >
      <div className="modal-body " ref={ref}>
        <div className="col">
          <CalculatorForm />
        </div>
      </div>
    </CustomModal>
  );
}
