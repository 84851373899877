import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {
  composeValidators,
  minValueConstraint,
  requiredConstraint,
} from "../../../functions/validator";
import { useContext, useEffect } from "react";
import { SET_SELECTED_ADDRESS } from "../../../functions/constants";
import Context from "../../../functions/context";
import { useTranslation } from "react-i18next";

export default function TrackingForm({ form, initialValues, isUpdate = false }) {
  const { mainDispatch } = useContext(Context);
  const { t } = useTranslation();

  useEffect(() => {
      if (!isUpdate) {
          mainDispatch({type: SET_SELECTED_ADDRESS, payload: {}});
          form.change("recipientAddress");
      }
  }, [mainDispatch, form]);

  return (
    <div className="parcels-form">
      <div className="decloration-form">
        <div className="col">
          {/* <h1 className="parcels-list-title">Заполните декларацию</h1> */}
          <p className="parcels-list-text mt-3">
            {t("Пожалуйста, внимательно опишите каждый товар в заказе. Эти данные будут использоваться для оформления таможенной декларации.")}
          </p>
        </div>
        <div className="parcels-form">
          <Form
            form={form}
            mutators={{ ...arrayMutators }}
            initialValues={initialValues}
            render={({
              handleSubmit,
              form: {
                mutators: { push },
              },
              values,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="tracking row y-b">
                    <p className="m-0 col-md-2">{t("Трек-номер")}</p>
                    <Field
                      name="tracking"
                      validate={composeValidators(requiredConstraint)}
                    >
                      {({ input, meta }) => (
                        <div className={`track-number col-md-10`}>
                          <input
                            className={`form-control col-md-10 ${
                              (meta.error || meta.submitError) && meta.touched
                                ? `error-border`
                                : ``
                            }`}
                            placeholder={t("Номер трекинга*")}
                            {...input}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="products row y-b">
                    <p className="m-0 col-md-2">{t("Декларация")}</p>
                    <div className="col-md-10">
                      <FieldArray name="products">
                        {({ fields }) => {
                          return fields.map((fieldName, index) => {
                            const total =
                              (values.products[index]?.unitPrice || 0) *
                              (values.products[index]?.quantity || 1);
                            return (
                              <div key={`products_${index}`}>
                                <div className="products-info">
                                  <Field
                                    name={`${fieldName}.productName`}
                                    validate={composeValidators(
                                      requiredConstraint
                                    )}
                                  >
                                    {({ input, meta }) => (
                                      <div className={`product-name`}>
                                        <input
                                          className={`form-control col-md-10 ${
                                            (meta.error || meta.submitError) &&
                                            meta.touched
                                              ? `error-border`
                                              : ``
                                          }`}
                                          placeholder={t("Название товара*")}
                                          {...input}
                                        />
                                      </div>
                                    )}
                                  </Field>
                                  <Field
                                    name={`${fieldName}.unitPrice`}
                                    type="number"
                                    validate={composeValidators(
                                      requiredConstraint,
                                      minValueConstraint(1)
                                    )}
                                  >
                                    {({ input, meta }) => (
                                      <div className={`track-price`}>
                                        <input
                                          type="text"
                                          placeholder={`${t("Стоимость")}*`}
                                          className={`form-control ${
                                            (meta.error || meta.submitError) &&
                                            meta.touched
                                              ? `error-border`
                                              : ``
                                          }`}
                                          name="track-price"
                                          {...input}
                                        />
                                        <span className="input-btn">$</span>
                                      </div>
                                    )}
                                  </Field>
                                  <Field
                                    name={`${fieldName}.quantity`}
                                    type="number"
                                    validate={composeValidators(
                                      requiredConstraint,
                                      minValueConstraint(1)
                                    )}
                                  >
                                    {({ input, meta }) => (
                                      <div className={`track-count`}>
                                        <input
                                          type="number"
                                          className={`form-control  ${
                                            (meta.error || meta.submitError) &&
                                            meta.touched
                                              ? `error-border`
                                              : ``
                                          }`}
                                          name="track-count"
                                          min="1"
                                          placeholder={t("Количество*")}
                                          {...input}
                                        />
                                        <span className="input-btn">{t("шт")}</span>
                                      </div>
                                    )}
                                  </Field>
                                  <p className="total">
                                    {t("Итого")}: <span>${total}</span>
                                  </p>
                                  {fields.length > 1 ? (
                                    <button
                                      type="button"
                                      className="btn modal-outline-btn"
                                      onClick={() => fields.remove(index)}
                                    >
                                      <i className="ri-delete-bin-2-line"></i>
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                  {total > 1000 ? (
                                    <div className="warning">
                                      <img
                                        src="/images/icons/alert.svg"
                                        alt="alert"
                                      />
                                      <span>
                                        {t("Лимит беспошлинного ввоза превышен. Ваш продукт может быть остановлен в таможне. Пожалуйста, свяжитесь с нашим агентом")}
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                                {fields.length > 1 &&
                                fields.length - 1 !== index ? (
                                  <div className="col">
                                    <hr className="my-4" />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          });
                        }}
                      </FieldArray>
                      <button
                        type="button"
                        className="btn new-address-btn my-3"
                        onClick={() => push("products", null)}
                      >
                        <i className="ri-add-line"></i> {t("Добавить новый заказ")}
                      </button>
                      <Field name="note">
                        {({ input }) => (
                          <div className="col-md-12">
                            <textarea
                              {...input}
                              rows="3"
                              placeholder={t("Комментарий для оператора")}
                            ></textarea>
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}
