import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Context from "../../../../functions/context";
import {
  shopEventRecords,
  trackingEventOrder,
} from "../../../../functions/helper";
import TagManager from "react-gtm-module";

export default function StripeCheckoutForm({
  successCallback,
  amount,
  resource,
}) {
  const {
    mainState: { currentItem, selectedTariff },
  } = useContext(Context);
  const [isProcessing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const purchaseEvent = () => {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });
    if (resource === 3) {
      const items = [
        {
          item_name: selectedTariff.name,
          item_brand: "Subscription",
          item_category: "Subscription",
          price: selectedTariff.price,
          quantity: 1,
          currency: "USD",
        },
      ];
      TagManager.dataLayer({
        dataLayer: {
          event: "purchase",
          transaction_id: selectedTariff.id,
          value: amount,
          ecommerce: {
            items: items,
          },
        },
      });
    } else if (resource === 2) {
      const items = shopEventRecords([currentItem]);
      TagManager.dataLayer({
        dataLayer: {
          event: "purchase",
          ecommerce: {
            transaction_id: currentItem.id,
            value: amount,
            items: items,
          },
        },
      });
    } else {
      const items = trackingEventOrder([currentItem]);
      TagManager.dataLayer({
        dataLayer: {
          event: "purchase",
          ecommerce: {
            transaction_id: currentItem.id,
            value: amount,
            items: items,
          },
        },
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setErrorMessage("");
    setProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/stripe-completion`,
        payment_method_data: {
          billing_details: {
            address: "auto",
          },
        },
      },
      redirect: "if_required",
    });
    if (error) {
      setErrorMessage(error.message);
    } else if (paymentIntent.status === "succeeded") {
      console.log("Payment succeeded 🎉");
      purchaseEvent();
      successCallback();
    }
    setProcessing(false);
  };
  return (
    <form id="stripe-checkout-form" onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="col-12 mt-4">
        <div className="total">
          <p>{t("Сумма оплаты")} :</p>
          <h4>$ {amount}</h4>
        </div>
      </div>
      <button
        disabled={isProcessing}
        id="submit"
        type="submit"
        className="send-button btn mt-2"
      >
        {!isProcessing ? t("Оплатить") : t("Обработка...")}
      </button>
      {errorMessage ? <p>{errorMessage}</p> : ""}
    </form>
  );
}
