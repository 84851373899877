import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Context from "../../../functions/context";

export default function ShopDetail({ values }) {
  const {
    mainState: { customer },
  } = useContext(Context);
  const { t } = useTranslation();

  const totalPrice = useMemo(() => {
    return (values?.records || []).reduce((t, r) => {
      if (!r) {
        return t;
      }
      return r.quantity * r.price + t;
    }, 0);
  }, []);

  const fee =
    (customer.active_subscription?.plan?.offer_options?.online_fee ?? 5) / 100;
console.log(totalPrice);
  return (
    <>
      <div className="parcels-list-declaration">
        <h6 className="parcels-title">{t("Декларация")}</h6>
        {(values?.records || []).map((record, index) => {
          if (!record) {
            return "";
          }
          return (
            <div key={`product_${index}`} className="item-declaration y-b">
              <div>
                <div className="item-deckaration__name">
                  {t("Наименование")}
                </div>
                <div className="item-deckaration__value">{record.name}</div>
              </div>
              <div>
                <div className="item-deckaration__name">{t("Количество")}</div>
                <div className="item-deckaration__value">{record.quantity}</div>
              </div>
              <div>
                <div className="item-deckaration__name">{t("Стоимость")}</div>
                <div className="item-deckaration__value">
                  ${record.quantity * record.price}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <h6 className="parcels-title">{t("Товар(ы) для отправки")}</h6>
      <div className="parcels-list-details y-b">
        <ul>
          <li className="item-detail">
            <span className="item-left">{t("Общая стоимость товаров")}</span>
            <span className="item-right">${totalPrice}</span>
          </li>
          <li className="item-detail">
            <span className="item-left">
              {t("Комиссия за выкуп")}({100 * fee}%)
            </span>
            <span className="item-right">${totalPrice * fee}</span>
          </li>
          <li className="item-detail">
            <span className="item-left">{t("Итоговая сумма")}</span>
            <span className="item-right">${totalPrice + totalPrice * fee}</span>
          </li>
        </ul>
        <blockquote className="blockquote">
          <p className="mb-0">{t("Комментарий для оператора")}</p>
          <p className="" style={{ fontWeight: "normal" }}>
            {values?.note || ""}
          </p>
        </blockquote>
      </div>
    </>
  );
}
