import React from "react";
import { useTranslation } from "react-i18next";

export default function OrderFinished({ item, onClose, section }) {
  const { t } = useTranslation();
  return (
    <div className="decloration-form">
      <img src="/images/done.svg" alt="finished" />
      {item?.tracking ? (
        <h1 className="parcels-list-title">
          {t("Заказ")} {item?.tracking || ""}
        </h1>
      ) : (
        ""
      )}
      {!item?.tracking && item?.id ? (
        <h1 className="parcels-list-title">
          {t("Заказ")} № {item?.id || ""}
        </h1>
      ) : (
        ""
      )}
      <p>
        {section === "shop"
          ? t(
              "Оплатите заказ и мы выкупим ваш товар в течении 24 часов подробности можете узнать в своем кабинете"
            )
          : t(
              "Дождитесь доставки ваших заказов на наш склад. После взвешивание и консолидации, вы получите уведомление"
            )}
      </p>
      <button className="btn thanks" onClick={() => onClose()}>
        {t("Спасибо")}
      </button>
    </div>
  );
}
