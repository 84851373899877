import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";

export default function WithFooterPage() {
  return (
    <>
      <Outlet />
      <section id="contacsUs" className="container-fluid">
        <ContactUs />
      </section>
      <Footer />
    </>
  );
}
