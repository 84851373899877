import React, { useState } from "react";
import { reviewStars } from "../../../../functions/constants";
import "./index.scss";
import ReviewModal from "./ReviewModal";
import { useTranslation } from "react-i18next";

export default function Review({ box }) {
  const { t } = useTranslation();
  const [stars, setStars] = useState(0);
  const [modal, setModal] = useState();
  const [hoverIndex, setHoverIndex] = useState();
  return (
    <div className="review y-b">
      <div
        className="set-review"
        onMouseLeave={() =>
          modal
            ? setTimeout(() => {
                setHoverIndex();
              }, 30000)
            : setHoverIndex()
        }
      >
        <h5>{t("Поставьте оценку")}</h5>
        {reviewStars.map((star, index) => (
          <button
            onMouseEnter={() => setHoverIndex(index)}
            key={index}
            className="star btn"
            onClick={() => {
              setStars(star);
              setModal(true);
            }}
          >
            {index <= hoverIndex ? (
              <img src="/images/star.svg" alt="" />
            ) : (
              <img src="/images/emptyStar.svg" alt="" />
            )}
          </button>
        ))}
      </div>
      {modal ? (
        <ReviewModal
          setModal={setModal}
          modal={modal}
          stars={stars}
          boxId={box}
        />
      ) : null}
    </div>
  );
}
