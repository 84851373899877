import React, { useEffect, useRef, useState } from "react";
import { socialNetworks } from "../functions/constants";
import { useOnClickOutside } from "../functions/useOnClickOutside";
import WarningPopup from "./WarningPopup";

export default function SocialNetworks() {
  const ref = useRef();
  const [collap, setCollap] = useState(false);
  const box = document.getElementById("sms-box");

  function collapsed() {
    setCollap(!collap);
    document.getElementById("sms-box").style.animation = "none";
  }
  useOnClickOutside(ref, () => {
    setCollap(false);
    document.getElementById("sms-box").style.animation =
      "3s 7s forwards smsAnimation";
  });

  useEffect(() => {
    if (!collap) {
      setTimeout(() => {
        if (box)
          document.getElementById("sms-box").style.animation = "smsAnimation2";
      }, 15000);
    }
  }, [collap, box]);

  return (
    <div className="social-networks">
      <div className={`net-collapse ${collap ? `show` : ``}`} ref={ref}>
        {socialNetworks.map((item) => (
          <a
            key={item.name}
            href={item.link}
            target="_blank"
            rel="noreferrer"
            className="btn social-btn"
          >
            <p className="social-name">{item.name}</p>
            <img src={item.image} alt={item.name} />
          </a>
        ))}
      </div>
      <button className="btn social-btn" onClick={collapsed}>
        <WarningPopup />
        <img src="/images/icons/chat.svg" alt="alt" />
      </button>
    </div>
  );
}
