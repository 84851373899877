import { useContext, useEffect } from "react";
import BreadCrumb from "../components/BreadCrumb";
import SocialNetworks from "../components/SocialNetworks";
import { totop } from "../functions/helper";
import { useTranslation } from "react-i18next";
import Context from "../functions/context";
import TagManager from "react-gtm-module";

export default function SizeTablePage() {
  const { t } = useTranslation();
  const {
    mainState: { customer },
  } = useContext(Context);

  useEffect(() => {
    if (customer) {
      TagManager.dataLayer({
        dataLayer: {
          event: "userID",
          UID: customer.id,
        },
      });
    }
    totop();
  }, []);
  return (
    <>
      <SocialNetworks />
      <BreadCrumb currentTitle="Таблица размеров" />
      <section id="size-table" className="container-fluid">
        <div className="size-table container my-5">
          <h2 className="text-center mt-5">{t("Men's Size Chart")}</h2>
          <img
            src="/images/Mens_480x480.png"
            alt="mens"
            style={{
              margin: "0px auto",
              display: "block",
              maxWidth: "450px",
              maxHeight: "450px",
            }}
          />
          <h2 className="text-center mt-5">{t("Women's Size Chart")}</h2>
          <img
            src="/images/Womens_480x480.png"
            alt="mens"
            style={{
              margin: "0px auto",
              display: "block",
              maxWidth: "450px",
              maxHeight: "450px",
            }}
          />
          <h2 className="text-center">{t("Kids' Size Chart")}</h2>
          <p className="text-center mb-0">{t("Toddler (0-4 yrs)")}</p>
          <img
            src="/images/Toddler_480x480.png"
            alt="mens"
            style={{
              margin: "0px auto",
              display: "block",
              maxWidth: "450px",
              maxHeight: "450px",
            }}
          />
          <p className="text-center">{t("Youth (5 yrs and older)")}</p>
          <img
            src="/images/Kids_480x480.png"
            alt="mens"
            style={{
              margin: "0px auto",
              display: "block",
              maxWidth: "450px",
              maxHeight: "450px",
            }}
          />
        </div>
      </section>
    </>
  );
}
