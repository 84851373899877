import React from "react";
import { useTranslation } from "react-i18next";

export default function ContactUs() {
  const { t } = useTranslation();
  return (
    <div className="contact-us container">
      <h3 className="contact-title w-50">
        {t("Enter your details and we will contact you shortly.")}
      </h3>
      <a href="tel:+19292440000" className="btn contact-button">
        <i className="ri-phone-line">&nbsp;</i>+1 (929) 244-0000
      </a>
    </div>
  );
}


// import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
// import { debounce } from "../functions/helper";

// export default function ContactUs() {
//   const { t } = useTranslation();
//   const [userInfo, setUserInfo] = useState();
//   const prefix =
//     window.localStorage.getItem("country") === "USA" ? "+1" : "+998";
//   function setInfo(value, key) {
//     debounce(
//       () => {
//         if (key === "name") {
//           setUserInfo({ ...userInfo, name: value });
//         } else setUserInfo({ ...userInfo, number: value });
//       },
//       "inputQuery",
//       500
//     );
//   }
//   function sendInfo() {
//     if (userInfo) {
//       // do something with user info
//     }
//   }

//   console.log(userInfo);

//   return (
//     <div className="contact-us container">
//       <h3 className="contact-title w-50">
//         {t("Enter your details and we will contact you shortly.")}
//       </h3>
//       <div className="user-data">
//         <input
//           type="text"
//           name="firstName"
//           placeholder={t("Name")}
//           onChange={(e) => {
//             setInfo(e.target.value.trim(), "name");
//           }}
//         />
//         <input
//           type="text"
//           pattern={`^[0-9]{0,${prefix === "+1" ? 10 : 9}}$`}
//           placeholder={t("Phone Number")}
//           name="number"
//           onChange={(e) => {
//             setInfo(e.target.value.trim(), "number");
//           }}
//         />
//         <button onClick={sendInfo} className="btn send">
//           Send
//         </button>
//       </div>
//     </div>
//   );
// }
