import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

export default function ReferralPage() {
    const {referral} = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        if (referral) {
            sessionStorage.setItem('referral', referral);
            navigate('/registration');
        }
    }, []);

    return <>Redirecting...</>
}