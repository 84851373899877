import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import Context from "../../functions/context";
import { Link } from "react-router-dom";
import useConfirm from "../../functions/use-confirm";
import { loadProfile, subsActivation } from "../../functions/api";
import { LOAD_PROFILE } from "../../functions/constants";

export default function WalletSubscriptionPage() {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const {
    mainState: { customer },
    mainDispatch,
  } = useContext(Context);

  const handleClick = async (status) => {
    try {
      await subsActivation(status);
      const userData = await loadProfile();
      mainDispatch({ type: LOAD_PROFILE, payload: userData });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="wallet-subscription mt-4 mb-5">
      {customer.active_subscription ? (
        <div className="content">
          <div className="status bb d-flex align-items-center">
            <h5 className="m-0">{t("Статус")}:</h5>
            <h5 className="m-0">{customer.active_subscription.plan.name}</h5>
          </div>
          <div className="info bb d-flex align-items-center">
            <h5 className="m-0">{t("Срок")}</h5>
            <h5 className="m-0">
              <span>
                {t("с")} {customer.active_subscription.created_at}
              </span>
              {t("до")} {customer.active_subscription.expire_time}
            </h5>
          </div>
          <div className="info bb d-flex align-items-center">
            <h5 className="m-0">{t("Name")}</h5>
            <h5 className="m-0">{customer.full_name}</h5>
          </div>
          <div className="info bb d-flex align-items-center">
            <h5 className="m-0">{t("Номер")}</h5>
            <h5 className="m-0">{customer.phones[0]}</h5>
          </div>
          <div className="info bb d-flex align-items-center">
            <h5 className="m-0">{t("Спопоб оплаты")}</h5>
            <h5 className="m-0">
              {customer.active_subscription.transaction.gateway ===
              "stripe" ? (
                <>
                  <img src="/images/logos/visa.svg" alt="visa" />
                  <img src="/images/logos/mastercard.svg" alt="mastercard" />
                </>
              ) : (
                <img
                  src="/images/logos/payme_02.svg"
                  alt="Payme"
                  style={{ width: "30px" }}
                />
              )}
            </h5>
          </div>
          <div className="info bb d-flex align-items-center">
            <h5 className="m-0">{t("Email")}</h5>
            <h5 className="m-0">{customer.email}</h5>
          </div>
        </div>
      ) : (
        <Trans
          i18nKey="activeSubs"
          components={{
            button: <Link to="/subscription" className="mb-4 d-inline-block" />,
          }}
        />
      )}
      {customer.active_subscription && customer.auto_renewal_subscription && (
        <h6 className="mt-4">
          <Trans
            i18nKey="cancelSubs"
            components={{
              button: (
                <button
                  style={{ color: "#f3d127", textDecoration: "underline" }}
                  className="bg-transparent border-0"
                  onClick={async () => {
                    const isConfirm = await confirm({
                      title: "cancel_subscription",
                      text: "sure_cancel_subscription",
                      yes: "yes_cancel_subscription",
                      no: "no_cancel_subscription",
                    });
                    if (isConfirm) {
                      handleClick(0);
                    }
                  }}
                />
              ),
            }}
          />
        </h6>
      )}
      {customer.auto_renewal_subscription === false && (
        <button
          className="update btn text-nowrap my-4"
          onClick={() => handleClick(1)}
        >
          {t("Обнавить")}
        </button>
      )}
      {!customer.active_subscription && (
        <h6>
          {t("При оплате тарифа вы согласились на")}{" "}
          <Link to="/terms">{t("условия соглашения")}</Link> Yellow.*
        </h6>
      )}
    </div>
  );
}
