import { useContext, useEffect } from "react";
import Context from "../../../functions/context";
import { SET_SELECTED_ADDRESS } from "../../../functions/constants";
import { loadAddressAction } from "../../../reducer/action";
import { useTranslation } from "react-i18next";

export default function AddressChooser({ form }) {
  const { t } = useTranslation();

  const {
    mainState: { selectedAddress, addresses },
    mainDispatch,
  } = useContext(Context);

  useEffect(() => {
    loadAddressAction(2, mainDispatch);
  }, [mainDispatch]);

  return (
    <div className="col-12 addresses">
      <h1 className="parcels-list-title">{t("Выбор получателя")}</h1>
      <p className="parcels-list-text mt-3 my-4">
        {t("Вы можете выбрать адрес отправителя из сохранившехся адресов, либо добавить новый адрес")}
      </p>
      {(addresses[2] || []).map((address) => (
        <div
          onClick={() => {
            mainDispatch({ type: SET_SELECTED_ADDRESS, payload: address });
            form.change("recipientAddress", address.id);
          }}
          className={`parcels-address ${
            address.id === selectedAddress?.id ? "active" : ""
          } 
         y-b
          `}
          key={`address_${address.id}`}
        >
          <div className={`circle`}>
            <span />
          </div>
          <div className="address-info row">
            <div className="col-md-6">
              <p className="mb-2" style={{ fontWeight: 500 }}>
                {address.full_name} |{" "}
                <span className="apartment custom-tooltipe">
                  {address.apartment}
                  {address.limit > 1000 ? (
                    <span className="tooltiptext">
                      {t("Ваш лимит уже использован в этом квартале")}
                    </span>
                  ) : null}
                </span>
              </p>
              <p className="">{address.label}</p>
            </div>
            <div className="col-md-6">
              {address.phones ? (
                <p style={{ fontWeight: 500 }} className="mb-2">
                  <span>
                    <i className="ri-phone-line"></i>
                  </span>
                  {(address.phones || []).join(" ")}
                </p>
              ) : null}
              {address.passport ? (
                <p>
                  <span>
                    <i className="ri-passport-line"></i>
                  </span>
                  {address.passport ? address.passport : null}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
