import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProductCard from "./ProductCard";
import { loadProposalProducts } from "../functions/api";
import { useTranslation } from "react-i18next";

export default function Products() {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  useEffect(() => {
    loadProposalProducts().then((items) => setProducts(items));
  }, []);

  return (
    <div className="container products">
      <div className="title">
        <h2>
          {t("Большие скидки на")} <br className="only-desktop" /> {t("спортивные аксессуары.")}
        </h2>
        <Link to="/registration" className="swiper-button btn">
          {t("Лучшие магазины")}
        </Link>
      </div>
      <div className="row product-list">
        {products.map((product) => (
          <div className="col-md-3" key={product.id}>
            <ProductCard product={product} />
          </div>
        ))}
      </div>
      {products.total_count ? (
        <div className="buttons">
          <button className="swiper-button btn">{t("Более")}</button>
          <Link to="/shops" className="swiper-button btn">
            {t("Полезно для покупок")}
          </Link>
        </div>
      ) : null}
    </div>
  );
}
