import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { reviewStars } from "../../../functions/constants";
import { debounce } from "../../../functions/helper";
import { claimById, claimsList } from "../../../functions/api";
import { useParams } from "react-router-dom";

export default function ClaimsList() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [claims, setClaims] = useState([]);
  const [hoverIndex, setHoverIndex] = useState();
  const [commit, setCommit] = useState();

  const submit = () => {
    alert(`Stars: ${hoverIndex + 1} Comment: ${commit}`);
  };

  function searchFn(e) {
    debounce(() => setCommit(e.target.value), "inputQuery", 500);
  }

  const getClaims = async () => {
    try {
      const res = await claimById(id);
      setClaims([res]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClaims();
  }, []);

  return (
    <div className="claim-status">
      <h3 className="mb-0">{t("Статус")}</h3>
      <div className="parcles-list table-responsive mt-4 mb-5">
        <table className="table overflow-auto">
          <thead>
            <tr>
              <th className="text-nowrap th-verified2-link">{t("ID")}</th>
              <th className="text-nowrap th-verified2-name">
                {t("ID коробки/трека")}
              </th>
              <th className="text-nowrap th-price">{t("Сумма продуктов")}</th>
              <th className="text-nowrap th-price">{t("Время регистрации")}</th>
              <th className="text-nowrap th-price">{t("Статус жалобы")}</th>
            </tr>
          </thead>
          <tbody>
            {claims.length ? (
              claims.map((item) => (
                <tr key={item.id}>
                  <td className="text-nowrap">{item.id}</td>
                  <td className="text-nowrap">{item.products[0].tracking}</td>
                  <td className="text-nowrap">${item.total_price}</td>
                  <td className="text-nowrap">{item.created_at}</td>
                  <td className={`text-nowrap last ${item.status}`}>
                    {item.status}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>{t("No active claims")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="review">
        <h5 className="fw-normal text-center">
          {t("Оцените работу персонала")}
        </h5>
        <div className="stars mb-4 d-flex align-items-center justify-content-center">
          {reviewStars.map((star, index) => (
            <button
              onMouseEnter={() => setHoverIndex(index)}
              key={index}
              className="star btn"
            >
              {index <= hoverIndex ? (
                <img src="/images/star.svg" alt="star" />
              ) : (
                <img src="/images/emptyStar.svg" alt="star" />
              )}
            </button>
          ))}
        </div>
        <div className="commit">
          <textarea
            className="form-control"
            placeholder={t("Сообщение")}
            onChange={searchFn}
          />
          <button className="submit-btn y-b btn w-auto" onClick={submit}>
            {t("Отправить")}
          </button>
        </div>
      </div>
    </div>
  );
}
