import React, { useState } from "react";
import PaymeBankCardForm from "./PaymeBankCardForm";
import PaymeConfirmForm from "./PaymeConfirmForm";

export default function PaymePayment({
  uuid,
  resource,
  resourceId,
  amount,
  successCallback,
  currencyRate,
  saveCard,
}) {
  const [codeSent, setCodeSent] = useState(false);

  return (
    <>
      {!codeSent ? (
        <PaymeBankCardForm
          uuid={uuid}
          amount={amount}
          setCodeSent={setCodeSent}
          currencyRate={currencyRate}
          saveCard={saveCard}
        />
      ) : (
        ""
      )}
      {codeSent ? (
        <PaymeConfirmForm
          uuid={uuid}
          amount={amount}
          resource={resource}
          resourceId={resourceId}
          successCallback={successCallback}
          currencyRate={currencyRate}
        />
      ) : (
        ""
      )}
    </>
  );
}
