import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function BreadCrumb({ currentTitle, className = "" }) {
  const { t } = useTranslation();

  return (
    <section id="breadcrumbs" className={`container-fluid ${className}`}>
      <nav
        aria-label="breadcrumb"
        className="breadcrumbs container d-flex w-100 justify-content-between"
      >
        <div>
          <h1 className="breadcrumb-title">{currentTitle}</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/for-uzbek">{t("Главная")}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {t(currentTitle)}
            </li>
          </ol>
        </div>
      </nav>
    </section>
  );
}
