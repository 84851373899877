import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

function TimerButton({ resendTime = 90, onClick }) {
  const { t } = useTranslation();
  const [timer, setTimer] = useState(90);
  const [reset, setReset] = useState(false);
  
  useEffect(() => {
    let interval = setInterval(() => {
      if (timer > 0) {
        setTimer((prev) => prev - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [reset]);

  return (
    <button
      className="re send-button btn"
      disabled={timer > 0}
      onClick={(e) => {
        e.preventDefault();
        onClick();
        setTimer(resendTime);
        setReset(!reset);
      }}
    >
      {t("Resend") + " " + (timer > 0 ? timer : "")}
      <i className="ri-refresh-line"></i>
    </button>
  );
}

export default TimerButton;
