import { Field, Form } from "react-final-form";
import React, { useState } from "react";
import { addPaymeBankCard } from "../../../../functions/api";
import { normalizeErrorFields } from "../../../../functions/request-helper";
import {
  cardExpireConstraint,
  composeValidators,
  hasErrorInput,
  lengthEqualConstraint,
  requiredConstraint,
} from "../../../../functions/validator";
import BankCardInput from "./BankCardInput";
import BankCardExpireInput from "./BankCardExpireInput";
import { priceFormatter } from "../../../../functions/helper";
import { useTranslation } from "react-i18next";

export default function PaymeBankCardForm({
  uuid,
  amount,
  setCodeSent,
  currencyRate,
  saveCard,
}) {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();

  const onSubmit = async (values) => {
    try {
      setDisabled(true);
      await addPaymeBankCard(values.cardNumber, values.expire, saveCard, uuid);
      setCodeSent(true);
    } catch (res) {
      if (res?.response?.status === 422) {
        const error = res.response.data;
        const fieldErrors = normalizeErrorFields(error);
        if (Object.values(fieldErrors).length) {
          return fieldErrors;
        }
      } else if (res?.response?.status === 400 && res.response?.data?.message) {
        return { cardNumber: res.response.data.message };
      }
    } finally {
      setDisabled(false);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className="row">
            <div className="col-md-8">
              <Field
                name="cardNumber"
                type="text"
                validate={composeValidators(
                  requiredConstraint,
                  lengthEqualConstraint(16)
                )}
              >
                {({ input, meta }) => (
                  <div className="number-card position-relative">
                    <BankCardInput
                      input={input}
                      className={`form-control ${
                        hasErrorInput(meta) ? "error-border" : ""
                      }`}
                      style={{ fontSize: "18px" }}
                      placeholder="8600 0000 0000 0000"
                    />
                    {meta.submitError && (
                      <span
                        style={{
                          fontSize: "12px",
                          bottom: "-20px",
                          color: "#E83D3D",
                        }}
                        className="position-absolute"
                      >
                        {meta.submitError}
                      </span>
                    )}
                  </div>
                )}
              </Field>
            </div>
            <div className="col-md-4">
              <Field
                name="expire"
                type="text"
                validate={composeValidators(
                  requiredConstraint,
                  cardExpireConstraint
                )}
              >
                {({ input, meta }) => (
                  <div className="number-card">
                    <BankCardExpireInput
                      input={input}
                      className={`form-control ${
                        hasErrorInput(meta) ? "error-border" : ""
                      }`}
                      style={{ fontSize: "18px" }}
                      placeholder="12/27"
                    />
                  </div>
                )}
              </Field>
            </div>
            <div className="col-12">
              <div className="total">
                <strong>{t("Сумма оплаты")}:</strong>
                <h4>
                  {priceFormatter(
                    Math.round(
                      parseFloat(amount) * parseFloat(currencyRate.Rate)
                    )
                  )}{" "}
                  UZS
                </h4>
              </div>
            </div>
            <div className="col-12">
              <button
                type="submit"
                className="send-button btn"
                disabled={disabled}
              >
                {!disabled ? t("Send") : t("Process")}
              </button>
            </div>
          </form>
        );
      }}
    />
  );
}
