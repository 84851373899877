import { useContext, useEffect, useState } from "react";
import BreadCrumb from "../components/BreadCrumb";
import $ from "jquery";
import { getMessages, readAllMessages, sendMessages } from "../functions/api";
import InfiniteScroll from "react-infinite-scroll-component";

import Message from "../components/Message";
import { UNREAD_COUNT } from "../functions/constants";
import Context from "../functions/context";
import SocialNetworks from "../components/SocialNetworks";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
const queryString = require("qs");
const initialQuery = {
  page: 1,
  size: 10,
};

const initialMessages = {
  items: [],
  total_count: 0,
};
export default function MessagesPage() {
  const { t } = useTranslation();
  const {
    mainDispatch,
    mainState: { customer },
  } = useContext(Context);
  const [query, setQuery] = useState(initialQuery);
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState(initialMessages);

  useEffect(() => {
    getMessages(queryString.stringify(query)).then((res) =>
      setMessages({
        total_count: res.total_count,
        items: [...messages.items, ...res.items],
      })
    );
  }, [queryString.stringify(query)]);

  useEffect(() => {
    readAllMessages().then(() => {
      mainDispatch({ type: UNREAD_COUNT, payload: 0 });
    });
    scrollToBottom();
  }, []);

  function fetchData() {
    setQuery({ ...query, page: query.page + 1 });
  }

  function scrollToBottom() {
    $(".infinite-scroll-component").animate(
      { scrollTop: $(".infinite-scroll-component")[0].scrollHeight },
      1000
    );
  }
  async function send() {
    await sendMessages(newMessage).then((res) =>
      setMessages({ ...messages, items: [res, ...messages.items] })
    );
    document.getElementById("messageInput").value = "";
    setNewMessage("");
    scrollToBottom();
  }
  // console.log($(".infinite-scroll-component").scrollTop());

  useEffect(() => {
    if (customer) {
      TagManager.dataLayer({
        dataLayer: {
          event: "userID",
          UID: customer.id,
        },
      });
    }
    document
      .getElementsByClassName("message-scroll")[0]
      .addEventListener("scroll", () => {});
  }, []);

  return (
    <>
      <SocialNetworks />
      <BreadCrumb currentTitle={t("Сообщения")} />
      <section
        id="message-section"
        className="container"
        style={{ minHeight: "calc(100vh - 100px)" }}
      >
        <div className="message-content">
          <div className="message-top">
            <div className="operator-image">
              <img src="/images/operator.png" alt="operator" />
            </div>
            <h5>{t("Оператор")}</h5>
            <a
              href="tel:+19292440000"
              target="_blank"
              rel="noreferrer"
              className="btn contact-button"
            >
              <i className="ri-phone-line">&nbsp;</i>+1 (929) 244-0000
            </a>
          </div>
          <div className="message-body">
            <InfiniteScroll
              className="message-scroll"
              dataLength={messages.items.length}
              next={fetchData}
              hasMore={messages.total_count !== messages.items.length}
              height={350}
            >
              {messages.items.length ? (
                messages.items.map((item) => (
                  <Message
                    key={item.id}
                    message={item}
                    messages={messages}
                    setMessages={setMessages}
                  />
                ))
              ) : (
                <h2 className="text-center">{t("Пустой")}</h2>
              )}
            </InfiniteScroll>
          </div>
          <div className="message-footer">
            <input
              id="messageInput"
              type="text"
              placeholder={t("Написать сообщение...")}
              onKeyDown={(e) => {
                if (e.key === "Enter" && newMessage) {
                  send();
                  e.target.value = "";
                }
              }}
              onChange={(e) => {
                setNewMessage(e.target.value.trim());
              }}
            />
            <button
              id="sentBtn"
              className="send-btn btn"
              disabled={!newMessage}
              onClick={send}
            >
              <img src="/images/send.png" alt="send" />
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
