import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Context from "../../../functions/context";
import { Link, useNavigate } from "react-router-dom";
import { SET_CLAIMS } from "../../../functions/constants";
import { createClaims } from "../../../functions/api";
import { jsonToFormData } from "../../../functions/helper";

export default function ClaimConfirm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [claim, setClaim] = useState();
  const {
    mainState: {
      claims: {
        data: { sender, receiver, products, fileImages, text, type },
      },
    },
    mainDispatch,
  } = useContext(Context);

  const submit = async () => {
    const formData = jsonToFormData({
      sender,
      receiver,
      products,
      fileImages,
      text,
      type,
    });

    try {
      const res = await createClaims(formData);
      setClaim(res);
    } catch (error) {
      console.log(error);
    }
  };

  const done = () => {
    mainDispatch({
      type: SET_CLAIMS,
      payload: {
        step: 0,
        data: {},
      },
    });
    navigate("/cabinet/claims");
  };
  console.log(claim);

  return !claim ? (
    <div className="claim-confirm">
      <h3 className="fw-normal">{t("Проверьте детали претензии")}</h3>
      <span>{t("Тип заявления")}:</span>
      <h3 className="fw-normal mt-2">{t(type)}</h3>
      <h4 className="fw-normal text-center mb-3 mt-4">
        {t("Информация о пользователе")}
      </h4>
      <div className="row">
        <div className="col-md-6 text-center">
          <h5>{t("Отправитель")}</h5>
          <h6>{sender.firstName}</h6>
          <h6>{sender.email}</h6>
          <h6>{sender.phone}</h6>
        </div>
        <div className="col-md-6 text-center">
          <h5>{t("Получатель")}</h5>
          <h6>{receiver.firstName}</h6>
          <h6>{receiver.email}</h6>
          <h6>{receiver.phone}</h6>
        </div>
      </div>
      <h4 className="fw-normal text-center mb-3 mt-4">
        {t("Информация о товаре")}
      </h4>
      <div className="table-responsive">
        <div className="product-info row">
          <div className="id col-1">
            <h6 className="mb-0">#</h6>
          </div>
          <div className="name col-3">
            <h6 className="mb-0">{t("Наименование товара")}</h6>
          </div>
          <div className="number col-3">
            <h6 className="mb-0">{t("Номер отслеживания")}</h6>
          </div>
          <div className="quantity col-3">
            <h6 className="mb-0">{t("Количество")}</h6>
          </div>
          <div className="price col-2">
            <h6 className="mb-0">{t("Цена товара")}</h6>
          </div>
        </div>
        {products.map((product, i) => (
          <div className="product-info row">
            <div className="id col-1">
              <span className="d-block">{i + 1}</span>
              <div className="line" />
            </div>
            <div className="name col-3">
              <span>{product.name}</span>
              <div className="line" />
            </div>
            <div className="number col-3">
              <span>{products[0].tracking}</span>
              <div className="line" />
            </div>
            <div className="quantity col-3">
              <span>{product.count}</span>
              <div className="line" />
            </div>
            <div className="price col-2">
              <span>{product.price}</span>
              <div className="line" />
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex align-items-center justify-content-between mb-5 col-12 mt-5">
        <h6 className="fw-normal total">{t("Общяя сумма")}:</h6>
        <h5 className="fw-bold">
          ${" "}
          {products.reduce((a, b) => {
            return (a += b.price && b.count ? +b.price * +b.count : 0);
          }, 0)}
        </h5>
      </div>
      <div className="row mb-5">
        <div className="col-md-7 col-12">
          <span>{t("Сообщение")}</span>
          <h6>{text}</h6>
        </div>
        <div className="offset-md-1 col-md-4 col-12">
          <span>{t("Загруженные документы")}</span>
          {fileImages.map((p) => (
            <h6>{p.name}</h6>
          ))}
        </div>
      </div>
      <button className="submit-btn y-b btn w-auto" onClick={submit}>
        {t("Отправить")}
      </button>
    </div>
  ) : (
    <div className="claim-confirm confirmed">
      <div className="d-flex align-items-center justify-content-between">
        <h3 className="mb-0">{t("ID вашего претензии")}</h3>
        {/* <div className="d-flexa align-items-center">
          <button className="btn p-0">
            <img src="/images/icons/share.svg" alt="share" />
          </button>
          <button className="btn p-0">
            <img src="/images/icons/download.svg" alt="download" />
          </button>
        </div> */}
      </div>
      <div className="claim-info">
        <h2>#{claim.id}</h2>
        <button className="submit-btn y-b btn w-auto" onClick={done}>
          {t("Готово")}
        </button>
        <span>
          {t(
            "Ваша претензия отправлена в Yellow, обработываются в течении 3 дней"
          )}
        </span>
        <br />
        <br />
        <Link to="/cabinet/claims" state={{ tab: true }}>
          {t("Вы можете проверить претензию в разделе")}
          <br />
          <span>{t("Проверить статус претензии")}</span>
        </Link>
      </div>
    </div>
  );
}
