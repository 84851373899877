import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function PasswordInput({ label, input, meta, error }) {
  const [visiblePassword, setVisiblePassword] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="col-12 position-relative input-margin-b">
      <input
        {...input}
        type={visiblePassword ? "text" : "password"}
        className={`content-right-body-input password-input ${
          (meta.error || meta.submitError) && meta.touched ? `error-border` : ``
        }`}
        placeholder={label}
        autoComplete="new-password"
      />
      <span className="text-danger">{error ?? ""}</span>
      <button
        className={`password-btn-eye ${visiblePassword ? "none" : ""}`}
        type="button"
        onClick={() => setVisiblePassword(!visiblePassword)}
      >
        <i className="ri-eye-off-line"></i>
      </button>
      <button
        className={`password-btn-eye-off ${!visiblePassword ? "none" : ""}`}
        type="button"
        onClick={() => setVisiblePassword(!visiblePassword)}
      >
        <i className="ri-eye-line"></i>
      </button>
      {meta.error && meta.error !== "Required" ? (
        <span className="help-block">{t(meta.error)}</span>
      ) : null}
    </div>
  );
}
