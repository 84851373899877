import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { getOrderDetails } from "../../functions/api";
import Context from "../../functions/context";
import {
  PAYMENT_MODAL,
  paymentResourceEnum,
  TOGGLE_PAYMENT_MODAL,
} from "../../functions/constants";
import PaymentCreateModal from "./components/Payment/PaymentCreateModal";
import { getFormattedDate } from "../../functions/helper";
import ViewInvoiceModal from "./components/ViewInvoiceModal";
import InvoiceContent from "./components/InvoiceContent";
import generatePDF from "react-to-pdf";

export default function PaymentInvoice() {
  const { id } = useParams();
  const targetRef = useRef(null);
  const { mainState, mainDispatch } = useContext(Context);
  const { visibleCreatePaymentModal, currentItem } = mainState;
  const [isOpenView, setIsOpenView] = useState(false);
  const [order, setOrder] = useState();

  const fetchOrderDetails = async () => {
    try {
      const res = await getOrderDetails(id);
      setOrder(res);
    } catch (error) {
      console.log(error);
    }
  };

  const pay = () => {
    mainDispatch({
      type: PAYMENT_MODAL,
      payload: { visible: false, item: currentItem },
    });
    mainDispatch({
      type: TOGGLE_PAYMENT_MODAL,
      payload: {
        resource: paymentResourceEnum.order,
        resourceId: order.order.id,
        amount: order.order.debt_price,
        visible: true,
        uuid: id,
      },
    });
  };

  const download = () => {
    targetRef.current.classList.remove("fade");
    targetRef.current.classList.add("mobile-download");
    generatePDF(targetRef, { filename: "Invoice.pdf" });
    targetRef.current.classList.remove("mobile-download");
    targetRef.current.classList.add("fade");
  };

  const handlePrint = () => {
    const printContent = targetRef.current;
    const WinPrint = window.open("", "", "width=700,height=650");

    WinPrint.document.write(
      `<html><head><title>Invoice</title></head><body>${printContent.innerHTML}</body></html>`
    );
    WinPrint.print();
  };

  useEffect(() => {
    if (id) {
      fetchOrderDetails();
    }
  }, [id]);

  return (
    <section className="payment-invoice">
      {order && (
        <>
          <div className="row">
            <div className="col-md-8 col-12">
              <div className="left-side">
                <p>Balance DUE</p>
                <h4>${order.order.debt_price}</h4>
                <button className="btn" onClick={pay}>
                  Pay
                </button>
                <hr />
                <span>
                  © 2024 Intuit Inc. All rights reserved. Privacy | Terms of
                  service
                </span>
              </div>
            </div>
            <div className="col-md-4 col-12 right-side">
              <div className="order-details">
                <img className="logo" src="/images/logo.svg" alt="logo" />
                <p>Yellow Way Inc</p>
                <div className="d-flex">
                  <p>Invoice</p>
                  <span></span>
                </div>
                <div className="d-flex">
                  <p>Due date</p>
                  <span>{getFormattedDate(order.order.created_at)}</span>
                </div>
                <div className="d-flex">
                  <p>Invoice amount</p>
                  <span>${order.order.debt_price}</span>
                </div>
                <div className="buttons">
                  <button className="btn" onClick={() => setIsOpenView(true)}>
                    View invoice
                  </button>
                  <div className="d-flex">
                    <button className="btn" onClick={download}>
                      <img src="/images/icons/download.svg" alt="dowload" />
                    </button>
                    <button className="btn" onClick={handlePrint}>
                      <img src="/images/icons/print.svg" alt="print" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="merchant-details">
                <p>Merchand details</p>
                <div>
                  <img src="/images/icons/email.svg" alt="" />
                  <a href="mailto:theyellowinc@gmail.com">
                    theyellowinc@gmail.com
                  </a>
                </div>
                <div className="middle">
                  <img src="/images/icons/contact-book.svg" alt="" />
                  <a href="tel:19292440000">+1 9292440000</a>
                </div>
                <div>
                  <img src="/images/icons/location-line.svg" alt="" />
                  <a href="https://goo.gl/maps/umYYEB2UPLWGH9zXA">
                    1954 Coney Island Avenue , Brooklyn, NY, 11223
                  </a>
                </div>
              </div>
              <img src="/images/logos/stripe.png" alt="stripe" />
              <div className="bottom-text">
                <img src="/images/icons/lock.svg" alt="lock" />
                <p>Information is protected and kept confidential</p>
              </div>
            </div>
          </div>
          <ViewInvoiceModal
            isOpen={isOpenView}
            setIsOpen={setIsOpenView}
            data={order}
          />
          <InvoiceContent
            className="fade invoice-content"
            data={order}
            ref={targetRef}
          />
        </>
      )}
      {visibleCreatePaymentModal ? <PaymentCreateModal /> : ""}
    </section>
  );
}
